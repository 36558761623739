export const COUNTRIES = [
    "Afghanistan", "Åland-Inseln", "Albanien", "Algerien", "Amerikanisch-Samoa",
    "Andorra", "Angola", "Anguilla", "Antarktis", "Antigua und Barbuda",
    "Argentinien", "Armenien", "Aruba", "Australien", "Österreich", "Aserbaidschan",
    "Bahamas", "Bahrain", "Bangladesch", "Barbados", "Belarus", "Belgien",
    "Belize", "Benin", "Bermuda", "Bhutan", "Bolivien",
    "Bosnien und Herzegowina", "Botswana", "Bouvetinsel", "Brasilien",
    "Britisches Territorium im Indischen Ozean", "Brunei Darussalam", "Bulgarien",
    "Burkina Faso", "Burundi", "Kambodscha", "Kamerun", "Kanada", "Kap Verde",
    "Kaimaninseln", "Zentralafrikanische Republik", "Tschad", "Chile", "China",
    "Weihnachtsinsel", "Kokosinseln (Keeling)", "Kolumbien", "Komoren",
    "Kongo", "Demokratische Republik Kongo", "Cookinseln",
    "Costa Rica", "Elfenbeinküste", "Kroatien", "Kuba", "Zypern",
    "Tschechische Republik", "Dänemark", "Dschibuti", "Dominica", "Dominikanische Republik",
    "Ecuador", "Ägypten", "El Salvador", "Äquatorialguinea", "Eritrea",
    "Estland", "Äthiopien", "Falklandinseln (Malwinen)", "Färöer", "Fidschi", 
    "Finnland", "Frankreich", "Französisch-Guayana", "Französisch-Polynesien",
    "Französische Süd- und Antarktisgebiete", "Gabun", "Gambia", "Georgien", "Deutschland",
    "Ghana", "Gibraltar", "Griechenland", "Grönland", "Grenada", "Guadeloupe",
    "Guam", "Guatemala", "Guernsey", "Guinea", "Guinea-Bissau", "Guyana",
    "Haiti", "Heard- und McDonald-Inseln", "Heiliger Stuhl (Vatikanstadt)",
    "Honduras", "Hongkong", "Ungarn", "Island", "Indien", "Indonesien",
    "Iran", "Irak", "Irland", "Isle of Man", "Israel", "Italien",
    "Jamaika", "Japan", "Jersey", "Jordanien", "Kasachstan", "Kenia",
    "Kiribati", "Demokratische Volksrepublik Korea", "Republik Korea",
    "Kuwait", "Kirgisistan", "Laos", "Lettland", "Libanon", "Lesotho",
    "Liberia", "Libyen", "Liechtenstein", "Litauen", "Luxemburg", "Macao",
    "Nordmazedonien", "Madagaskar", "Malawi", "Malaysia", "Malediven", "Mali",
    "Malta", "Marshallinseln", "Martinique", "Mauretanien", "Mauritius", "Mayotte",
    "Mexiko", "Mikronesien", "Moldau", "Monaco", "Mongolei", "Montserrat",
    "Marokko", "Mosambik", "Myanmar", "Namibia", "Nauru", "Nepal", "Niederlande",
    "Niederländische Antillen", "Neukaledonien", "Neuseeland", "Nicaragua", "Niger", 
    "Nigeria", "Niue", "Norfolkinsel", "Nördliche Marianen", "Norwegen",
    "Oman", "Pakistan", "Palau", "Besetzte palästinensische Gebiete", "Panama",
    "Papua-Neuguinea", "Paraguay", "Peru", "Philippinen", "Pitcairninseln",
    "Polen", "Portugal", "Puerto Rico", "Katar", "Réunion", "Rumänien",
    "Russische Föderation", "Ruanda", "St. Helena", "St. Kitts und Nevis",
    "St. Lucia", "St. Pierre und Miquelon", "St. Vincent und die Grenadinen",
    "Samoa", "San Marino", "São Tomé und Príncipe", "Saudi-Arabien", "Senegal",
    "Serbien und Montenegro", "Seychellen", "Sierra Leone", "Singapur", "Slowakei",
    "Slowenien", "Salomonen", "Somalia", "Südafrika", "Südgeorgien und die Südlichen Sandwichinseln",
    "Spanien", "Sri Lanka", "Sudan", "Suriname", "Svalbard und Jan Mayen",
    "Eswatini", "Schweden", "Schweiz", "Syrische Arabische Republik", "Taiwan",
    "Tadschikistan", "Tansania", "Thailand", "Timor-Leste", "Togo", "Tokelau",
    "Tonga", "Trinidad und Tobago", "Tunesien", "Türkei", "Turkmenistan",
    "Turks- und Caicosinseln", "Tuvalu", "Uganda", "Ukraine", "Vereinigte Arabische Emirate",
    "Vereinigtes Königreich", "Vereinigte Staaten", "Kleinere amerikanische Überseeinseln",
    "Uruguay", "Usbekistan", "Vanuatu", "Venezuela", "Vietnam",
    "Britische Jungferninseln", "Amerikanische Jungferninseln", "Wallis und Futuna",
    "Westsahara", "Jemen", "Sambia", "Simbabwe"
];
