import React from 'react'

const ExpandIcon = () => {
    return (
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 14V8H2V12H6V14H0ZM12 6V2H8V0H14V6H12Z" fill="#1A1A1A" />
        </svg>
    )
}

export default ExpandIcon