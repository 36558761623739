import React from 'react';

interface IconProps {
  type: string;
}

const SvgIcon: React.FC<IconProps> = ({ type }) => {
  const renderIcon= () => {
    switch (type) {
      case 'AccountSetting':
        return (
            <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_427_589)">
              <path
                d="M11.6667 9.16667H17.5V5H11.6667V9.16667ZM14.5833 8.125L12.5 6.66667V5.83333L14.5833 7.29167L16.6667 5.83333V6.66667L14.5833 8.125ZM1.66667 17.5C1.20833 17.5 0.815972 17.3368 0.489583 17.0104C0.163194 16.684 0 16.2917 0 15.8333V4.16667C0 3.70833 0.163194 3.31597 0.489583 2.98958C0.815972 2.66319 1.20833 2.5 1.66667 2.5H18.3333C18.7917 2.5 19.184 2.66319 19.5104 2.98958C19.8368 3.31597 20 3.70833 20 4.16667V15.8333C20 16.2917 19.8368 16.684 19.5104 17.0104C19.184 17.3368 18.7917 17.5 18.3333 17.5H1.66667ZM7.5 11.6667C8.19444 11.6667 8.78472 11.4236 9.27083 10.9375C9.75694 10.4514 10 9.86111 10 9.16667C10 8.47222 9.75694 7.88194 9.27083 7.39583C8.78472 6.90972 8.19444 6.66667 7.5 6.66667C6.80556 6.66667 6.21528 6.90972 5.72917 7.39583C5.24306 7.88194 5 8.47222 5 9.16667C5 9.86111 5.24306 10.4514 5.72917 10.9375C6.21528 11.4236 6.80556 11.6667 7.5 11.6667ZM1.75 15.8333H13.25C12.6667 14.7917 11.8611 13.9757 10.8333 13.3854C9.80556 12.7951 8.69444 12.5 7.5 12.5C6.30556 12.5 5.19444 12.7951 4.16667 13.3854C3.13889 13.9757 2.33333 14.7917 1.75 15.8333Z"
                fill="#3C3C3C"
              />
            </g>
            <defs>
              <clipPath id="clip0_427_589">
                <rect width="20" height="20" fill="white" />
              </clipPath>
            </defs>
          </svg>
        );
      case 'ChangePassword':
        return (
            <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.00065 18.3333C4.54232 18.3333 4.14996 18.1701 3.82357 17.8437C3.49718 17.5173 3.33398 17.1249 3.33398 16.6666V8.33325C3.33398 7.87492 3.49718 7.48256 3.82357 7.15617C4.14996 6.82978 4.54232 6.66659 5.00065 6.66659H5.83398V4.99992C5.83398 3.84714 6.24023 2.8645 7.05273 2.052C7.86523 1.2395 8.84787 0.833252 10.0007 0.833252C11.1534 0.833252 12.1361 1.2395 12.9486 2.052C13.7611 2.8645 14.1673 3.84714 14.1673 4.99992V6.66659H15.0007C15.459 6.66659 15.8513 6.82978 16.1777 7.15617C16.5041 7.48256 16.6673 7.87492 16.6673 8.33325V16.6666C16.6673 17.1249 16.5041 17.5173 16.1777 17.8437C15.8513 18.1701 15.459 18.3333 15.0007 18.3333H5.00065ZM10.0007 14.1666C10.459 14.1666 10.8513 14.0034 11.1777 13.677C11.5041 13.3506 11.6673 12.9583 11.6673 12.4999C11.6673 12.0416 11.5041 11.6492 11.1777 11.3228C10.8513 10.9964 10.459 10.8333 10.0007 10.8333C9.54232 10.8333 9.14996 10.9964 8.82357 11.3228C8.49718 11.6492 8.33398 12.0416 8.33398 12.4999C8.33398 12.9583 8.49718 13.3506 8.82357 13.677C9.14996 14.0034 9.54232 14.1666 10.0007 14.1666ZM7.50065 6.66659H12.5007V4.99992C12.5007 4.30547 12.2576 3.7152 11.7715 3.22909C11.2854 2.74297 10.6951 2.49992 10.0007 2.49992C9.30621 2.49992 8.71593 2.74297 8.22982 3.22909C7.74371 3.7152 7.50065 4.30547 7.50065 4.99992V6.66659Z"
              fill="#3C3C3C"
            />
          </svg>
        );
      case 'Subscription':
        return (
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.4167 17L13.1667 15.75C13 15.6806 12.8438 15.6076 12.6979 15.5313C12.5521 15.4549 12.4028 15.3611 12.25 15.25L11.0417 15.625L10.2083 14.2083L11.1667 13.375C11.1389 13.1944 11.125 13.0139 11.125 12.8333C11.125 12.6528 11.1389 12.4722 11.1667 12.2917L10.2083 11.4583L11.0417 10.0417L12.25 10.4167C12.4028 10.3056 12.5521 10.2118 12.6979 10.1354C12.8438 10.059 13 9.98611 13.1667 9.91667L13.4167 8.66667H15.0833L15.3333 9.91667C15.5 9.98611 15.6562 10.059 15.8021 10.1354C15.9479 10.2118 16.0972 10.3056 16.25 10.4167L17.4583 10.0417L18.2917 11.4583L17.3333 12.2917C17.3611 12.4722 17.375 12.6528 17.375 12.8333C17.375 13.0139 17.3611 13.1944 17.3333 13.375L18.2917 14.2083L17.4583 15.625L16.25 15.25C16.0972 15.3611 15.9479 15.4549 15.8021 15.5313C15.6562 15.6076 15.5 15.6806 15.3333 15.75L15.0833 17H13.4167ZM14.25 14.5C14.7083 14.5 15.1007 14.3368 15.4271 14.0104C15.7535 13.684 15.9167 13.2917 15.9167 12.8333C15.9167 12.375 15.7535 11.9826 15.4271 11.6562C15.1007 11.3299 14.7083 11.1667 14.25 11.1667C13.7917 11.1667 13.3993 11.3299 13.0729 11.6562C12.7465 11.9826 12.5833 12.375 12.5833 12.8333C12.5833 13.2917 12.7465 13.684 13.0729 14.0104C13.3993 14.3368 13.7917 14.5 14.25 14.5ZM2.66667 15.3333C2.20833 15.3333 1.81597 15.1701 1.48958 14.8438C1.16319 14.5174 1 14.125 1 13.6667V3.66667C1 3.20833 1.16319 2.81597 1.48958 2.48958C1.81597 2.16319 2.20833 2 2.66667 2H16C16.4583 2 16.8507 2.16319 17.1771 2.48958C17.5035 2.81597 17.6667 3.20833 17.6667 3.66667V8.10417C17.4167 7.92361 17.1528 7.76389 16.875 7.625C16.5972 7.48611 16.3056 7.36806 16 7.27083V5.33333H2.66667V8.66667H10.1667C9.625 9.19444 9.19792 9.81597 8.88542 10.5312C8.57292 11.2465 8.41667 12.0139 8.41667 12.8333C8.41667 13.2778 8.46528 13.7118 8.5625 14.1354C8.65972 14.559 8.79861 14.9583 8.97917 15.3333H2.66667Z" fill="#3C3C3C"/>
            </svg>
        );
      case 'Support':
        return (
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.834 6.66659C10.834 7.55064 10.4828 8.39849 9.85767 9.02361C9.23255 9.64873 8.3847 9.99992 7.50065 9.99992C6.6166 9.99992 5.76875 9.64873 5.14363 9.02361C4.51851 8.39849 4.16732 7.55064 4.16732 6.66659C4.16732 5.78253 4.51851 4.93468 5.14363 4.30956C5.76875 3.68444 6.6166 3.33325 7.50065 3.33325C8.3847 3.33325 9.23255 3.68444 9.85767 4.30956C10.4828 4.93468 10.834 5.78253 10.834 6.66659ZM14.1673 14.9999V16.6666H0.833984V14.9999C0.833984 13.1583 3.81732 11.6666 7.50065 11.6666C11.184 11.6666 14.1673 13.1583 14.1673 14.9999ZM17.084 12.0833V13.3333H15.834V12.0833H17.084ZM15.4173 7.91658H14.1673V7.49992C14.1673 6.83688 14.4307 6.20099 14.8995 5.73215C15.3684 5.26331 16.0043 4.99992 16.6673 4.99992C17.3304 4.99992 17.9662 5.26331 18.4351 5.73215C18.9039 6.20099 19.1673 6.83688 19.1673 7.49992C19.1673 8.30825 18.7506 9.06659 18.0923 9.50825L17.8423 9.66659C17.3673 9.99992 17.084 10.5083 17.084 11.0833V11.2499H15.834V11.0833C15.834 10.0916 16.334 9.16659 17.159 8.62492L17.4006 8.46659C17.7256 8.24992 17.9173 7.89159 17.9173 7.49992C17.9173 7.1684 17.7856 6.85046 17.5512 6.61603C17.3168 6.38161 16.9988 6.24992 16.6673 6.24992C16.3358 6.24992 16.0179 6.38161 15.7834 6.61603C15.549 6.85046 15.4173 7.1684 15.4173 7.49992V7.91658Z" fill="#3C3C3C"/>
            </svg>
        );
      // Add more icons here...
      default:
        return <span>Icon not found</span>; 
    }
  };

  return <>{renderIcon()}</>;
};

export default SvgIcon;
