import React from "react";

import "aos/dist/aos.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./style.scss";

import { LANG } from "../../constants/language";
import HomeHeader from "./home-header/HomeHeader";
import HomeFooter from "./home-footer/HomeFooter";
import HomeSubscribe from "./home-subscribe/HomeSubscribe";
import HomeFollow from "./home-follow/HomeFollow";
import ThankYou from "./thank-you/ThankYou";
import { useSelector } from "react-redux";
import { HomePageSelector } from "../../core/data/redux/webpage/webPageAction";
import ReactQuill from 'react-quill';
import { WebPage } from "../../types/webpage";
import { Link } from "react-router-dom";
const Home = () => {
  const homepage: WebPage = useSelector(HomePageSelector);
  return (
    <>
      <div className="homepage">
        <section className="homeBanner">
          <HomeHeader />
          <div className="bannerWrap">
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <div className="bannerContent">
                    <h1>{homepage?.home?.hero?.title}</h1>
                    <p>Gemeinsam stark auf und neben dem Platz - Werde Teil der KV Mühlheim Familie!</p>
                    <Link to={homepage?.home?.hero?.buttonLink||"/"} relative={'path'}><button className="appBtn">{homepage?.home?.hero?.buttonText}</button></Link>
                  </div>
                </div>
                <div className="col-md-6"></div>
              </div>
            </div>
          </div>
        </section>
        <section className="stadtSection">
          <div className="container">
            <div className="row align-items-start">
              <div className="col-md-6">
                <div className="stadtImg pe-lg-5">
                  <img src={homepage?.home?.hero?.banner||'/assets/img/leftImg.jpg'} alt='leftImg' className="w-100 h-auto" />
                </div>
              </div>
              <div className="col-md-6">
                <div className="ps-lg-5">
                  <div>
                    <ReactQuill value={homepage?.home?.about?.content}
                      readOnly={true}
                      theme={"bubble"} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <ThankYou />
        <HomeFollow />
        <HomeSubscribe />
      </div>
    </>
  )
}

export default Home;
