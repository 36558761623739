export const NATIONALITIES = [
    "Afghanisch",
    "Ägyptisch",
    "Äquatorialguineisch",
    "Albanisch",
    "Algerisch",
    "Amerikanisch",
    "Andorranisch",
    "Angolanisch",
    "Antiguanisch",
    "Aserbaidschanisch",
    "Äthiopisch",
    "Australisch",
    "Österreichisch",
    "Bahamasisch",
    "Bahrainisch",
    "Bangladeschisch",
    "Barbadisch",
    "Barbudanisch",
    "Belgisch",
    "Belarussisch",
    "Belizisch",
    "Beninisch",
    "Bhutanisch",
    "Bolivianisch",
    "Bosnisch",
    "Botsuanisch",
    "Brasilianisch",
    "Britisch",
    "Bruneiisch",
    "Bulgarisch",
    "Burkinisch",
    "Burundisch",
    "Chilenisch",
    "Chinesisch",
    "Costa-ricanisch",
    "Dänisch",
    "Dominikanisch",
    "Dschibutisch",
    "Ecuadorianisch",
    "Emiratisch",
    "Eritreisch",
    "Estnisch",
    "Fidschianisch",
    "Finnisch",
    "Französisch",
    "Gabunisch",
    "Gambisch",
    "Georgisch",
    "Deutsch",
    "Ghanaisch",
    "Griechisch",
    "Grenadisch",
    "Guatemaltekisch",
    "Guinea-bissauisch",
    "Guineisch",
    "Guyanisch",
    "Haitianisch",
    "Honduranisch",
    "Indisch",
    "Indonesisch",
    "Irakisch",
    "Iranisch",
    "Irisch",
    "Isländisch",
    "Israelisch",
    "Italienisch",
    "Ivorisch",
    "Japanisch",
    "Jordanisch",
    "Kamerunisch",
    "Kanadisch",
    "Kapverdisch",
    "Kasachisch",
    "Katarisch",
    "Keniaisch",
    "Kiribatisch",
    "Kirgisisch",
    "Kolumbianisch",
    "Komorisch",
    "Kongolesisch",
    "Kroatisch",
    "Kubanisch",
    "Kuwaitisch",
    "Laotisch",
    "Lettisch",
    "Libanesisch",
    "Liberianisch",
    "Libysch",
    "Liechtensteinisch",
    "Litauisch",
    "Luxemburgisch",
    "Madagassisch",
    "Malawisch",
    "Malaysisch",
    "Maledivisch",
    "Malisch",
    "Maltesisch",
    "Marshallisch",
    "Mauretanisch",
    "Mauritisch",
    "Mexikanisch",
    "Mikronesisch",
    "Moldauisch",
    "Monégassisch",
    "Mongolisch",
    "Montenegrinisch",
    "Mosambikanisch",
    "Namibisch",
    "Nauruisch",
    "Nepalesisch",
    "Neuseeländisch",
    "Nicaraguanisch",
    "Niederländisch",
    "Nigrisch",
    "Nigerianisch",
    "Nordirisch",
    "Nordkoreanisch",
    "Norwegisch",
    "Östtimoresisch",
    "Omanisch",
    "Pakistanisch",
    "Palauisch",
    "Panamaisch",
    "Papua-neuguineisch",
    "Paraguayisch",
    "Peruanisch",
    "Philippinisch",
    "Polnisch",
    "Portugiesisch",
    "Ruandisch",
    "Rumänisch",
    "Russisch",
    "Salvadorianisch",
    "Samoanisch",
    "São-toméisch",
    "Saudi-arabisch",
    "Schottisch",
    "Schwedisch",
    "Schweizerisch",
    "Senegalesisch",
    "Serbisch",
    "Seychellisch",
    "Sierra-leonisch",
    "Singapurisch",
    "Slowakisch",
    "Slowenisch",
    "Somalisch",
    "Spanisch",
    "Sri-lankisch",
    "St. Lucianisch",
    "Sudanesisch",
    "Surinamisch",
    "Swasiländisch",
    "Südkoreanisch",
    "Südafrikanisch",
    "Syrisch",
    "Tadschikisch",
    "Taiwanisch",
    "Tansanisch",
    "Thailändisch",
    "Togoisch",
    "Tonganisch",
    "Trinidadisch",
    "Tschadisch",
    "Tschechisch",
    "Tunesisch",
    "Türkisch",
    "Tuvaluisch",
    "Ugandisch",
    "Ukrainisch",
    "Ungarisch",
    "Uruguayisch",
    "Usbekisch",
    "Venezolanisch",
    "Vietnamesisch",
    "Walisisch",
    "Zentralafrikanisch",
    "Sambisch",
    "Simbabwisch"
];
