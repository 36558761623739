import React, { useEffect, useRef } from 'react'
import './style.scss';
import { FormGroup } from 'react-bootstrap';
import HomeFollow from '../../../home/home-follow/HomeFollow';
import HomeSubscribe from '../../../home/home-subscribe/HomeSubscribe';
import { WebPage } from '../../../../types/webpage';
import { useSelector } from 'react-redux';
import { MannschaftPage1Selector } from '../../../../core/data/redux/webpage/webPageAction';
import { Link } from 'react-router-dom';
export default function Mannschaft1() {
    const mannschaft1: WebPage = useSelector(MannschaftPage1Selector);
    const fussballRef: any = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (mannschaft1?.mannschaftFirst?.fussballScript!==undefined) {
            fussballRef.current.innerHTML = mannschaft1?.mannschaftFirst?.fussballScript||"";
            const script = document.createElement('script');
            script.src = 'https://www.fussball.de/widgets.js'; // URL to the widget script
            script.async = true;
            script.defer = true;
            document.body.appendChild(script);
            return () => {
                document.body.removeChild(script);
            };
        }
    }, [mannschaft1?.mannschaftFirst?.fussballScript])
    return (
        <div className='teamsPage BgLightBlue'>
            <div className='teamsPageContent'>
                <div className='singleTeam'>
                    <div className='row'>
                        <div className='col-sm-7'>
                            <div className='teamContent'>
                                <h1 className='pb-4'>{mannschaft1?.mannschaftFirst?.title}</h1>
                                <FormGroup className='mb-5'>
                                    <h4>{mannschaft1?.mannschaftFirst?.trainerName}</h4>
                                    <p>Trainer - <Link to={"/verein/vorstand"}>{mannschaft1?.mannschaftFirst?.contactPerson}</Link></p>
                                </FormGroup>
                                <FormGroup className='mb-5'>
                                    <h4>Trainingszeiten:</h4>
                                    <p>{mannschaft1?.mannschaftFirst?.trainingTimes}</p>
                                </FormGroup>
                            </div>
                        </div>
                        <div className='col-sm-5'>
                            <div className='teamImg'>
                            <img src={mannschaft1?.mannschaftFirst?.trainerImage||'/assets/img/default-avatar.png'} onError={(e) => { 
                                    e.currentTarget.src = '/assets/img/default-avatar.png'; 
                                  }} alt='avatar-06' />
                            </div>
                        </div>
                    </div>
                </div>
                <div ref={fussballRef}>
                </div>
            </div>
            <HomeFollow />
            <HomeSubscribe />
        </div>
    )
}
