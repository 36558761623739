import React from "react";

export default function CopyTextIcon() {
  return (
<svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<mask id="mask0_56_3838" style={{maskType:"alpha"}} maskUnits="userSpaceOnUse" x="0" y="0" width="21" height="20">
<rect x="0.5" width="20" height="20" fill="#D9D9D9"/>
</mask>
<g mask="url(#mask0_56_3838)">
<path d="M8 14.9998C7.54167 14.9998 7.14931 14.8366 6.82292 14.5103C6.49653 14.1839 6.33333 13.7915 6.33333 13.3332V3.33317C6.33333 2.87484 6.49653 2.48248 6.82292 2.15609C7.14931 1.8297 7.54167 1.6665 8 1.6665H15.5C15.9583 1.6665 16.3507 1.8297 16.6771 2.15609C17.0035 2.48248 17.1667 2.87484 17.1667 3.33317V13.3332C17.1667 13.7915 17.0035 14.1839 16.6771 14.5103C16.3507 14.8366 15.9583 14.9998 15.5 14.9998H8ZM8 13.3332H15.5V3.33317H8V13.3332ZM4.66667 18.3332C4.20833 18.3332 3.81597 18.17 3.48958 17.8436C3.16319 17.5172 3 17.1248 3 16.6665V4.99984H4.66667V16.6665H13.8333V18.3332H4.66667Z" fill="#3C3C3C"/>
</g>
</svg>

  );
}
