import React from 'react'
import { WebPage } from '../../../types/webpage';
import { HomePageSelector } from '../../../core/data/redux/webpage/webPageAction';
import { useSelector } from 'react-redux';

export default function ThankYou() {
  const homepage: WebPage = useSelector(HomePageSelector);
  return (
    <section className="thankSection">
      <div className="contain">
        <h2 className="text-center text-uppercase">{homepage?.home?.digitalHesse?.title}</h2>
        <div className="row">
          <div className="col-md-12 col-sm-12">
            <div className="img">
              <img src={homepage?.home?.digitalHesse?.image||"/assets/img/hessen.jpg"} alt="hessen" />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
