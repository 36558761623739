import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { LANG } from '../../../../constants/language';
import { useDispatch, useSelector } from 'react-redux';
import { setRequestModalStatus } from '../../../data/redux/shared/sharedSlice';
import { getFriendRequest, updateFriend } from '../../../../services/friends.service';
import VisibilityBox from '../../VisibilityBox';
import CrossRedCircleIcon from '../../../../icons/CrossRedCircleIcon';
import TickBlueCircleIcon from '../../../../icons/TickBlueCircleIcon';

const FriendsRequestModal = (props: any) => {
    const fileUrl = process.env.REACT_APP_FILE_URL;
    const [friendsRequest, setFriendsRequest] = useState<any[]>([]);
    const { requestModalStatus } = useSelector((state: any) => state.shared);
    const dispatch: any = useDispatch();

    useEffect(() => {
        getData();
    }, [])

    const getData = async () => {
        const [myRequests] = await Promise.all([getFriendRequest({})]);
        if (myRequests.status == 200) {
            setFriendsRequest(myRequests.data?.data);
        }
    }
    const closeModal = () => {
        dispatch(setRequestModalStatus(false));
    }

    const acceptRequest = async (request: any) => {
        try {
            const result = await updateFriend(request?._id, { status: 'accepted' });
            if (result.status == 200) {
                await getData();
                props?.refresh();
            }
        } catch (error) {
            console.log(error)
        }
    }

    const declineRequest = async (request: any) => {
        try {
            const result = await updateFriend(request?._id, { status: 'rejected' });
            if (result.status == 200) {
                await getData();
            }
        } catch (error) {
            console.log(error)
        }
    }
    return (
        <Modal
            {...props}
            onHide={closeModal}
            show={requestModalStatus}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <span className='fs-6'>{LANG.FRIEND_REQUESTS}</span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className='my-2'>
                <VisibilityBox show={friendsRequest?.length > 0}>
                    {
                        friendsRequest?.map((fRequest: any, index: number) =>
                            <div key={index} className="mb-2 d-flex align-items-center justify-content-between">
                                <div className="pendingFriendImg  d-flex align-items-center flex-wrap">
                                    <img
                                        className='rounded-circle'
                                        src={fRequest?.sender?.avatar ? fileUrl + fRequest?.sender?.avatar : "/assets/img/default-avatar.png"}
                                        width={40}
                                        height={40}
                                        alt="friendOne"
                                    />
                                    <h6 className='ms-2'>{fRequest?.sender?.firstName} {fRequest?.sender?.lastName}</h6>
                                </div>
                                <div className="bothBtn">
                                    <button className="btn crossBtn" onClick={() => declineRequest(fRequest)}>
                                        <CrossRedCircleIcon />
                                    </button>
                                    <button className="btn tickBtn" onClick={() => acceptRequest(fRequest)}>
                                        <TickBlueCircleIcon />
                                    </button>
                                </div>
                            </div>
                        )
                    }
                </VisibilityBox>
                <VisibilityBox show={friendsRequest?.length==0}>
                     <h4 className='text-center p-4'>{LANG.NO_FRIEND_REQUESTS}</h4>
                </VisibilityBox>
                <div className='d-flex justify-content-end'>
                    <Button type='button' onClick={closeModal} variant='outline-secondary' className='me-2' >
                        {LANG.CLOSE}
                    </Button>
                </div>
            </Modal.Body >
        </Modal >

    )
}

export default FriendsRequestModal;