import React, { memo, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import EventCalendar from '../trainer/event-calendar/EventCalendar'

const ReportCalendar = memo(function ReportCalendar(props: any){
    const [events, setEvents]= useState<any[]>([]);
    useEffect(()=>{
        if(props?.events?.length){
            const evs: Event[] = props?.events?.map((event: any) => ({
              title: event?.eventType,
              date: event?.date,
              extendedProps: event,
            }));
          setEvents(evs);
        }
    },[props?.events]);
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className='report-calendar'
        >
            <Modal.Body>
               <EventCalendar events={events} />
            </Modal.Body>
        </Modal>
    )
})

export default ReportCalendar