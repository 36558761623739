import React from 'react'

const SlotsIcon = () => {
  return (
    <svg width="33" height="33" viewBox="0 0 28 26" fill="#D9D9D9" xmlns="http://www.w3.org/2000/svg">
<path d="M12.9334 20.1667L18.4667 16.6334C18.8667 16.3667 19.0667 15.9889 19.0667 15.5C19.0667 15.0111 18.8667 14.6334 18.4667 14.3667L12.9334 10.8334C12.4889 10.5445 12.0334 10.5222 11.5667 10.7667C11.1 11.0111 10.8667 11.4 10.8667 11.9334V19.0667C10.8667 19.6 11.1 19.9889 11.5667 20.2334C12.0334 20.4778 12.4889 20.4556 12.9334 20.1667ZM3.53337 25.5C2.80003 25.5 2.17225 25.2389 1.65003 24.7167C1.12781 24.1945 0.866699 23.5667 0.866699 22.8334V6.83335C0.866699 6.45558 0.994477 6.13891 1.25003 5.88335C1.50559 5.6278 1.82225 5.50002 2.20003 5.50002H8.8667V2.83335C8.8667 2.10002 9.12781 1.47224 9.65003 0.95002C10.1723 0.427798 10.8 0.166687 11.5334 0.166687H16.8667C17.6 0.166687 18.2278 0.427798 18.75 0.95002C19.2723 1.47224 19.5334 2.10002 19.5334 2.83335V5.50002H26.2C26.5778 5.50002 26.8945 5.6278 27.15 5.88335C27.4056 6.13891 27.5334 6.45558 27.5334 6.83335V22.8334C27.5334 23.5667 27.2723 24.1945 26.75 24.7167C26.2278 25.2389 25.6 25.5 24.8667 25.5H3.53337ZM11.5334 5.50002H16.8667V2.83335H11.5334V5.50002Z" fill="#000000"/>
</svg>

  )
}

export default SlotsIcon