import React, { useEffect, useState } from 'react';
import { Modal, Button, Form, ListGroup, InputGroup } from 'react-bootstrap';
import SearchIcon from '../../../../icons/SearchIcon';
import { LANG } from '../../../../constants/language';
import _ from 'lodash';
import { getUserID } from '../../../../services/user.service';
import { getAllTeamsByUserId } from '../../../../services/teams.service';

interface Props {
  show: boolean;
  teams: any[],
  handleClose: () => void;
  select(m: any[]): void
}

export function SelectTeamsModal({ show, teams: saveTeams, handleClose, select }: Props) {
  const [selectedTeams, setSelectedTeams] = useState<any>([]);
  const [teams, setTeams] = useState<any[]>([]);
  const [tempTeams, setTempTeams] = useState<any[]>([]);

  useEffect(() => {
    getData();
    if (saveTeams?.length) {
      setTimeout(() => {
        setSelectedTeams(saveTeams);
      }, 400);
    }
  }, [saveTeams, show]);

  const getData = async () => {
    try {
        const id = getUserID()
        const result = await  getAllTeamsByUserId(id);
        let mergeTeams: any[] = result?.data?.data||[];
        mergeTeams = [...saveTeams, ...mergeTeams];
        const uniqueTeams: any[] = _.uniqBy(mergeTeams, '_id');
        setTeams(uniqueTeams);
        setSelectedTeams(saveTeams);
        setTempTeams(uniqueTeams);
    } catch (error) {
      console.log(error);
    }
  }

  const searchFriends = async (text: string) => {
    try {
      if (!text) {
        setTeams(tempTeams);
        return;
      }
      const filteredData = tempTeams?.filter((datas:any) => {
        const values = Object.values(datas).map((value:any) =>
          value.toString().toLowerCase()
        );
        return values?.some((value) => value.includes(text?.toLowerCase()));
      });
      setTeams(filteredData);
    } catch (error) {
      console.log(error)
    }
  }
  // Sample member data
  const toggleSelect = (team: any) => {
    setSelectedTeams((prevSelected: any) =>
      prevSelected.includes(team) ? prevSelected.filter((m: any) => m?._id !== team?._id) : [...prevSelected, team]
    );
  };

  const submit = () => {
    select(selectedTeams);
    handleClose();
  }

  return (
    <Modal show={show} onHide={handleClose} centered >
      <Modal.Header closeButton>
        <Modal.Title>{LANG.ALL_TEAMS}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* Search Bar */}
        <InputGroup className="mb-3">
          <InputGroup.Text className='border-0'>
            <SearchIcon />
          </InputGroup.Text>
          <Form.Control placeholder={LANG.SEARCH_TEAM} onChange={(e) => searchFriends(e.target.value)} />
        </InputGroup>

        {/* Members List */}
        <ListGroup variant="flush" style={{ maxHeight: '400px', overflowY: 'auto' }}>
          {teams.map((member: any) => (
            <label key={member._id} className='mb-1 rounded' htmlFor={'member-check-' + member?._id}>
              <ListGroup.Item
                className={`d-flex align-items-center ${selectedTeams.includes(member) ? 'bg-light' : ''
                  }`}
                style={{ cursor: 'pointer' }}
              >
                <span className="flex-grow-1 ms-2">{member?.teamName || 'NA'}</span>
                <Form.Check
                  type="checkbox"
                  id={'member-check-' + member?._id}
                  checked={selectedTeams.includes(member)}
                  onChange={() => toggleSelect(member)}
                  className="ml-auto"
                />
              </ListGroup.Item>
            </label>
          ))}
        </ListGroup>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" className='w-100' onClick={submit}>
          {LANG.CHOOSE_SELECTED_MEMBER}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
