import React from 'react'
import { Link } from 'react-router-dom';
import InstagramBlueIcon from '../../../icons/InstagramBlueIcon';
import FacebookBlueIcon from '../../../icons/FacebookBlueIcon';
import YouTubeBlueIcon from '../../../icons/YouTubeBlueIcon';
import { LANG } from '../../../constants/language';
import { WebPage } from '../../../types/webpage';
import { useSelector } from 'react-redux';
import { FooterPageSelector, HomePageSelector } from '../../../core/data/redux/webpage/webPageAction';
import { scrollToTop } from '../../../utils';
const HomeFooter = () => {
    const footer: WebPage = useSelector(FooterPageSelector);
    const homepage: WebPage = useSelector(HomePageSelector);
    return (
        <section className="footerSection">
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <div className="footer_Logo">
                            <img src={'/assets/img/logo.png'} alt="logo" />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="footer_menu">
                            <h3>{LANG.MENU}</h3>
                            <ul>
                                <li><Link to={'/'} onClick={scrollToTop}>{LANG.HOME}</Link></li>
                                <li><Link to={'/verein/vorstand'} onClick={scrollToTop}>Verein</Link></li>
                                <li><Link to={'/teams/mannschaft1'} onClick={scrollToTop}>{LANG.TEAMS}</Link></li>
                                <li><Link to={'/sponsoren'} onClick={scrollToTop}>Sponsoren</Link></li>
                                <li><Link to={'/downloads'} onClick={scrollToTop}>{LANG.DOWNLOAD}</Link></li>
                                <li><Link to={'/application'} onClick={scrollToTop}>App</Link></li>
                                <li><Link to={'/contact'} onClick={scrollToTop}>Kontakt</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6"><p className="mb-0">{new Date().getFullYear()} KV Mühlheim</p></div>
                    <div className="col-md-6">
                        <ul className="footerSocialLinks my-3">
                            <li><Link to={homepage?.home?.socialMedia?.instagram || "/"} target='blank' relative={'path'}><InstagramBlueIcon /></Link></li>
                            <li><Link to={homepage?.home?.socialMedia?.facebook || "/"} target='blank' relative={'path'}><FacebookBlueIcon /></Link></li>
                            <li><Link to={homepage?.home?.socialMedia?.youtube || "/"} target='blank' relative={'path'}><YouTubeBlueIcon /></Link></li>
                        </ul>
                        <ul className="imprintMenu">
                            <li><Link to={'/impressum'} onClick={scrollToTop}>Impressum</Link></li>
                            <li><Link to={'/datenschutz'} onClick={scrollToTop}>Datenschutz</Link></li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default HomeFooter