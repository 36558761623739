export const LANG = {
    WELCOME_BACK: "Willkommen zurück",
    LOGIN_MSG: "Melden Sie sich an, um über die neuesten Nachrichten, Spiele und exklusiven Inhalte die neuesten Informationen zu erhalten.",
    PASSWORD: "Passwort",
    REMEMBER_PASSWORD: "Passwort speichern",
    FORGOT_PASSWORD: "Passwort vergessen?",
    LOG_IN: "Anmelden",
    HAVE_NOT_ACCOUNT: "Sie haben noch kein Konto?",
    REGISTER: "Registrieren",
    REGISTER_MSG: "Jetzt beitreten, um mit Fans in Kontakt zu treten, auf exklusive Inhalte zuzugreifen und die neuesten Informationen zu erhalten!",
    BACK: "Zurück",
    JOIN: "Verbinden",
    SELECT: "auswählen",
    LAST_CLUB: "Letzter Club",
    FIRST_NAME: "Vorname",
    ID:"ID",
    DIGITAL_CARD:"Digitale Karte",
    LAST_NAME: "Nachname",
    STREET: "Straße",
    HOUSE_NO: "Hausnummer",
    ZIP_CODE: "PLZ",
    CITY: "Stadt",
    BIRTHDAY: "Geburtstag",
    BIRTHPLACE_CITY: "Geburtsort",
    BIRTHPLACE_COUNTRY: "Geburtsland",
    NATIONALITY: "Nationalität",
    PHONE: "Telefon",
    FIRSTNAME_PARENTS: "Vorname (Erziehungsberechtigter / Eltern)",
    LASTNAME_PARENTS: "Nachname (Erziehungsberechtigter / Eltern)",
    EMAIL_PARENTS: "Email (Erziehungsberechtigter / Eltern)",
    PHONE_PARENTS: "Telefon (Erziehungsberechtigter / Eltern)",
    RELATION: "Beziehung",
    GENDER: "Geschlecht",
    BANK_NAME: "Name des Kreditinstitutes",
    IBAN_BANK_ACCOUNT: "IBAN",
    BIC_BANK_CODE: "BIC",
    ACCOUNT_HOLDER: "Name des Kontoinhabers",
    PHOTO: "Foto",
    ATTACHMENTS: "Anhänge",
    CONTINUE: "Weiter",
    HAVE_AN_ACCOUNT: "Haben Sie ein Konto?",
    SIGN_IN: "Anmelden",
    PLEASE_CHOOSE: "Bitte auswählen",
    REGISTER_PARENT_MSG: "Um fortzufahren, müssen Sie die Zustimmung eines Elternteils oder Erziehungsberechtigten vorlegen.",
    SELECT_PLAN: "Wählen Sie Planen aus",
    REGISTER_PLAN_MSG: "Wählen Sie den Plan, der Ihren Bedürfnissen am besten entspricht, und genießen Sie exklusive Vorteile.",
    INDIVIDUAL_YOUTH_MEMBERSHIP: "Individuelle Jugendmitgliedschaft",
    FAMILY_PLAN: "Familienplan",
    SPECIAL_PLAN: "Sonderpläne",
    PRIVACY_HEADING: "Einwilligung zur Datenschutzerklärung",
    PRIVACY_MSG: "Mit dem Absenden dieses Formulars bestätige ich, dass ich die Datenschutzerklärung gelesen und verstanden habe. Ich willige ein, dass meine personenbezogenen Daten gemäß der Datenschutzerklärung verarbeitet werden",
    PRIVACY_POLICY: "Datenschutzrichtlinie",
    DATA_POCILY_HEADING: "Einwilligung zur Verwendung der Daten zu Werbezwecken",
    DATA_POLICY_MSG: "Hiermit erkläre ich mich einverstanden, dass meine Kontaktdaten (z.B. E-Mail-Adresse und Telefonnummer) zu Werbezwecken, wie dem Erhalt von Informationen zu Produkten, Dienstleistungen oder Veranstaltungen des Unternehmens, verwendet werden. Diese Einwilligung kann ich jederzeit mit Wirkung für die Zukunft widerrufen, indem ich eine entsprechende Mitteilung an [Kontaktadresse einfügen] sende.",
    TERMS_AND_CONDITION: "AGBs (Satzung)",
    I_AGREE_TO: "Ich stimme zu",
    UPLOAD_DOCUMENT: "Dokument hochladen",
    APPLICATION_FOR_MATCH_PERMISSIONS: "Antrag auf Spielerlaubnis (Bitte Antrag auf Spielerlaubnis, downloaden, ausfüllen und hochladen)",
    APPLICATION_FOR_CLUB_TRANSFER: "Antrag auf Spielerlaubnis (Bitte Antrag auf Spielerlaubnis, downloaden, ausfüllen und hochladen)",
    BIRTH_CERTIFICATE: "Kopie der Geburtsurkunde",
    DOCTOR_CERTIFICATE: "Ärztliches Attest",
    RESIDENCE_CERTIFICATION: "Nachweis über den Wohnsitz in der Bundesrepublik Deutschland (Bestätigung der Meldebehörde)",
    PLAYER_PARENTS_DECLARATION: "Zusatzformular to_players_parents zu finden im Download-Bereich unter www.hfv-online.de",
    COPY_OF_PASSPORT: "Kopie des Personalausweises / Reisepasses / Ausweisdokument (Aufenthaltsgestattung /- nehmigung / -erlaubnis)",
    APPLICATION_ATTACHMENT_ARGETINA: "Ausgefülltes Formblatt zu finden im Download-Bereich unter www.hfv-online.de",
    APPLICATION_ATTACHMENT_ISTUPNICA: "Abmeldung beim Verein (Istupnica)",
    APPLICATION_ATTACHMENT_BRISOVNICA: "Abmeldebestätigung von dem zuständigen Regionalverband (Brisovnica)",
    REGISTRATION_SUCCESSFULLY_DONE: "Registrierung erfolgreich abgeschlossen.",
    LOGIN_SUCCESSFULLY: "Anmeldung erfolgreich",
    USER_PROFILE: "Benutzerprofil",
    DASHBAORD: "Dashboard",
    EVENTS: "Veranstaltungen",
    FRIENDS: "Freunde",
    PROFILE: "Profil",
    HOME: "Home",
    ACCOUNT_SETTING: "Kontoeinstellung",
    CHANGE_PASSWORD: "Kennwort ändern",
    SUBSCRIPTION: "Abonnement",
    SUBSCRIPTION_UPDATED_SUCCESSFULLY: "Abonnement erfolgreich aktualisiert.",
    HELP_AND_SUPPORT: "Hilfe und Support",
    EDIT_ACCOUNT_DETAILS: "Kontodetails bearbeiten",
    UPLOAD_PHOTO: "Foto hochladen",
    PERSONAL_INFORMATION: "Persönliche Informationen",
    ADDRESS: "Adresse",
    BANK_DETAIL: "Bankverbindung",
    DOCUMENTS: "Unterlagen",
    UPDATE: "Aktualisieren",
    PASSWORD_AND_SECURITY: "Passwort und Sicherheit",
    UPDATE_YOUR_PASSWORD: "Aktualisieren Sie Ihr Passwort",
    ENTER_OLD_PASSWORD: "Geben Sie das alte Passwort ein",
    ENTER_NEW_PASSWORD: "Geben Sie ein neues Passwort ein",
    ENTER_CONFIRM_PASSWORD: "Geben Sie das Bestätigungspasswort ein",
    PASSWORD_CHANGED_SUCCESSFULLY: "Passwort erfolgreich geändert.",
    SETTINGS: "Einstellungen",
    LOGOUT: "Abmelden",
    CONTACT_US: "Kontaktieren Sie uns",
    ENTER_REGISTERED_EMAIL: "Geben Sie die registrierte E-Mail-Adresse ein",
    SUBMIT: "Bestätigen",
    INVALID_EMAIL_ADDRESS: "Ungültige E-Mail-Address.",
    OTP_EXPIRED: "Warten Sie auf die Genehmigung des Administrators",
    ACCOUNT_VERIFICATION: "Kontobestätigung",
    INVALID_LINK: "Ungültiger Link",
    ACCOUNT_VERIFIED_SUCCESSFULLY: "Deine Email wurde veriziert. Bitte warte bis Deine Mitgliedschaft bestätigt wird",
    LINK_EXPIRED: "Link abgelaufen",
    USER_NOT_FOUND: "Benutzer nicht gefunden",
    ENTER_EMAIL_ADDRESS: "Geben Sie die Email ein",
    EMAIL_IS_REQUIRED: "Email ist erforderlich",
    EMAIL_ALREADY_EXISTS: "Email existiert bereits",
    PASSWORD_IS_REQUIRED: "Passwort ist erforderlich",
    CONFIRM_PASSWORD_IS_REQUIRED: "Bestätigen passwort ist erforderlich",
    PASSWORD_MUST_MATCH: "Das Passwort muss übereinstimmen",
    PLEASE_ADD_VALID_EMAIL: "Bitte fügen Sie eine gültige Email hinzu",
    FIELD_IS_REQUIRED: "Feld ist erforderlich",
    FIRSTNAME_IS_REQUIRED: "Vorname ist erforderlich",
    LASTNAME_IS_REQUIRED: "Nachname ist erforderlich",
    BIRETHDATE_IS_REQUIRED: "Geburtsdatum ist erforderlich",
    STREET_IS_REQUIRED: "Straße ist erforderlich",
    HOUSE_NO_IS_REQUIRED: "Hausnummer ist erforderlich",
    ZIP_CODE_IS_REQUIRED: "PLZ ist erforderlich",
    CITY_IS_REQUIRED: "Stadt ist erforderlich",
    MAXIMUM_LIMIT_HUNDRED_CHAR: "Die Höchstgrenze beträgt 13 Zeichen",
    MAXIMUM_LIMIT_BIC_CHAR: "Die Höchstgrenze beträgt 11 Zeichen",
    MINIMUM_LIMIT_BIC_CHAR: "Die Mindestbeschränkung beträgt 8 Zeichen",
    MINIMUM_LIMIT_PHONE_CHAR: "Die Mindestbeschränkung beträgt 10 Zeichen",
    ZIPCODE_MUST_BE_FIVE_CHAR: "Die Postleitzahl muss 5-stellig sein",
    MEMBER_EMAIL: "E-Mail des Mitglieds",
    NAME: "Name",
    SIBLING_DETAILS: "Geschwisterdetails",
    ADD_SIBLING: "Geschwister hinzufügen",
    INVALID_MEMBER_ID: "Ungültige Mitglieds-Email",
    CONFIRM_PASSWORD: "Passwort bestätigen",
    PRIVATE: "Privat",
    PUBLIC: "öffentlich",
    MEMBER_ID: "Mitglieds-ID",
    JOINED: "Beigetreten",
    SEASON: "Saison",
    CONTACT: "Kontakt",
    EDIT_PROFILE: "Profil bearbeiten",
    ADD_NEW_FRIENDS: "Füge neue Freunde hinzu",
    FRIEND_REQUESTS: "Freundschaftsanfragen",
    FRIEND: "Freund",
    SEND_FRIEND_REQUESTS: "Freundschaftsanfrage senden",
    ACCEPT: "Akzeptieren",
    DECLINE: "Ablehnen",
    SEARCH_FRIENDS: "Suche Freunde",
    VIEW_ONLY: "Nur anzeigen",
    SELECT_THE_ROLE_TO_CONTINUE: "Wählen Sie die Rolle aus, um fortzufahren",
    MEMBER: "Mitglied",
    TRAINER: "Trainer",
    SPONSOR: "Sponsor",
    TASK: "Aufgabe",
    MY_TEAM: "Mein Team",
    TOTAL_TEAM_MEMBER: "Teammitglieder",
    ALL_MEMBERS: "Alle Mitglieder",
    VIEW_DETAILS: "Details anzeigen",
    TEAM: "Team",
    CREATE_NEW_TEAM: "Neues Team erstellen",
    ENTER_TEAM_NAME: "Geben Sie den Teamnamen ein",
    SELECT_MEMBER: "Wählen Sie Mitglied aus",
    SEARCH_MEMBER: "Mitglieder suchen",
    CHOOSE_SELECTED_MEMBER: "Wählen Sie das ausgewählte Mitglied aus",
    SELECTED_MEMBER: "Ausgewähltes Mitglied",
    CREATE_TEAM: "Team erstellen",
    TEAM_CREATED_SUCCESSFULLY: "Team erfolgreich erstellt.",
    EDIT_MEMBER: "Mitglied bearbeiten",
    UPDATED_SUCCESSFULLY: "Erfolgreich aktualisiert",
    CREATE_NEW_EVENT: "Neue Veranstaltung erstellen",
    NEW_EVENT: "Neue Veranstaltung",
    EVENT_DETAILS: "Veranstaltungsdetails",
    ASSIGNED_TRAINER: "Zugewiesener Trainer",
    BLOOD_GROUP: "Blutgruppe",
    PROFILE_UPDATED_SUCCESSFULLY: "Profil erfolgreich aktualisiert.",
    NO_FRIENDS_AVAILABLE: "Keine Freunde verfügbar",
    DESCRIPTION: "Beschreibung",
    MEMBERS_DETAIL: "Mitgliederdetails",
    ALREADY_SELECTED_IN_TEAM_B: "Bereits in Team B ausgewählt",
    ALREADY_SELECTED_IN_TEAM_A: "Bereits in Team A ausgewählt",
    EVENT_TYPE: "Ereignistyp",
    EVENT_DURATION: "Veranstaltungsdauer",
    ROOM_NAME: "Raumname",
    TIME: "Zeit",
    TIME_DURATION: "Länge",
    EVENT_DESCRIPTION: "Beschreibung der Veranstaltung",
    MAXIMUM_PARTICIPANTS: "Maximale Teilnehmerzahl",
    LOCATION: "Standort",
    SELECT_MEMBERS_TEAM_A: "Wählen Sie Mitgliedsteam A aus",
    SELECT_MEMBERS_TEAM_B: "Wählen Sie Mitgliedsteam B aus",
    TEAM_AND_PARTICIPANTS_TYPE: "Teams/Einzelteilnehmer",
    EVENT: "Event",
    PARTICIPANTS: "Teilnehmer",
    EVENT_NOT_FOUNDS: "Veranstaltungen nicht gefunden",
    EVENT_REQUEST_ACCEPTED_SUCCESSFULLY: "Ereignisanfrage erfolgreich angenommen",
    EVENT_REQUEST_REJECTED_SUCCESSFULLY: "Ereignisanfrage erfolgreich abgelehnt",
    ALERTS: "Alarm",
    EVENT_REQUEST: "Alarm",
    GROUND_NAME: "Platz",
    DURATION: "Dauer",
    DATE: "Datum",
    ACTION: "Auswahl",
    AGE: "Alter",
    YEARS: "Jahre",
    MEMBERSHIP_EXPIRE: "Mitgliedschaft läuft aus",
    APPROVED: "Genehmigt!",
    UNDER_REVIEW: "Wird überprüft",
    NOTIFICATIONS: "Benachrichtigungen",
    NO_DATA: "Keine Daten",
    ongoingTask: "Laufende Aufgabe",
    task_name: "Aufgabe",
    assign_date: "Datum",
    description: "Beschreibung",
    status: "Status",
    comment: "Kommentare/Feedback",
    deadline: "Frist",
    action: "Auswahl",
    details: "Details",
    no_ongoing_task_available: "Keine laufenden Aufgaben verfügbar",
    completed_task: "Abgeschlossene Aufgabe",
    no_completed_tasks_available: "Keine abgeschlossenen Aufgaben verfügbar",
    inprogress: "Ausstehend",
    complete: "Erledigt",
    REQUEST_SENDED: "Anfrage gesendet",
    OLD_PASSWORD_IS_REQUIRED: "Altes Passwort ist erforderlich",
    NEW_PASSWORD_IS_REQUIRED: "Neues Passwort ist erforderlich",
    MEMBERS_IS_REQUIRED: "Mitglieder sind erforderlich.",
    TASK_DETAILS: "Aufgabendetails",
    EDIT_EVENT_DETAILS: "Bearbeiten Sie die Veranstaltungsdetails",
    EDIT: "Bearbeiten",
    CANCEL: "Abbrechen",
    CONFIRM:"Bestätigen",
    TASK_STATUS: "Aufgabenstatus",
    DEADLINE: "Frist",
    COMMENTS: "Kommentare",
    ADD_COMMENT: "Kommentar hinzufügen",
    INVALID_DOCUMENT_TYPE: "Ungültiger Dokumenttyp",
    PENDING: "Ausstehend",
    CLOSE: "Schließen",
    NO_FRIEND_REQUESTS: "Keine Freundschaftsanfragen",
    ACTIVE_SPONSORSHIPS: "Aktive Vergangene",
    PAST_SPONSORSHIPS: "Vergangene Sponsoring",
    COMPANY_DETAILS: "Firmendetails",
    COMPANY_NAME: "Firmenname",
    WEBSITE_URL: "Website-URL",
    COMPANY_LOGO: "Firmenlogo",
    OFFERINGS: "",
    COMPANY_DESCRIPTION: "Firmennummer",
    COMPANY_EMAIL: "Firmenemail",
    COMPANY_PHONE: "Firmentelefon",
    REJECT: "Ablehnen",
    CREATE_FORUM: "Erstellen Sie einen Beitrag",
    TITLE: "Titel",
    ADD_COVER: "Bild auswählen",
    ADD_IMAGE: "Bild hinzufügen",
    DELETE: "Löschen",
    FORUM: "Forum",
    SAVE: "Speichern",
    DELETED_SUCCESSFULLY: "Erfolgreich gelöscht",
    MESSAGE: "Nachricht",
    COMMENT: "Kommentar",
    ARE_YOU_SURE_WANT_TO_DELETE: "Möchten Sie es wirklich löschen?",
    ARE_YOU_SURE_WANT_TO_BOOK: "Bitte bestätigen Sie die Buchung des Sponsorings",
    UPDATE_TEAM_DETAIL: "Teamdetails aktualisieren",
    TEAM_NAME: "Teamname",
    AUTHOR: "Autor",
    MAXIMUM_FILE_SIZE_IS_TEN_MB: "Die maximale Dateigröße beträgt 10 MB",
    SHOW_MORE: "Mehr anzeigen",
    VIEW_ALL: "Alle anzeigen",
    TRAINING: "Training",
    PRACTICE: "Üben",
    BOOKING: "Buchung",
    BOOK_SLOT: "Buchen",
    AVAILABLE_SLOTS: "gEmplacements disponibles",
    NEW_SLOTS_AVAILABLE: "Nouveaux créneaux ajoutés",
    EMAIL: "E-MAIL",
    UPCOMING_EVENTS: "Kommende Veranstaltungen",
    EMPLOYMENT_DURATION: "Beschäftigungsdauer",
    LOADING: "Laden",
    SUBSCRIBE: "Abonnieren",
    SUBSCRIBE_EMAIL_SUCCESSFULLY: "E-Mail erfolgreich abonnieren",
    EVENT_REPORT: "Veranstaltungsbericht",
    MATCH: "Übereinstimmen",
    PRESENT: "Teilgenommen",
    NOT_PARTICIPATED: "Nicht teilgenommen",
    MANAGE_SUBSCRIPTION: "Abonnement verwalten",
    START_DATE: "Startdatum",
    END_DATE: "Enddatum",
    QUICK_LINKS: "Schnelle Links",
    TERMS_CONDITIONS: "Allgemeine Geschäftsbedingungen",
    COPYRIGHTS: "F.C. Kikers. Alle Rechte vorbehalten",
    ABOUT_US: "Über uns",
    BECOME_A_MEMBER: "Werden Sie Mitglied",
    SPONSERSHIP: "Sponsoring",
    GALLERY: "Galerie",
    FORUMS: "Foren",
    ONLY_JPG_PNG_IMAGES_ALLOWED:"Es sind nur JPG- oder PNG-Bilder zulässig.",
    SELECT_TEAM:"Wählen Sie Team",
    SELECTED_TEAMS:"Ausgewählte Teams",
    ALL_TEAMS: "Alle Teams",
    SEARCH_TEAM:"Suchteam",
    REASON:"Grund",
    VOCATION_DETAIL:"Einzelheiten zur Berufung",
    LAST_VACTION_DATE:"Letzter Urlaubstermin",
    VOCATION:"Berufung",
    NEXT:"Weiter",
    COMPLETE_REGISTRATION:"Registrierung abschließen",
    MISCELLANEOUS:"Sonstiges",
    PLAN:"Planen",
    APP_DOWNLOAD:"App Downloads",
    MENU:"Menü",
    DOWNLOAD:"Downloads",
    TEAMS:"Mannschaften",
    VOCATION_DATE_FROM:"Berufungsdatum von",
    VOCATION_DATE_TO:"Berufungsdatum bis",
    SUB_TITLE:"Untertitel",
    CONTACT_BUTTON_TEXT:"Werde Mitglied",
    ENTER_NEWS:"Geben Sie Neuigkeiten ein",
    THE_CONTACT_FORM_SUBMMITED_SUCCESSFULLY: "Das Kontaktformular wurde erfolgreich übermittelt.",
    ELAPSED:"Vergangen",
    SIGNATURE:"Unterschrift",
    DOWNLOAD_DOCUMENT:"Dokument herunterladen",
    ACCOUNT_HOLDER_SIGNATURE:"Unterschrift des Kontoinhabers",
    DOWNLOADS:"Downloads",
    APP:"App",
    LOGIN:"Login",
    SPONSORS:"Sponsoren",
    PDF_DOWNLOAD:"PDF herunterladen",
    SEND:"Einreichen",
    DOWNLOAD_THE_APP:"Laden Sie die App herunter",
    ENTER_YOUR_MSG_HERE:"Geben Sie hier Ihre Nachricht ein",
    BECOME_PART_OF_THE_CLUB_SUBTITLE:"Egal, ob Spieler, Fan oder Unterstützer: \n Bei uns ist jeder willkommen! Entdecke die Vorteile einer Mitgliedschaft und werde ein aktiver Teil unserer Gemeinschaft",
    JOIN_NOW:"JETZT MITGLIED WERDEN",
    SEND_BUTTON:"Senden",
    JAHRGANG:"Jahrgang",
    GAME:"Spiel",
    VIEW_TEAM:"Anzeigen",
    WEEK:"Woche",
    MONTH:"Monat",
    ANNUAL:"Jahr",
    MARK_AS_COMPLETE:"Erledigt",
    BOOK_CANCEL:"Abbrechen",
    BOOK_CONFIRM:"Bestätigen",
    SLOTS:"Deals",
    NO_ANY_SPONSORING:"Kein Sponsoring",
    PRICE:"Preis",
    SPONSORING:"Sponsoring",
    NEW_SPONSORING:"Neues Sponsoring",
    WAIT_FOR_CONFIRMATION:"Deine Email wurde verifiziert. Bitte warte bis Deine Mitgliedschaft bestätigt wird.",
    SPIEL:"Spiel",
    DIGITAL_CARD_DOWNLOADED_SUCCESS:"Digitale Karte erfolgreich heruntergeladen."
}
