import React from 'react'
import { Modal } from 'react-bootstrap'
import { LANG } from '../../../constants/language'
import moment from 'moment'
import { status_translation } from '../../../constants'
import RejectReasonTooltip from '../common/reject-reason-tooltip/RejectReasonTooltip'

const EventDetailModal = (props: any) => {
    return (
        <Modal
            {...props}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className='eventDetailModal'
        >
            <Modal.Header closeButton className=' border-0 pb-0'>
                <Modal.Title id="contained-modal-title-vcenter">
                    {LANG.EVENT_DETAILS}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className='p-3'>
                <div className='row mb-2'>
                    <div className='col-6'>
                        <span className='fw-bold'>{LANG.EVENT_TYPE}</span>
                    </div>
                    <div className='col-6'>
                        <span className='px-2'>{props?.event?.eventType}</span>
                    </div>
                </div>
                <div className='row mb-2'>
                    <div className='col-6'>
                        <span className='fw-bold'>{LANG.ROOM_NAME}</span>
                    </div>
                    <div className='col-6'>
                        <span className='px-2'>{props?.event?.room?.roomName}</span>
                    </div>
                </div>
                <div className='row mb-2'>
                    <div className='col-6'>
                        <span className='fw-bold'>{LANG.EVENT_DURATION}</span>
                    </div>
                    <div className='col-6'>
                        <span className='px-2'>{props?.event?.eventDuration}</span>
                    </div>
                </div>
                <div className='row mb-2'>
                    <div className='col-6'>
                        <span className='fw-bold'>{LANG.TIME_DURATION}</span>
                    </div>
                    <div className='col-6'>
                        <span className='px-2'>{props?.event?.timeDuration} Std.</span>
                    </div>
                </div>
                <div className='row mb-2'>
                    <div className='col-6'>
                        <span className='fw-bold'>{LANG.DATE}</span>
                    </div>
                    <div className='col-6'>
                        <span className='px-2'>{moment(props?.event?.date).format("DD MMM YYYY")} {props?.event?.time}</span>
                    </div>
                </div>

                <div className='row mb-2'>
                    <div className='col-6'>
                        <span className='fw-bold'>{LANG.TRAINER}</span>
                    </div>
                    <div className='col-6'>
                        <span className='px-2'>{props?.event?.createdBy?.firstName} {props?.event?.createdBy?.lastName}</span>
                    </div>
                </div>

                <div className='row mb-2'>
                    <div className='col-6'>
                        <span className='fw-bold'>{LANG.PARTICIPANTS}</span>
                    </div>
                    <div className='col-6'>
                        <span className='px-2'>{props?.event?.participantType}</span>
                    </div>
                </div>
                {
                    props?.event?.participantType === 'team' ?
                        <div className='row mb-2'>
                            <div className='col-6'>
                                <span className='fw-bold'>Teams</span>
                            </div>
                            <div className='col-12'>
                                {
                                    props?.event?.teams?.map((item: any, index: number) =>
                                        <div className='border rounded p-2 mb-2' key={index}>
                                            <span className='me-2 fw-bold'>{item?.team?.teamName}</span>
                                            {
                                                item?.users?.map((member: any, index: number) =>
                                                    <div key={index} className='me-2  d-flex justify-content-between p-1'>
                                                        <span className='text-capitalize text-gray-800'>{member?.user?.firstName} {member?.user?.lastName}</span>
                                                        <span className={member?.status=='accepted' ? "event_approved text-capitalize fs-6" : "event_under_review text-capitalize fs-6"} >{status_translation[member?.status]}
                                                        <RejectReasonTooltip show={member?.status == 'rejected'} reason={member?.reason} />
                                                  
                                                        </span>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    )
                                }
                            </div>
                        </div> :
                        <div className='row mb-2'>
                            <div className='col-6 mb-2'>
                                <span className='fw-bold'>{LANG.MEMBER}</span>
                            </div>
                            <div className='col-12'>
                                {
                                    props?.event?.participants?.map((member: any, index: number) =>
                                        <div key={index} className='me-2  d-flex justify-content-between border rounded p-2 mb-2'>
                                            <span className='text-capitalize text-gray-800'>{member?.user?.firstName} {member?.user?.lastName}</span>
                                            <span className={member?.status=='accepted' ? "event_approved text-capitalize fs-6" : "event_under_review text-capitalize fs-6"} >{member?.status=='pending'?'Ausstehend':status_translation[member?.status]}
                                              <RejectReasonTooltip show={member?.status == 'rejected'} reason={member?.reason} />
                                            </span>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                }

            </Modal.Body>
        </Modal>
    )
}

export default EventDetailModal