import React from 'react'

const SearchIcon = () => {
    return (
        <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.3333 15L9.08333 9.75C8.66667 10.0833 8.1875 10.3472 7.64583 10.5417C7.10417 10.7361 6.52778 10.8333 5.91667 10.8333C4.40278 10.8333 3.12153 10.309 2.07292 9.26042C1.02431 8.21181 0.5 6.93056 0.5 5.41667C0.5 3.90278 1.02431 2.62153 2.07292 1.57292C3.12153 0.524306 4.40278 0 5.91667 0C7.43056 0 8.71181 0.524306 9.76042 1.57292C10.809 2.62153 11.3333 3.90278 11.3333 5.41667C11.3333 6.02778 11.2361 6.60417 11.0417 7.14583C10.8472 7.6875 10.5833 8.16667 10.25 8.58333L15.5 13.8333L14.3333 15ZM5.91667 9.16667C6.95833 9.16667 7.84375 8.80208 8.57292 8.07292C9.30208 7.34375 9.66667 6.45833 9.66667 5.41667C9.66667 4.375 9.30208 3.48958 8.57292 2.76042C7.84375 2.03125 6.95833 1.66667 5.91667 1.66667C4.875 1.66667 3.98958 2.03125 3.26042 2.76042C2.53125 3.48958 2.16667 4.375 2.16667 5.41667C2.16667 6.45833 2.53125 7.34375 3.26042 8.07292C3.98958 8.80208 4.875 9.16667 5.91667 9.16667Z" fill="#5A5A5A" />
        </svg>
    )
}

export default SearchIcon