import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { LANG } from '../../../constants/language'
import moment from 'moment'
import { SponsorPlan } from '../../../types/sponsor-plan'
import clsx from 'clsx';
import "./style.scss";

const DealsModal = (props: any) => {
    const maxDate = new Date().toISOString().split("T")[0];
    const [startDate, setStartDate]= useState<any>(maxDate);
    const [endDate, setEndDate]= useState<any>("");
    const plan: SponsorPlan = props?.plan;
    useEffect(()=>{
       // set end date
       if (plan?.type==="monthly") {
        setEndDate(moment(startDate).add(1 ,'months').format("YYYY-MM-DD"));
      }else if (plan?.type==='quarterly') {
        setEndDate( moment(startDate).add(3 ,'months').format("YYYY-MM-DD"));
      }else if (plan?.type==="annually") {
        setEndDate( moment(startDate).add(12 ,'months').format("YYYY-MM-DD"));
      }
    },[props?.show]);

    const handleSubmit=async()=>{
        // submit form
        props.submit({startDate, endDate});
    }
    const startDateChange = (event:any) => {
        const date= event.target.value;
        setStartDate(date);
        if (plan?.type==="monthly") {
          setEndDate(moment(date).add(1 ,'months').format("YYYY-MM-DD"));
        }else if (plan?.type==='quarterly') {
          setEndDate( moment(date).add(3 ,'months').format("YYYY-MM-DD"));
        }else if (plan?.type==="annually") {
          setEndDate( moment(date).add(12 ,'months').format("YYYY-MM-DD"));
        }
      }

    return (
        <Modal
            {...props}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className='eventDetailModal'
        >
            <Modal.Header closeButton className=' border-0 pb-0'>
                <Modal.Title id="contained-modal-title-vcenter">
                    {LANG.NEW_SPONSORING}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className='p-3'>
                <div className='row mb-2'>
                    <div className='col-6'>
                        <span className='fw-bold'>{LANG.NAME}</span>
                    </div>
                    <div className='col-6'>
                        <span className='px-2 text-capitalize'>{plan?.title}</span>
                    </div>
                </div>
                <div className='row mb-2'>
                    <div className='col-6'>
                        <span className='fw-bold '>{LANG.PLAN}</span>
                    </div>
                    <div className='col-6'>
                        <span className='text-capitalize ms-2'>{plan?.type}</span>
                    </div>
                </div>
                <div className='row mb-2'>
                    <div className='col-6'>
                        <span className='fw-bold'>{LANG.PRICE}</span>
                    </div>
                    <div className='col-6'>
                        <span className='px-2'>€{plan?.price - plan?.discount} <span style={{ textDecoration: 'line-through' }} className={plan?.discount < 1 ? 'd-none' : ''}> €{plan?.price}</span> </span>
                    </div>
                </div>
                <div className='row mb-2'>
                   
                    <div className='col-6'>
                        <label className='mb-2'>
                        <span className='fw-bold'>{LANG.START_DATE}</span>

                        </label>
                        <div className="form-group" >
                            <div className="group-img">
                                <input
                                    type="date"
                                    min={maxDate}
                                    value={startDate}
                                    placeholder={LANG.START_DATE}
                                    onChange={startDateChange}
                                    className={clsx(
                                        "form-control commonInput",
                                        { "border border-danger": !startDate },
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='col-6'>
                        <label className='mb-2' >
                        <span className='fw-bold'>{LANG.END_DATE}</span>

                        </label>
                        <div className="form-group" >
                            <div className="group-img">
                                <input
                                    type="date"
                                    min={maxDate}
                                    readOnly={true}
                                    value={endDate}
                                    placeholder={LANG.END_DATE}
                                    className={clsx(
                                        "form-control commonInput read-only-input",
                                        { "border border-danger": !endDate },
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='mt-3 d-flex gap-2'>
                        <button className='blueButton' onClick={handleSubmit} disabled={!startDate|| !endDate} >
                            {LANG.BOOK_SLOT}
                        </button>
                        <button className='btn btn-danger' onClick={props.onHide}>
                            {LANG.CANCEL}
                        </button>
                    </div>
                </div>


            </Modal.Body>
        </Modal>
    )
}

export default DealsModal;