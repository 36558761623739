import React, { useEffect, useState } from 'react';
import { Form, Button, ListGroup, Modal } from 'react-bootstrap';
import PlusIcon from '../../../../icons/PlusIcon';
import { LANG } from '../../../../constants/language';
import VisibilityBox from '../../VisibilityBox';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import { EVENTS_DURATION, EVENTS_TYPE, PARTICIPANTS_TYPES, TIMES_DURATION } from '../../../../constants';
import * as Yup from "yup";
import { useFormik } from "formik";
import clsx from 'clsx';
import { getAllRooms } from '../../../../services/room.service';
import {  updateEvent } from '../../../../services/event.service';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { setEditEventModalStatus, setFormDetail } from '../../../data/redux/shared/sharedSlice';

const eventSchema: any = Yup.object().shape({
    eventType: Yup.string().required(LANG.FIELD_IS_REQUIRED),
    date: Yup.date().required(LANG.FIELD_IS_REQUIRED),
    time: Yup.string().required(LANG.FIELD_IS_REQUIRED),
    room: Yup.string().required(LANG.FIELD_IS_REQUIRED),
    eventDuration: Yup.string().required(LANG.FIELD_IS_REQUIRED),
    timeDuration: Yup.string().required(LANG.FIELD_IS_REQUIRED),
    participantType: Yup.string().required(LANG.FIELD_IS_REQUIRED),
    maxParticipant: Yup.string().required(LANG.FIELD_IS_REQUIRED),
    location: Yup.string().required(LANG.FIELD_IS_REQUIRED),
});

const EditEventModalDetails = (props: any) => {
    const fileUrl = process.env.REACT_APP_FILE_URL;
    const [rooms, setRooms] = useState<any[]>([]);
    const dispatch:any= useDispatch();
    const {formDetails:eventDetail,editEventModal}= useSelector((state:any)=>state.shared);

    useEffect(() => {
        getData();
    }, [])

    const formIntialValues: any = {
        ...eventDetail,
        room: eventDetail?.room?._id,
        date: moment(eventDetail.date).format('YYYY-MM-DD')||"",
    };

    const eventFormik = useFormik({
        initialValues: formIntialValues,
        validationSchema: eventSchema,
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            try {
                const result = await updateEvent(eventDetail?._id, { ...values });
                setSubmitting(true);
                resetForm();
                dispatch(setEditEventModalStatus(false));
                props?.update();
            } catch (error) {
                if (error instanceof AxiosError) {
                    toast.error(error.response?.data?.responseMessage);
                }
                setSubmitting(false);
            }
        },
    });

    const getData = async () => {
        const [roomData] = await Promise.all([getAllRooms({ page: 1, limit: 100 })]);
        if (roomData.status == 200) {
            setRooms(roomData.data?.data);
        }
    }
    const closeModal=()=>{
        dispatch(setEditEventModalStatus(false));
        dispatch(setFormDetail({}));
    }
    return (
        <Modal
            {...props}
            onHide={closeModal}
            show={editEventModal}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <span className='fs-6'>{LANG.EDIT_EVENT_DETAILS}</span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={eventFormik.handleSubmit}>
                    <div className="row">
                        <div className="col-sm-12 col-md-4 mb-5">
                            <Form.Group>
                                <select
                                    className={clsx(
                                        "form-control commonInput",
                                        { "border border-danger": eventFormik.touched.eventType && eventFormik.errors.eventType },
                                    )}
                                    disabled={true}
                                    value={eventFormik.values?.eventType}
                                    name="eventType"
                                    onChange={(v) => {
                                        eventFormik.setFieldValue("eventType", v.target.value);
                                    }}
                                >
                                    <option value="">{LANG.EVENT_TYPE}</option>
                                    {EVENTS_TYPE.map((ev, index: number) =>
                                        <option value={ev.value} key={index}>{ev.label}</option>
                                    )}
                                </select>
                                {eventFormik.errors.eventType && eventFormik.touched.eventType && (
                                    <div className="formik-errors text-danger">{`${eventFormik.errors.eventType}`}</div>
                                )}
                            </Form.Group>
                        </div>
                        <div className="col-sm-12 col-md-4 mb-5">
                            <Form.Group>
                                <select
                                    className={clsx(
                                        "form-control commonInput",
                                        { "border border-danger": eventFormik.touched.eventDuration && eventFormik.errors.eventDuration },
                                    )}
                                    value={eventFormik.values?.eventDuration}
                                    name="eventDuration"
                                    onChange={(v) => {
                                        eventFormik.setFieldValue("eventDuration", v?.target.value);
                                    }}
                                >
                                    <option value="">{LANG.EVENT_DURATION}</option>
                                    {EVENTS_DURATION.map((ev, index: number) =>
                                        <option value={ev.value} key={index}>{ev.label}</option>
                                    )}
                                </select>
                                {eventFormik.errors.eventDuration && eventFormik.touched.eventDuration && (
                                    <div className="formik-errors text-danger">{`${eventFormik.errors.eventDuration}`}</div>
                                )}
                            </Form.Group>
                        </div>
                        <div className="col-sm-12  col-md-4 mb-5">
                            <Form.Group>
                                <select
                                    className={clsx(
                                        "form-control commonInput text-capitalize",
                                        { "border border-danger": eventFormik.touched.room && eventFormik.errors.room },
                                    )}
                                    disabled={true}
                                    value={eventFormik.values?.room}
                                    name="room"
                                    onChange={(v) => {
                                        eventFormik.setFieldValue("room", v?.target.value);
                                    }}
                                >
                                    <option value="">{LANG.ROOM_NAME}</option>
                                    {rooms.map((ev, index: number) =>
                                        <option value={ev?._id} key={index} className='text-capitalize'>{ev.roomName}</option>
                                    )}
                                </select>
                            </Form.Group>
                            {eventFormik.errors.room && eventFormik.touched.room && (
                                <div className="formik-errors text-danger">{`${eventFormik.errors.room}`}</div>
                            )}
                        </div>
                        <div className="col-sm-12  col-md-4 mb-5">
                            <Form.Group>
                                <input
                                    className={clsx(
                                        "form-control commonInput",
                                        { "border border-danger": eventFormik.touched.date && eventFormik.errors.date },
                                    )}
                                    type="date"
                                    {...eventFormik.getFieldProps("date")}
                                    min={new Date().toISOString().split("T")[0]}
                                />
                            </Form.Group>
                            {eventFormik.errors.date && eventFormik.touched.date && (
                                <div className="formik-errors text-danger">{`${eventFormik.errors.date}`}</div>
                            )}
                        </div>
                        <div className="col-sm-12  col-md-4 mb-5">
                            <Form.Group>
                                <input
                                    type="time"
                                    className={clsx(
                                        "form-control commonInput",
                                        { "border border-danger": eventFormik.touched.time && eventFormik.errors.time },
                                    )}
                                    {...eventFormik.getFieldProps("time")}
                                />
                            </Form.Group>
                            {eventFormik.errors.time && eventFormik.touched.time && (
                                <div className="formik-errors text-danger">{`${eventFormik.errors.time}`}</div>
                            )}
                        </div>
                        <div className="col-sm-12 col-md-4 mb-5">
                            <Form.Group>
                                <select
                                    value={eventFormik.values?.timeDuration}
                                    className={clsx(
                                        "form-control commonInput",
                                        { "border border-danger": eventFormik.touched.timeDuration && eventFormik.errors.timeDuration },
                                    )}
                                    name="timeDuration"
                                    onChange={(v) => {
                                        eventFormik.setFieldValue("timeDuration", v?.target.value);
                                    }}
                                >
                                    <option value="">{LANG.TIME_DURATION}</option>
                                    {TIMES_DURATION.map((ev, index: number) =>
                                        <option value={ev?.value} key={index}>{ev.label}</option>
                                    )}
                                </select>
                                {eventFormik.errors.timeDuration && eventFormik.touched.timeDuration && (
                                    <div className="formik-errors text-danger">{`${eventFormik.errors.timeDuration}`}</div>
                                )}
                            </Form.Group>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12  col-md-12 mb-5">
                            <Form.Group>
                                <textarea
                                    placeholder={LANG.DESCRIPTION}
                                    className={clsx(
                                        "form-control commonInput",
                                        { "border border-danger": eventFormik.touched.description && eventFormik.errors.description },
                                    )}
                                    {...eventFormik.getFieldProps("description")}
                                />
                            </Form.Group>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 col-md-4 mb-5">
                            <Form.Group>
                                <select
                                    className={clsx(
                                        "form-control commonInput",
                                        { "border border-danger": eventFormik.touched.participantType && eventFormik.errors.participantType },
                                    )}
                                    disabled={true}
                                    value={eventFormik.values?.participantType}
                                    name="participantType"
                                    onChange={(v) => {
                                        eventFormik.setFieldValue("participantType", v?.target.value);
                                    }}
                                >
                                    <option value="">{LANG.TEAM_AND_PARTICIPANTS_TYPE}</option>
                                    {PARTICIPANTS_TYPES.map((ev, index: number) =>
                                        <option value={ev?.value} key={index}>{ev.label}</option>
                                    )}
                                </select>
                            </Form.Group>
                        </div>
                        <div className="col-sm-12  col-md-4 mb-5">
                            <Form.Group>
                                <input
                                    type="number"
                                    placeholder={LANG.MAXIMUM_PARTICIPANTS}
                                    min={0}
                                    readOnly={true}
                                    className={clsx(
                                        "form-control commonInput",
                                        { "border border-danger": eventFormik.touched.maxParticipant && eventFormik.errors.maxParticipant },
                                    )}
                                    {...eventFormik.getFieldProps("maxParticipant")}
                                />
                            </Form.Group>
                        </div>

                        <div className="col-sm-12  col-md-4 mb-5">
                            <Form.Group>
                                <input
                                    type="text"
                                    placeholder={LANG.LOCATION}
                                    maxLength={128}
                                    readOnly={true}
                                    className={clsx(
                                        "form-control commonInput",
                                        { "border border-danger": eventFormik.touched.location && eventFormik.errors.location },
                                    )}
                                    {...eventFormik.getFieldProps("location")}
                                />
                            </Form.Group>
                        </div>
                    </div>
                    <div className='d-flex justify-content-end'>
                         <Button type='button' onClick={closeModal} variant='outline-secondary' className='me-2' >
                            {LANG.CANCEL}
                        </Button>
                        <Button type='submit' variant="primary" className='' >
                            {LANG.UPDATE}
                        </Button>
                    </div>
                </form>
            </Modal.Body >
        </Modal >

    )
}

export default EditEventModalDetails;