/* eslint-disable */
import React, { useEffect, useState } from "react";
import AllRoutes from "./router/router";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from "react-redux";
import { getAllWebpages } from "../services/webpage.service";
import { setWebpageState } from "../core/data/redux/webpage/webpageSlice";
import { addLocale } from "primereact/api";
import moment from 'moment';
import 'moment/locale/de';
moment().locale('de');

const Feature = () => {
  const [base, setBase] = useState("");
  const [page, setPage] = useState("");
  const [last, setLast] = useState("");
  const currentPath = window.location.pathname;
  const dispatch = useDispatch();
  useEffect(() => {
    setBase(currentPath.split("/")[1]);
    setPage(currentPath.split("/")[2]);
    setLast(currentPath.split("/")[3]);
    getPages();

    addLocale('de', {
      firstDayOfWeek: 1,
      dayNames: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
      dayNamesShort: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
      dayNamesMin: ['S', 'M', 'D', 'M', 'D', 'F', 'S'],
      monthNames: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
      monthNamesShort: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
      today: 'Heute',
      clear: 'Löschen',
    });
  }, []);

  const getPages = async () => {
    try {
      const result = await getAllWebpages();
      dispatch(setWebpageState(result.data?.data));
    } catch (error) {
      console.log(`Error: {error}`)
    }
  }

  return (
    <>
      <div
        className={`main-wrapper
        ${page === "add-court" ? "add-court venue-coach-details" :
            page === "coach-detail" ? "venue-coach-details coach-detail" :
              page === "lesson-timedate" ? "coach lessons" :
                page === "lesson-type" ? "coach lessons" :
                  // page === "forgot-password" ? "authendication-pages" :
                  page === "gallery" ? "gallery-page innerpagebg" :
                    page === "invoice" ? "invoice-page innerpagebg" :
                      page === "venue-details" ? "venue-coach-details" : ""
          }`
        }
      >
        <AllRoutes />
      </div>
      <ToastContainer />
    </>
  );
};

export default Feature;
