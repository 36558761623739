import React from "react";

export default function FriendsBlackIcon() {
  return (
<svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
<mask id="mask0_338_829" style={{maskType:"alpha"}} maskUnits="userSpaceOnUse" x="0" y="0" width="33" height="33">
<rect x="0.199219" y="0.5" width="32" height="32" fill="#D9D9D9"/>
</mask>
<g mask="url(#mask0_338_829)">
<path d="M1.5332 27.1666V23.4333C1.5332 22.6777 1.72765 21.9833 2.11654 21.3499C2.50543 20.7166 3.02209 20.2333 3.66654 19.8999C5.04431 19.211 6.44431 18.6944 7.86654 18.3499C9.28876 18.0055 10.7332 17.8333 12.1999 17.8333C13.6665 17.8333 15.111 18.0055 16.5332 18.3499C17.9554 18.6944 19.3554 19.211 20.7332 19.8999C21.3776 20.2333 21.8943 20.7166 22.2832 21.3499C22.6721 21.9833 22.8665 22.6777 22.8665 23.4333V27.1666H1.5332ZM25.5332 27.1666V23.1666C25.5332 22.1888 25.261 21.2499 24.7165 20.3499C24.1721 19.4499 23.3999 18.6777 22.3999 18.0333C23.5332 18.1666 24.5999 18.3944 25.5999 18.7166C26.5999 19.0388 27.5332 19.4333 28.3999 19.8999C29.1999 20.3444 29.811 20.8388 30.2332 21.3833C30.6554 21.9277 30.8665 22.5221 30.8665 23.1666V27.1666H25.5332ZM12.1999 16.4999C10.7332 16.4999 9.47765 15.9777 8.4332 14.9333C7.38876 13.8888 6.86654 12.6333 6.86654 11.1666C6.86654 9.69992 7.38876 8.44436 8.4332 7.39992C9.47765 6.35547 10.7332 5.83325 12.1999 5.83325C13.6665 5.83325 14.9221 6.35547 15.9665 7.39992C17.011 8.44436 17.5332 9.69992 17.5332 11.1666C17.5332 12.6333 17.011 13.8888 15.9665 14.9333C14.9221 15.9777 13.6665 16.4999 12.1999 16.4999ZM25.5332 11.1666C25.5332 12.6333 25.011 13.8888 23.9665 14.9333C22.9221 15.9777 21.6665 16.4999 20.1999 16.4999C19.9554 16.4999 19.6443 16.4721 19.2665 16.4166C18.8888 16.361 18.5776 16.2999 18.3332 16.2333C18.9332 15.5221 19.3943 14.7333 19.7165 13.8666C20.0388 12.9999 20.1999 12.0999 20.1999 11.1666C20.1999 10.2333 20.0388 9.33325 19.7165 8.46659C19.3943 7.59992 18.9332 6.81103 18.3332 6.09992C18.6443 5.98881 18.9554 5.91659 19.2665 5.88325C19.5776 5.84992 19.8888 5.83325 20.1999 5.83325C21.6665 5.83325 22.9221 6.35547 23.9665 7.39992C25.011 8.44436 25.5332 9.69992 25.5332 11.1666Z" fill="#1A1A1A"/>
</g>
</svg>

  );
}
