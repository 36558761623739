import React, { useEffect, useState } from "react";
import ImageWithBasePath from "../../core/data/img/ImageWithBasePath";
import Slider from "react-slick";
import { Link, useParams } from "react-router-dom";
import { all_routes } from "../router/all_routes";
import { ConfirmDialog } from "primereact/confirmdialog";
import { getForumById } from "../../services/forum.service";
import { IForum } from "../../core/data/interface/forum";
import { AxiosError } from "axios";
import { toast } from "react-toastify";
import VisibilityBox from "../../core/components/VisibilityBox";
import moment from "moment";
import { LANG } from "../../constants/language";
import ForumComment from "../../core/components/forum/forum-comment/ForumComment";
import { useDispatch, useSelector } from "react-redux";
import { setCommentModalStatus, setFormDetail } from "../../core/data/redux/shared/sharedSlice";
import ForumCommentModal from "../../core/components/forum/comment-modal/page";

const ForumDetails = () => {
  const routes = all_routes;
  const fileUrl = process.env.REACT_APP_FILE_URL;
  const { commentModal } = useSelector((state: any) => state.shared);
  const params = useParams();
  const [selectedItems, setSelectedItems] = useState(Array(4).fill(false));
  const [forumDetail, setForumDetail] = useState<any>();
  const dispatch = useDispatch();
  const handleItemClick = (index: number) => {
    setSelectedItems((prevSelectedItems) => {
      const updatedSelectedItems = [...prevSelectedItems];
      updatedSelectedItems[index] = !updatedSelectedItems[index];
      return updatedSelectedItems;
    });
  };

  useEffect(() => {
    console.log(params)
    if (params?.id) {
      getForum(params?.id);
    }
  }, [params?.id])

  const getForum = async (id: any) => {
    try {
      const result = await getForumById(id);
      if (result.status == 200) {
        setForumDetail(result?.data?.data);
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        toast.error(error.response?.data?.responseMessage);
      }
    }
  }

  const handleToggleComment = async (forum: IForum) => {
    dispatch(setCommentModalStatus(true));
    dispatch(setFormDetail({forumId: forum._id}));
  }

  return (
    <div>
      {/* Page Content */}
      <div className="content blog-details">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-10 col-lg-8 mx-auto">
              {/* Blog */}
              <div className="featured-venues-item">
                <div className="listing-item blog-info">
                  <div className="listing-img">
                    <VisibilityBox show={forumDetail?.image !== undefined}>
                      <div className="listing-img">
                        <img
                          src={fileUrl + String(forumDetail?.image)}
                          className="img-fluid"
                          alt="Venue"
                        />
                      </div>
                    </VisibilityBox>
                  </div>
                  <div className="listing-content news-content">
                    <div className="listing-venue-owner blog-detail-owner d-lg-flex justify-content-between align-items-center">
                      <div className="navigation">
                        <span>
                        <img height={100} width={100} src={forumDetail?.createdBy?.avatar ? fileUrl + forumDetail?.createdBy?.avatar : "/assets/img/default-avatar.png"} alt="User" />
                        {forumDetail?.createdBy?.firstName} {forumDetail?.createdBy?.lastName}
                        </span>
                        <span><i className="feather-calendar" />{moment(forumDetail?.createdAt).format("DD MMM YYYY")}</span>
                        <span><i className="far fa-comment-alt" />{forumDetail?.comments?.length} {LANG.COMMENTS}</span>
                      </div>
                    </div>
                    {/* <hr> */}
                    <h2 className="listing-title">
                      <span className="">{forumDetail?.title}</span>
                    </h2>
                    <p>{forumDetail?.description}</p>
                    
                  {/* <hr> */}
                </div>
              </div>
              {/* /Blog */}
              {/* Author Comments */}
              <div className="blog-comments">
                <div className="dull-bg author-widget">
                  <div className=" author-group d-md-flex align-items-center justify-content-start">
                    <div className="profile-pic">
                      <img height={60} width={60} className="rounded-circle" src={forumDetail?.createdBy?.avatar ? fileUrl + forumDetail?.createdBy?.avatar : "/assets/img/default-avatar.png"} alt="User" />
                    </div>
                    <div className="info">
                      <span>{LANG.AUTHOR}</span>
                      <h5>{forumDetail?.createdBy?.firstName} {forumDetail?.createdBy?.lastName}</h5>
                      <p>{forumDetail?.createdBy?.description||'-'}</p>
                    </div>
                  </div>
                </div>
              </div>
              {/* /Author Comments */}
              {/* Comments */}
              <ForumComment forum={forumDetail} commentForum={handleToggleComment} />
            </div>
          </div>
          <div className="text-center my-3">
            <Link to={'/user/forum-list'}>
               <span className="text-hover-underline">{LANG.VIEW_ALL}</span>
            </Link>
          </div>
        </div>
        </div>
      </div>
      {/* /Page Content */}

      {commentModal && <ForumCommentModal update={()=>getForum(params?.id)} />}
      <ConfirmDialog />
    </div>
  );
}

export default ForumDetails;