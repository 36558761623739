import React from 'react'
import VisibilityBox from '../../VisibilityBox'
import { Tooltip } from 'primereact/tooltip'


interface Props {
  show: boolean,
  reason: string
}
const RejectReasonTooltip = ({show, reason}:Props) => {
  return (
    <VisibilityBox show={show}>
        <Tooltip target=".custom-target-icon" /> 
         <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="mx-1 custom-target-icon bi bi-info-circle"
         data-pr-tooltip={reason}
         data-pr-position="right"
         data-pr-at="right+5 top"
         data-pr-my="left center-2" viewBox="0 0 16 16">
  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
  <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0"/>
</svg>
    </VisibilityBox>
  )
}

export default RejectReasonTooltip