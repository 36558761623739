import React, { useEffect, useState } from 'react';
import { Card, Form, Button, Container, ListGroup } from 'react-bootstrap';
import PlusIcon from '../../../../icons/PlusIcon';
import { LANG } from '../../../../constants/language';
import { SelectMembersModal } from '../members-modal/page';
import VisibilityBox from '../../VisibilityBox';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import { EVENTS_DURATION, EVENTS_TYPE, PARTICIPANTS_TYPES, TIMES_DURATION } from '../../../../constants';
import * as Yup from "yup";
import { useFormik } from "formik";
import clsx from 'clsx';
import { getAllRooms } from '../../../../services/room.service';
import { createEvent, createSLots, getSlots } from '../../../../services/event.service';
import Select from "react-select";
import { SelectTeamsModal } from '../teams-modal/page';

interface Props {
    back(): void,
    create(): void,
}

const eventSchema: any = Yup.object().shape({
    eventType: Yup.string().required(LANG.FIELD_IS_REQUIRED),
    date: Yup.date().required(LANG.FIELD_IS_REQUIRED),
    time: Yup.string().required(LANG.FIELD_IS_REQUIRED),
    room: Yup.string().required(LANG.FIELD_IS_REQUIRED),
    // eventDuration: Yup.string().required(LANG.FIELD_IS_REQUIRED),
    timeDuration: Yup.string().required(LANG.FIELD_IS_REQUIRED),
    participantType: Yup.string().required(LANG.FIELD_IS_REQUIRED),
    maxParticipant: Yup.string().required(LANG.FIELD_IS_REQUIRED),
    // location: Yup.string().required(LANG.FIELD_IS_REQUIRED),
    participants: Yup.array(),
    teams: Yup.array(),
});

const CreateNewEvent = ({ back, create }: Props) => {
    const fileUrl = process.env.REACT_APP_FILE_URL;
    const [openMember, setOpenMember] = useState<boolean>(false);
    const [openTeam, setOpenTeam] = useState<boolean>(false);
    const [rooms, setRooms] = useState<any[]>([]);
    const [addMemberType, setAddMemberType] = useState<string>();
    const [allowedTimes, setAllowedTimes] = useState<any>([]);

    useEffect(() => {
        getData();
    }, [])

    const formIntialValues: any = {
        eventType: '',
        date: '',
        time: '',
        room: '',
        description: '',
        // eventDuration: '',
        timeDuration: '',
        participantType: '',
        maxParticipant: '',
        // location:"",
        teams: [],
        participants: []
    };

    const eventFormik = useFormik({
        initialValues: formIntialValues,
        validationSchema: eventSchema,
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            try {
                if (isInvalidForm()) {
                    toast.error(LANG.MEMBERS_IS_REQUIRED);
                    return;
                }
                let participants = [], teams = [];
                if (values.participantType == 'individual') {
                    participants = values.participants.map((v: any) => {
                        return { user: v?._id }
                    });
                } else {
                    teams = values?.teams?.map((p: any) => {
                        return { team: p?._id, users: p?.members?.map((m: any) => {
                          return { user: m };
                        }) };
                      });
                }
                const result: any = await createEvent({ ...values, participants, teams });

                const users: any = []

                if (participants && participants.length > 0) {
                    participants.forEach((item: any) => {
                        users.push(item.user);
                    })
                }
                if (teams && teams.length > 0) {
                    teams.forEach((item: any) => {
                        users.concat(item.members);
                    });
                }
                if (result && values && values.timeDuration && values.time) {
                    const data = TIMES_DURATION.find(item => item.value === values.timeDuration);
                    const min = data ? data.min : null;
                    const slotsResponse = await createSLots({
                        startSlot: values.time,
                        date: values.date,
                        duration: min,
                        eventId: result?.data?.data?._id,
                        users,
                        roomId: values.room,
                    })
                }

                setSubmitting(true);
                create();
                back();
                resetForm();
            } catch (error) {
                if (error instanceof AxiosError) {
                    toast.error(error.response?.data?.responseMessage);
                }
                setSubmitting(false);
            }
        },
    });

    const getData = async () => {
        try {
            
            const [roomData] = await Promise.all([getAllRooms({ page: 1, limit: 100 })]);
            if (roomData.status == 200) {
                setRooms(roomData.data?.data);
            }
        } catch (error) {
            console.log(`Error: ${error}`)
        }
    }

    const getSlotsApi = async (duration = "") => {
        if (eventFormik.values.date != '' && (duration != '' || eventFormik.values.timeDuration != '' || eventFormik.values.room != '')) {
            let value = eventFormik.values.timeDuration
            if (duration != '') value = duration;
            const result = TIMES_DURATION.find(item => item.value === value);
            const min = result ? result.min : null;
            const payload: any = { date: eventFormik.values.date, duration: min, roomId: eventFormik.values.room };
            const [eventDetail] = await Promise.all([getSlots(payload)])
            const array: any = []
            if (eventDetail?.data?.data && eventDetail?.data?.data.length > 0) {
                eventDetail?.data?.data.forEach((item: any) => {
                    array.push({ value: item.start, label: item.start })
                })
                setAllowedTimes(array);
            }
        }
    }

    const selectMember = (selectedMembers: any[]) => {
        if (addMemberType == 'participant') {
            eventFormik.setFieldValue('participants', selectedMembers);
        } else if (addMemberType == 'team') {
            eventFormik.setFieldValue('teams', selectedMembers);
        }
    }

    const isInvalidForm = () => {
        if (eventFormik.values.participantType === 'individual') {
            return eventFormik.values.participants?.length < 1;
        } else if (eventFormik.values.participantType === 'team') {
            return (eventFormik.values.teams?.length < 1);
        } else {
            return true;
        }
    }

    return (
        <>
            <Container className="mt-5 createEventWrapper">
                {/* Breadcrumb */}
                <div className='my-2 breadcrumbWrap'>
                    <span className='text-link cursor-pointer' onClick={back} >{LANG.EVENTS}</span> /
                    <span className='text-primary'> {LANG.CREATE_NEW_EVENT}</span>
                </div>
                {/* Create New Team Card */}
                <div className='border rounded p-3 createEventWrapper'>
                    <h3 className='mb-2 text-capitalize'>{LANG.NEW_EVENT}</h3>
                    <form onSubmit={eventFormik.handleSubmit}>
                        <Card className="shadow-sm col-md-12 col-sm-12 p-0">
                            <Card.Body className=''>
                                <Card.Title>
                                    <h4 className='text-capitalize'>{LANG.EVENT_DETAILS}</h4>
                                </Card.Title>

                                <div className="row">
                                    <div className="col-sm-12 col-md-4 mb-5">
                                        <Form.Group>
                                            <select
                                                className={clsx(
                                                    "form-control commonInput",
                                                    { "border border-danger": eventFormik.touched.eventType && eventFormik.errors.eventType },
                                                )}
                                                value={eventFormik.values?.eventType}
                                                name="eventType"
                                                onChange={(v) => {
                                                    eventFormik.setFieldValue("eventType", v.target.value);
                                                }}
                                            >
                                                <option value="">{LANG.EVENT_TYPE}</option>
                                                {EVENTS_TYPE.map((ev, index: number) =>
                                                    <option value={ev.value} key={index}>{ev.label}</option>
                                                )}
                                            </select>
                                            {eventFormik.errors.eventType && eventFormik.touched.eventType && (
                                                <div className="formik-errors text-danger">{`${eventFormik.errors.eventType}`}</div>
                                            )}
                                        </Form.Group>
                                    </div>
                                    <div className="col-sm-12  col-md-4 mb-5">
                                        <Form.Group>
                                            <select
                                                className={clsx(
                                                    "form-control commonInput text-capitalize",
                                                    { "border border-danger": eventFormik.touched.room && eventFormik.errors.room },
                                                )}
                                                value={eventFormik.values?.room}
                                                name="room"
                                                onChange={(v) => {
                                                    eventFormik.setFieldValue("room", v?.target.value);
                                                    getSlotsApi()
                                                }}
                                            >
                                                <option value="">{LANG.ROOM_NAME}</option>
                                                {rooms.map((ev, index: number) =>
                                                    <option value={ev?._id} key={index} className='text-capitalize'>{ev.roomName}</option>
                                                )}
                                            </select>
                                        </Form.Group>
                                        {eventFormik.errors.room && eventFormik.touched.room && (
                                            <div className="formik-errors text-danger">{`${eventFormik.errors.room}`}</div>
                                        )}
                                    </div>
                                    <div className="col-sm-12  col-md-4 mb-5">
                                        <Form.Group>
                                            <input
                                                className={clsx(
                                                    "form-control commonInput",
                                                    { "border border-danger": eventFormik.touched.date && eventFormik.errors.date },
                                                )}
                                                type="date"
                                                {...eventFormik.getFieldProps("date")}
                                                min={new Date().toISOString().split("T")[0]}
                                                onChange={(v) => {
                                                    eventFormik.setFieldValue("date", v?.target.value);
                                                    getSlotsApi()
                                                }}
                                            />
                                        </Form.Group>
                                        {eventFormik.errors.date && eventFormik.touched.date && (
                                            <div className="formik-errors text-danger">{`${eventFormik.errors.date}`}</div>
                                        )}
                                    </div>

                                    <div className="col-sm-12 col-md-4 mb-5">
                                        <Form.Group>
                                            <select
                                                value={eventFormik.values?.timeDuration}
                                                className={clsx(
                                                    "form-control commonInput",
                                                    { "border border-danger": eventFormik.touched.timeDuration && eventFormik.errors.timeDuration },
                                                )}
                                                name="timeDuration"
                                                onChange={(v) => {
                                                    eventFormik.setFieldValue("timeDuration", v?.target.value);
                                                    getSlotsApi(v?.target.value)
                                                }}
                                            >
                                                <option value="">{LANG.TIME_DURATION}</option>
                                                {TIMES_DURATION.map((ev, index: number) =>
                                                    <option value={ev?.value} key={index}>{ev.label}</option>
                                                )}
                                            </select>
                                            {eventFormik.errors.timeDuration && eventFormik.touched.timeDuration && (
                                                <div className="formik-errors text-danger">{`${eventFormik.errors.timeDuration}`}</div>
                                            )}
                                        </Form.Group>
                                    </div>
                                    <div className="col-sm-12  col-md-4 mb-5">
                                        <Select
                                            options={allowedTimes}
                                            value={allowedTimes.find((option: any) => option?.value === eventFormik?.values?.time)}
                                            onChange={(v: any) => {
                                                eventFormik.setFieldValue("time", v?.value || "");
                                            }}
                                            placeholder="Select Time"
                                            classNamePrefix="custom-select"
                                            className={`${eventFormik?.touched?.time && eventFormik?.errors?.time
                                                    ? "border border-danger"
                                                    : ""
                                                }`}
                                        />
                                        {eventFormik.errors.time && eventFormik.touched.time && (
                                            <div className="formik-errors text-danger">{`${eventFormik.errors.time}`}</div>
                                        )}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12  col-md-12 mb-5">
                                        <Form.Group>
                                            <textarea
                                                placeholder={LANG.DESCRIPTION}
                                                className={clsx(
                                                    "form-control commonInput",
                                                    { "border border-danger": eventFormik.touched.description && eventFormik.errors.description },
                                                )}
                                                {...eventFormik.getFieldProps("description")}
                                            />
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12 col-md-4 mb-5">
                                        <Form.Group>
                                            <select
                                                className={clsx(
                                                    "form-control commonInput",
                                                    { "border border-danger": eventFormik.touched.participantType && eventFormik.errors.participantType },
                                                )}
                                                value={eventFormik.values?.participantType}
                                                name="participantType"
                                                onChange={(v) => {
                                                    eventFormik.setFieldValue("participantType", v?.target.value);
                                                }}
                                            >
                                                <option value="">{LANG.TEAM_AND_PARTICIPANTS_TYPE}</option>
                                                {PARTICIPANTS_TYPES.map((ev, index: number) =>
                                                    <option value={ev?.value} key={index}>{ev.label}</option>
                                                )}
                                            </select>
                                            {eventFormik.errors.participantType && eventFormik.touched.participantType && (
                                                <div className="formik-errors text-danger">{`${eventFormik.errors.participantType}`}</div>
                                            )}
                                        </Form.Group>
                                    </div>
                                    <div className="col-sm-12  col-md-4 mb-5">
                                        <Form.Group>
                                            <input
                                                type="number"
                                                placeholder={LANG.MAXIMUM_PARTICIPANTS}
                                                min={0}
                                                className={clsx(
                                                    "form-control commonInput",
                                                    { "border border-danger": eventFormik.touched.maxParticipant && eventFormik.errors.maxParticipant },
                                                )}
                                                {...eventFormik.getFieldProps("maxParticipant")}
                                            />
                                        </Form.Group>
                                        {eventFormik.errors.maxParticipant && eventFormik.touched.maxParticipant && (
                                            <div className="formik-errors text-danger">{`${eventFormik.errors.maxParticipant}`}</div>
                                        )}
                                    </div>
                                </div>
                                <VisibilityBox show={eventFormik.values?.participantType !== ""}>
                                    <div>
                                        <div className='my-2'>
                                            <h4>{LANG.MEMBERS_DETAIL}</h4>
                                        </div>
                                        {
                                            eventFormik.values?.participantType === 'team' ? <>
                                                <div className="row">
                                                    <div className="col-sm-12 col-md-4 mb-5">
                                                        <Form.Group>
                                                            <Form.Label>
                                                                Teams
                                                            </Form.Label>
                                                            <Button variant="outline-primary" className='w-100' onClick={() => {
                                                                setOpenTeam(true);
                                                                setOpenMember(false);
                                                                setAddMemberType('team');
                                                            }}>
                                                                <PlusIcon /> {LANG.SELECT_TEAM}
                                                            </Button>

                                                        </Form.Group>
                                                        <VisibilityBox show={eventFormik.values?.teams?.length > 0}>
                                                            <h6 className='my-2'>{LANG.SELECTED_TEAMS}</h6>
                                                            <ListGroup variant="flush" className="mb-3" style={{ maxHeight: '400px', overflowY: 'auto' }}>
                                                                {eventFormik.values?.teams.map((team: any) => (
                                                                    <ListGroup.Item
                                                                        key={team._id}
                                                                        className={`d-flex align-items-center ${eventFormik.values?.teams.includes(team) ? 'bg-light' : ''
                                                                            }`}
                                                                        style={{ cursor: 'pointer' }}
                                                                    >
                                                                        <span className="flex-grow-1 ms-2">{team?.teamName || 'NA'}</span>
                                                                    </ListGroup.Item>
                                                                ))}
                                                            </ListGroup>


                                                        </VisibilityBox>
                                                    </div>
                                                    <div className="col-sm-12 col-md-4 mb-5">

                                                    </div>
                                                </div>
                                            </> : <>
                                                <div className="row">
                                                    <div className="col-sm-12 col-md-4 mb-5">
                                                        <Form.Group>
                                                            <Button variant="outline-primary" className='w-100' onClick={() => {
                                                                setOpenMember(true);
                                                                setOpenTeam(false);
                                                                setAddMemberType('participant');
                                                            }}>
                                                                <PlusIcon /> {LANG.SELECT_MEMBER}
                                                            </Button>
                                                        </Form.Group>
                                                        <VisibilityBox show={eventFormik.values?.participants?.length > 0}>
                                                            <h6 className='my-2'>{LANG.SELECTED_MEMBER}</h6>
                                                            <ListGroup variant="flush" className="mb-3" style={{ maxHeight: '400px', overflowY: 'auto' }}>
                                                                {eventFormik.values?.participants.map((member: any) => (
                                                                    <ListGroup.Item
                                                                        key={member._id}
                                                                        className={`d-flex align-items-center ${eventFormik.values?.participants.includes(member) ? 'bg-light' : ''
                                                                            }`}
                                                                        style={{ cursor: 'pointer' }}
                                                                    >
                                                                        <img
                                                                            src={member?.avatar ? fileUrl + member?.avatar : "/assets/img/default-avatar.png"}
                                                                            alt={member.name}
                                                                            className="rounded-circle mr-3"
                                                                            style={{ width: '40px', height: '40px' }}
                                                                        />
                                                                        <span className="flex-grow-1 ms-2">{member.firstName || 'NA'} {member?.lastName}</span>

                                                                    </ListGroup.Item>
                                                                ))}
                                                            </ListGroup>
                                                        </VisibilityBox>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                    </div>
                                </VisibilityBox>
                                <div className='w-100'>
                                    <Button type='submit' variant="primary" className='blueButton'>
                                        {LANG.CREATE_NEW_EVENT}
                                    </Button>
                                </div>
                            </Card.Body>
                        </Card>
                    </form>
                </div>
            </Container>
            <SelectMembersModal show={openMember} members={[]} handleClose={() => setOpenMember(false)} select={selectMember} />
            <SelectTeamsModal show={openTeam} teams={eventFormik.values?.teams} handleClose={() => setOpenTeam(false)} select={selectMember} />
        </>
    )
}

export default CreateNewEvent;