import React, { useState } from "react";
import * as Yup from "yup";
import clsx from "clsx";
import { useFormik } from "formik";
import { AxiosError } from 'axios'
import { toast } from 'react-toastify'
import "aos/dist/aos.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./style.scss";
import HomeHeader from "../home/home-header/HomeHeader";
import HomeSubscribe from "../home/home-subscribe/HomeSubscribe";
import HomeFooter from "../home/home-footer/HomeFooter";
import { Container, FormGroup } from "react-bootstrap";
import ErrorText from "../../core/components/error-text";
import { alphaOnly } from "../../utils";
import { LANG } from "../../constants/language";
import { createContact } from "../../services/user.service";
import { Link } from "react-router-dom";
import LocationIcon from "../../icons/LocationIcon";
import MobileIcon from "../../icons/MobileIcon";
import PhoneIcon from "../../icons/PhoneIcon";
import HomeFollow from "../home/home-follow/HomeFollow";
import { WebPage } from "../../types/webpage";
import { useSelector } from "react-redux";
import { ContactPageSelector } from "../../core/data/redux/webpage/webPageAction";

const subscribeSchema = Yup.object().shape({
  title: Yup.string().required(LANG.FIELD_IS_REQUIRED),
  subTitle: Yup.string().required(LANG.FIELD_IS_REQUIRED),
  firstName: Yup.string().required(LANG.FIRSTNAME_IS_REQUIRED),
  lastName: Yup.string().required(LANG.LASTNAME_IS_REQUIRED),
  jahrgang: Yup.string().required(LANG.FIELD_IS_REQUIRED),
  phone: Yup.string().min(10, LANG.MINIMUM_LIMIT_PHONE_CHAR).max(13, LANG.MAXIMUM_LIMIT_HUNDRED_CHAR).required(LANG.FIELD_IS_REQUIRED),
  email: Yup.string().email(LANG.PLEASE_ADD_VALID_EMAIL).required(LANG.EMAIL_IS_REQUIRED),
  news: Yup.string().required(LANG.FIELD_IS_REQUIRED),
})
const ContactUs = () => {
  const contact: WebPage = useSelector(ContactPageSelector);
  const initialValues = {
    title: contact?.contact?.title || "Anfahrt zum Sportzentrum",
    subTitle: "kantakt",
    firstName: "",
    lastName: "",
    jahrgang: "",
    phone: "",
    email: "",
    news: ""
  };
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues,
    validationSchema: subscribeSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setLoading(true);
      try {
        const result = await createContact(values);
        if (result.status == 200) {
          toast.success(LANG.THE_CONTACT_FORM_SUBMMITED_SUCCESSFULLY);
          resetForm();
        }
        setSubmitting(false);
        setLoading(false);
      } catch (error) {
        if (error instanceof AxiosError) {
          toast.error(error.response?.data?.responseMessage)
        }
        setSubmitting(false);
        setLoading(false);
      }
    },
  });

  const formik2 = useFormik({
    initialValues,
    validationSchema: subscribeSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setLoading(true);
      try {
        const result = await createContact(values);
        if (result.status == 200) {
          toast.success(LANG.THE_CONTACT_FORM_SUBMMITED_SUCCESSFULLY);
          resetForm();
        }
        setSubmitting(false);
        setLoading(false);
      } catch (error) {
        if (error instanceof AxiosError) {
          toast.error(error.response?.data?.responseMessage)
        }
        setSubmitting(false);
        setLoading(false);
      }
    },
  });
  return (
    <>
      <div className="contactpage BgLightBlue">
        <HomeHeader />
        <Container>
          <div className="contactBanner">
            <div className="row">
              <div className="col-md-6 pe-lg-5">
                <div className="contactContent ">
                  <h2>{contact?.contact?.title}</h2>
                  <ul className="d-flex flex-column gap-3">
                    <li className="d-flex align-items-center"><label className="me-2">
                      <LocationIcon /></label>{contact?.contact?.contactAddress}</li>
                    <li className="d-flex align-items-center"><label className="me-2">
                      <PhoneIcon /></label><Link to={"tel:11234567890"}>{contact?.contact?.contactPhone||"-"}</Link> </li>
                    <li className="d-flex align-items-center"><label className="me-2">
                      <MobileIcon /></label><Link to={"tel:11234567891"}>{contact?.contact?.contactMobile||"-"}</Link></li>
                  </ul>
                  <form onSubmit={formik.handleSubmit}>
                    <FormGroup className="mb-4">
                      <div className="row">
                        <div className="col-6">
                          <input type="text" placeholder={LANG.FIRST_NAME}
                            maxLength={64}
                            onKeyPress={alphaOnly}
                            {...formik.getFieldProps("firstName")}
                            className={clsx(
                              "form-control commonInput",
                              { "border border-danger": formik.touched.firstName && formik.errors.firstName },
                            )} />
                          <ErrorText show={formik.touched.firstName && formik.errors.firstName} message={formik.errors?.firstName} />
                        </div>
                        <div className="col-6">
                          <input type="text" placeholder={LANG.LAST_NAME}
                            maxLength={64}
                            onKeyPress={alphaOnly}
                            {...formik.getFieldProps("lastName")}
                            className={clsx(
                              "form-control commonInput",
                              { "border border-danger": formik.touched.lastName && formik.errors.lastName },
                            )} />
                          <ErrorText show={formik.touched.lastName && formik.errors.lastName} message={formik.errors?.lastName} />

                        </div>
                      </div>
                    </FormGroup>
                    <FormGroup className="mb-4">
                      <div className="row">
                        <div className="col-6">
                          <input placeholder={LANG.JAHRGANG}
                            {...formik.getFieldProps("jahrgang")}
                            className={clsx(
                              "form-control commonInput bg-transparent",
                              { "border border-danger": formik.touched.jahrgang && formik.errors.jahrgang },
                            )}
                            type="text" />
                          <ErrorText show={formik.touched.jahrgang && formik.errors.jahrgang} message={formik.errors?.jahrgang} />
                        </div>
                        <div className="col-6">
                          <input placeholder={LANG.ENTER_EMAIL_ADDRESS}
                            {...formik.getFieldProps("email")}
                            className={clsx(
                              "form-control commonInput bg-transparent",
                              { "border border-danger": formik.touched.email && formik.errors.email },
                            )}
                            type="email" />
                          <ErrorText show={formik.touched.email && formik.errors.email} message={formik.errors?.email} />
                        </div>
                      </div>
                    </FormGroup>
                    <FormGroup className="mb-4">
                      <input placeholder={LANG.PHONE}
                        {...formik.getFieldProps("phone")}
                        className={clsx(
                          "form-control commonInput bg-transparent",
                          { "border border-danger": formik.touched.phone && formik.errors.phone },
                        )}
                        type="number" min={0} />
                      <ErrorText show={formik.touched.phone && formik.errors.phone} message={formik.errors?.phone} />

                    </FormGroup>
                    <FormGroup className="mb-4">
                      <textarea placeholder={LANG.ENTER_YOUR_MSG_HERE}
                        {...formik.getFieldProps("news")}

                        className={clsx(
                          "form-control commonInput bg-transparent",
                          { "border border-danger": formik.touched.news && formik.errors.news },
                        )} />
                      <ErrorText show={formik.touched.news && formik.errors.news} message={formik.errors?.news} />

                    </FormGroup>
                    <FormGroup>
                      <button type="submit" className="w-100">{LANG.SEND_BUTTON}</button>
                    </FormGroup>
                  </form>
                </div>
              </div>
              <div className="col-md-6  ps-lg-5">
                <iframe className="w-100"
                  src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d2012.1584840222351!2d8.827980675720738!3d50.11424997152991!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNTDCsDA2JzUxLjMiTiA4wrA0OSc1MC4wIkU!5e1!3m2!1sde!2sin!4v1734694766865!5m2!1sde!2sin" height="650" style={{ border: 0 }} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade"
                >
                </iframe>
              </div>
            </div>
          </div>
        </Container>
        <div className="sectionBgImage ">
          <Container>
            <div className="row align-items-center">
              <div className="col-sm-6">
                <div className="contentWrp ">
                  <h2>{contact?.contact?.ctaTitle}</h2>
                  <p>{contact?.contact?.ctaSubTitle}</p>
                  <Link to={contact?.contact?.btnLink || "#"} relative={'path'}>
                    <button className="btn btn-primary">{contact?.contact?.btnText}</button>
                  </Link>
                </div>
              </div>
              <div className="col-sm-6 contactContent">
                <form onSubmit={formik2.handleSubmit}>
                  <h3>{contact?.contact?.contactFormTitle2}</h3>
                  <FormGroup className="mb-4">
                    <div className="row">
                      <div className="col-6">
                        <input type="text" placeholder={LANG.FIRST_NAME}
                          maxLength={64}
                          onKeyPress={alphaOnly}
                          {...formik2.getFieldProps("firstName")}
                          className={clsx(
                            "form-control commonInput",
                            { "border border-danger": formik2.touched.firstName && formik2.errors.firstName },
                          )} />
                        <ErrorText show={formik2.touched.firstName && formik2.errors.firstName} message={formik2.errors?.firstName} />
                      </div>
                      <div className="col-6">
                        <input type="text" placeholder={LANG.LAST_NAME}
                          maxLength={64}
                          onKeyPress={alphaOnly}
                          {...formik2.getFieldProps("lastName")}
                          className={clsx(
                            "form-control commonInput",
                            { "border border-danger": formik2.touched.lastName && formik2.errors.lastName },
                          )} />
                        <ErrorText show={formik2.touched.lastName && formik2.errors.lastName} message={formik2.errors?.lastName} />

                      </div>
                    </div>
                  </FormGroup>
                  <FormGroup className="mb-4">
                    <div className="row">
                      <div className="col-6">
                        <input placeholder={LANG.JAHRGANG}
                          {...formik2.getFieldProps("jahrgang")}
                          className={clsx(
                            "form-control commonInput bg-transparent",
                            { "border border-danger": formik2.touched.jahrgang && formik2.errors.jahrgang },
                          )}
                          type="text" />
                        <ErrorText show={formik2.touched.jahrgang && formik2.errors.jahrgang} message={formik2.errors?.jahrgang} />
                      </div>
                      <div className="col-6">
                        <input placeholder={LANG.ENTER_EMAIL_ADDRESS}
                          {...formik2.getFieldProps("email")}
                          className={clsx(
                            "form-control commonInput bg-transparent",
                            { "border border-danger": formik2.touched.email && formik2.errors.email },
                          )}
                          type="email" />
                        <ErrorText show={formik2.touched.email && formik2.errors.email} message={formik2.errors?.email} />
                      </div>
                    </div>
                  </FormGroup>
                  <FormGroup className="mb-4">
                    <input placeholder={LANG.PHONE}
                      {...formik2.getFieldProps("phone")}
                      className={clsx(
                        "form-control commonInput bg-transparent",
                        { "border border-danger": formik2.touched.phone && formik2.errors.phone },
                      )}
                      type="number" min={0} />
                    <ErrorText show={formik2.touched.phone && formik2.errors.phone} message={formik2.errors?.phone} />

                  </FormGroup>
                  <FormGroup className="mb-4">
                    <textarea placeholder={LANG.ENTER_YOUR_MSG_HERE}
                      {...formik2.getFieldProps("news")}

                      className={clsx(
                        "form-control commonInput bg-transparent",
                        { "border border-danger": formik2.touched.news && formik2.errors.news },
                      )} />
                    <ErrorText show={formik2.touched.news && formik2.errors.news} message={formik2.errors?.news} />

                  </FormGroup>
                  <FormGroup>
                    <button type="submit" className="w-100">{LANG.SEND_BUTTON}</button>
                  </FormGroup>
                </form>
              </div>
            </div>
          </Container>
        </div>
        <HomeFollow />
        <HomeSubscribe />
      </div>
      <HomeFooter />
    </>
  );
};

export default ContactUs;
