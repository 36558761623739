import React from 'react'
import HomeHeader from '../home/home-header/HomeHeader';
import './style.scss';
import HomeFooter from '../home/home-footer/HomeFooter';
import { DatenschutzPageSelector } from '../../core/data/redux/webpage/webPageAction';
import { WebPage } from '../../types/webpage';
import { useSelector } from 'react-redux';
import ReactQuill from 'react-quill';

const Datenschutz = () => {
    const datenschutz: WebPage = useSelector(DatenschutzPageSelector);
    return (
        <div className='IMPressumPage BgLightBlue'>
            <div className='headerCover'>
                <HomeHeader />
            </div>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='textFull'>
                            <h1 className='text-break'>DATENSCHUTZERKLÄRUNG</h1>
                            <ReactQuill value={datenschutz?.datenschutz}
                                                  readOnly={true}
                                                  theme={"bubble"} />
                        </div>
                    </div>
                </div>
            </div>
            <HomeFooter />
        </div>
    )
}
export default Datenschutz;