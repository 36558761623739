import React from "react";

const TotalMembersIcon = () => {
  return (
    <svg
      width="57"
      height="56"
      viewBox="0 0 57 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.666016" width="56" height="56" rx="8" fill="#E8E8E8" />
      <mask
        id="mask0_146_2084"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="12"
        y="12"
        width="33"
        height="32"
      >
        <rect x="12.666" y="12" width="32" height="32" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_146_2084)">
        <path
          d="M20.6654 40C19.1987 40 17.9431 39.4778 16.8987 38.4333C15.8543 37.3889 15.332 36.1333 15.332 34.6667C15.332 33.2 15.8543 31.9444 16.8987 30.9C17.9431 29.8556 19.1987 29.3333 20.6654 29.3333C22.132 29.3333 23.3876 29.8556 24.432 30.9C25.4765 31.9444 25.9987 33.2 25.9987 34.6667C25.9987 36.1333 25.4765 37.3889 24.432 38.4333C23.3876 39.4778 22.132 40 20.6654 40ZM36.6654 40C35.1987 40 33.9431 39.4778 32.8987 38.4333C31.8543 37.3889 31.332 36.1333 31.332 34.6667C31.332 33.2 31.8543 31.9444 32.8987 30.9C33.9431 29.8556 35.1987 29.3333 36.6654 29.3333C38.132 29.3333 39.3876 29.8556 40.432 30.9C41.4765 31.9444 41.9987 33.2 41.9987 34.6667C41.9987 36.1333 41.4765 37.3889 40.432 38.4333C39.3876 39.4778 38.132 40 36.6654 40ZM28.6654 26.6667C27.1987 26.6667 25.9431 26.1444 24.8987 25.1C23.8543 24.0556 23.332 22.8 23.332 21.3333C23.332 19.8667 23.8543 18.6111 24.8987 17.5667C25.9431 16.5222 27.1987 16 28.6654 16C30.132 16 31.3876 16.5222 32.432 17.5667C33.4765 18.6111 33.9987 19.8667 33.9987 21.3333C33.9987 22.8 33.4765 24.0556 32.432 25.1C31.3876 26.1444 30.132 26.6667 28.6654 26.6667Z"
          fill="#1A1A1A"
        />
      </g>
    </svg>
  );
};

export default TotalMembersIcon;
