import React, { useRef } from 'react'
import ShareICirclecon from '../../../icons/ShareICirclecon';
import CopyTextIcon from '../../../icons/CopyTextIcon'
import { useSelector } from 'react-redux'
import { LANG } from '../../../constants/language'
import { copyTxtToClip, getAge, imageUrlToBase64 } from '../../../utils';
import moment from 'moment';
import jsPDF from 'jspdf';
import { digitalCardImage } from '../../../utils/digital';
import { toast } from 'react-toastify';

const DigitalCard = () => {
    const fileUrl = process.env.REACT_APP_FILE_URL;
    const user = useSelector((state: any) => state.user?.userDetail);

    const reportTemplateRef: any = useRef(null);

    const handleGeneratePdf = async () => {
        try {
            const doc = new jsPDF(
                'p',
                'px',
                [427, 312]
            );
            doc.addImage(digitalCardImage, 'PNG', 0, 0, doc.internal.pageSize.getWidth(), doc.internal.pageSize.getHeight());
            // Add text or other content
            doc.text(LANG.DIGITAL_CARD, 10, 20);
            doc.text(`${String(user?._id).slice(0, 10)}`, 125, 170);
            doc.text(`${LANG.FIRST_NAME}: ${user.firstName}`, 80, 200);
            doc.text(`${LANG.LAST_NAME}: ${user.lastName}`, 80, 220);
            doc.text(`${LANG.SEASON}: ${moment().year()}/${moment().year() + 1}`, 80, 240);
            if (user?.avatar) {
                let img =await imageUrlToBase64(fileUrl+user?.avatar);
                img= 'data:image/jpeg;base64,'+img,
                doc.addImage(img, "JPEG", 108, 50, 100, 100)
                doc.save('digital-karte.pdf');
            } else {
                doc.addImage("/assets/img/default-avatar.png", "PNG", 108, 50, 100, 100)
                doc.save('digital-Karte.pdf');
            }
            toast.success(LANG.DIGITAL_CARD_DOWNLOADED_SUCCESS);
        } catch (error) {
            console.log(`Error: ${error}`);
        }
    };
    return (
        <div className='position-relative'>
            <button type="button" className="btn position-absolute top-0 end-0 m-1" onClick={handleGeneratePdf}>
                <ShareICirclecon />
            </button>
            <div style={{
                // backgroundImage: 'url("https://kv-muehlheim.de/static/media/digitalCardBg.326251aeeeff9380e4ab.jpg")',
                backgroundSize: 'cover',
                borderRadius: 8,
                padding: '20px 16px',
                margin: '24px 0'
            }} className='digitalCard' ref={reportTemplateRef}>
                <div className="d-flex align-items-center justify-content-between mb-32px">
                    <h3 className="mb-0">{LANG.DIGITAL_CARD}</h3>
                </div>
                <div className="d-flex justify-content-center flex-column align-items-center">
                    <img className='rounded-circle' style={{maxHeight:100}} src={user?.avatar ? fileUrl + user?.avatar : "/assets/img/default-avatar.png"} height={100} width={100} alt="friendOne" />
                    <h4 style={{ textAlign: 'center' }}>{user?.firstName} {user?.lastName}</h4>
                    <div className="id_copy">
                        <span className="empId">{String(user?._id).slice(0, 10)}</span>
                        <span className="copyText cursor-pointer" onClick={() => copyTxtToClip(user?._id)}>
                            <CopyTextIcon />
                        </span>
                    </div>
                    <div className="cardInfo w-100">
                        <ul className="m-0">
                            <li className="d-flex justify-content-between">
                                <label className="text-uppercase">{LANG.AGE}</label>
                                <label>{getAge(user?.dob) || 1} {LANG.YEARS}</label>
                            </li>
                            <li className="d-flex justify-content-between">
                                <label className="text-uppercase">
                                    {LANG.SEASON}
                                </label>
                                <label>{moment().year()}/{moment().year() + 1}</label>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DigitalCard