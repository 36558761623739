import React, { useEffect, useState } from "react";
import FriendsBlock from "../../friendsBlock/page";
import { LANG } from "../../../../constants/language";
import VisibilityBox from "../../VisibilityBox";
import { getMyFriends } from "../../../../services/friends.service";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import ProfileCard from "../../profile-card/page";

export default function FriendTabContent() {
  const [friends, setFriends] = useState<any[]>([]);
  const id= String(localStorage.getItem("id"));
  
  const [show, setShow]= useState<boolean>(false);
  const [user, setUser]= useState<any>();

  useEffect(()=>{
    getAllData();
  },[]);

  const getAllData=async()=>{
    try {
      const [myFriends]=await Promise.all([getMyFriends({})])
      setFriends(myFriends?.data?.data);
    } catch (error) {
      console.log(error)
    }
  }

  const friendClick = (request: any) => {
    if(request.senderId==id){
      setUser(request.user)
    }else{
      setUser(request.sender)
    }
    setShow(true);
  }


  return (
    <div className="commonGrid">
      <VisibilityBox show={friends?.length > 0}>
        <h4 className="mb-32px">{LANG.FRIENDS}</h4>
        <div className="row">
          {friends.map((item, index) => (
            <div className="col-sm-6 col-md-4 col-lg-3 mb-3" key={index}>
              <FriendsBlock user={item?.senderId == id? item?.user:item?.sender} click={() => friendClick(item)} />
            </div>
          ))}
        </div>
      </VisibilityBox>

      <VisibilityBox show={friends?.length==0}>
          <div className="my-2">
            <h4>{LANG.NO_FRIENDS_AVAILABLE}</h4>
          </div>
      </VisibilityBox>

      <ProfileCard show={show} user={user}
        onHide={() => setShow(false)} />
      
    </div>
  );
}
