import React, { useEffect, useState } from 'react'
import { Dropdown, Table } from 'react-bootstrap'
import CrossRedCircleIcon from '../../../../icons/CrossRedCircleIcon';
import TickBlueCircleIcon from '../../../../icons/TickBlueCircleIcon';
import { acceptEvent, getAllEventInvitations, rejectEvent } from '../../../../services/event.service';
import { checkEventAccpeted, getEventStatus } from '../../../../utils';
import { toast } from 'react-toastify';
import { LANG } from '../../../../constants/language';
import { AxiosError } from 'axios';
import moment from 'moment';
import VThreeDots from '../../../../icons/VThreeDots';
import VisibilityBox from '../../VisibilityBox';
import ProfileCard from '../../profile-card/page';
import { getAllTeams, getAllUserTeams } from '../../../../services/teams.service';
import TeamBlock from '../../teamBlock/page';
import { useNavigate, useSearchParams } from 'react-router-dom';
import DigitalCard from '../../digital-card/DigitalCard';
import { useDispatch, useSelector } from 'react-redux';
import { setTeams } from '../../../data/redux/teams/teamSlice';
import { getFriendRequest, getMyFriends, updateFriend } from '../../../../services/friends.service';
import FriendsBlock from '../../friendsBlock/page';
import FriendsRequestModal from '../friendRequestModal/page';
import { setRequestModalStatus } from '../../../data/redux/shared/sharedSlice';
import { Calendar } from 'primereact/calendar';
import EventCalendar from '../../common/EventCalendar';
import EventDetailModal from '../../event-detail-modal/EventDetailModal';
import RejectEventModal from '../../reject-event-modal/RejectEventModal';
import { status_translation } from '../../../../constants';
export default function MemberDashboardTabContent() {
  const userId = localStorage.getItem("id");
  const [requestEventLists, setRequestEventLists] = useState<any[]>([]);
  const [eventLists, setEventLists] = useState<any[]>([]);
  const [friends, setFriends] = useState<any[]>([]);
  const [friendsRequest, setFriendsRequest] = useState<any[]>([]);
  const myTeams = useSelector((state: any) => state?.team?.teams);
  const { requestModalStatus } = useSelector((state: any) => state.shared);
  const [date, setDate] = useState(null);
  const dispatch = useDispatch();
  const fileUrl = process.env.REACT_APP_FILE_URL;
  const id = String(localStorage.getItem("id"));

  const [show, setShow] = useState<boolean>(false);
  const [user, setUser] = useState<any>();

  const [params, setParams] = useSearchParams();
  const [visible, setVisible] = useState(false);

  const [showEvent, setShowEvent] = useState<boolean>(false);
  const [selectedEvent, setSelectedEvent] = useState<any>();

  const [showReject, setShowReject] = useState<boolean>(false);

  const router = useNavigate();

  useEffect(() => {
    getAllData();
    getAllFriendsData();
  }, [])

  useEffect(() => {
    if(params?.get("tab")=='dashboard')
       getAllData();
  }, [params?.get("tab")])

  const getAllData = async () => {
    try {
      const [eventDetail, myTeams] = await Promise.all(
        [getAllEventInvitations({}), getAllUserTeams({})]
      );
      if (eventDetail?.status == 200 && eventDetail.data?.data?.length) {
        const requested = eventDetail.data?.data?.filter((event: any) => checkEventAccpeted(event, userId) && event?.approved)
        const accepted = eventDetail.data?.data?.filter((event: any) => !checkEventAccpeted(event, userId))
        setEventLists(accepted);
        setRequestEventLists(requested);
      }
      if (myTeams?.status == 200) {
        dispatch(setTeams(myTeams?.data?.data || []));
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getAllFriendsData = async () => {
    try {
      const [myFriends, myRequests] = await Promise.all(
        [getMyFriends({}), getFriendRequest({})]
      );
      if (myFriends.status == 200) {
        setFriends(myFriends.data?.data);
      }
      if (myRequests.status == 200) {
        setFriendsRequest(myRequests.data?.data);
      }
    } catch (error) {
      console.log(error)
    }
  }

  const acceptEventRequest = async (event: any) => {
    try {
      const result = await acceptEvent(event?._id, userId);
      if (result?.status == 200) {
        toast.success(LANG.EVENT_REQUEST_ACCEPTED_SUCCESSFULLY);
        getAllData();
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        toast.error(error.response?.data?.responseMessage);
      }
    }
  }

  const rejectEventRequest = async (event:any) => {
    try {
      const result = await rejectEvent(selectedEvent?._id, userId,event?.reason);
      if (result?.status == 200) {
        setShowReject(false);
        toast.success(LANG.EVENT_REQUEST_REJECTED_SUCCESSFULLY);
        getAllData();
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        toast.error(error.response?.data?.responseMessage);
      }
    }
  }

  const openTeam = (team: any) => {
    router(`/trainer/trainer-dashboard?tab=team&teamId=${team?._id}`)
  }

  const acceptRequest = async (request: any) => {
    try {
      const result = await updateFriend(request?._id, { status: 'accepted' });
      if (result.status == 200) {
        await getAllFriendsData();
      }
    } catch (error) {
      console.log(error)
    }
  }

  const declineRequest = async (request: any) => {
    try {
      const result = await updateFriend(request?._id, { status: 'rejected' });
      if (result.status == 200) {
        await getAllFriendsData();
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    setVisible(false);
    if (params.get("tab")) {
      setTimeout(() => {
        setVisible(true);
      }, 200);
    }
  }, [params.get("tab")]);

  const friendClick = (request: any) => {
    if (request.senderId == id) {
      setUser(request.user)
    } else {
      setUser(request.sender)
    }
    setShow(true);
  }

  const openRequestModal = () => {
    dispatch(setRequestModalStatus(true));
  }

  const viewEvent = (event: any) => {
    setSelectedEvent(event);
    setShowEvent(true);
  }

  const eventStatus= (event: any) =>{
    return getEventStatus(event, userId);
  }
  return (
    <div className="dashboardTabContent">
      <div className="tableWrapper alerttable mt-3">
        <div className="title_Counter d-flex justify-content-between mb-3">
          <h4>{LANG.EVENT_REQUEST}</h4>
          {/* <div className="pendingCounter">
                  Pending<span>8+</span>
                </div> */}
        </div>
        <div className="table-responsive scrollHeight">
          <Table className="table-event-request">
            <thead>
              <tr>
                <th>{LANG.EVENT_TYPE}</th>
                <th>{LANG.GROUND_NAME}</th>
                <th>{LANG.DURATION}</th>
                <th>{LANG.DATE}</th>
                <th>{LANG.PARTICIPANTS}</th>
                <th>{LANG.TRAINER}</th>
                <th>{LANG.ACTION}</th>
              </tr>
            </thead>
            <tbody>
              {
                requestEventLists.length ? <>
                  {
                    requestEventLists.map((event: any, index: number) =>
                      <tr key={index}>
                        <td className="text-capitalize">{event?.eventType}</td>
                        <td>{event?.room?.roomName}</td>
                        <td>{event?.eventDuration}</td>
                        <td>{moment(event?.date).format("DD MMM YYYY")} {event?.time}</td>
                        <td className="text-capitalize">{event?.participantType}</td>
                        <td>{event?.createdBy?.firstName} {event?.createdBy?.lastName}</td>
                        <td>
                          <button className="crossBtn" onClick={() => {
                            setSelectedEvent(event);
                            setShowReject(true);
                          }}>
                            <CrossRedCircleIcon />
                          </button>
                          <button className="tickBtn" onClick={() => acceptEventRequest(event)}>
                            <TickBlueCircleIcon />
                          </button>

                        </td>
                      </tr>
                    )
                  }
                </> :
                  <>
                    <p className="text-center w-100 p-3">
                      {LANG.EVENT_NOT_FOUNDS}
                    </p>
                  </>
              }
            </tbody>
          </Table>
        </div>
      </div>
      <div className="tableWrapper eventstable">
        <div className="title_Counter d-flex justify-content-between mb-3">
          <h4>{LANG.EVENTS}</h4>
          {/* <div className="pendingCounter">
                  Pending<span>8+</span>
                </div> */}
        </div>
        <div className="table-responsive scrollHeight">
          <Table className="">
            <thead>
              <tr>
                <th>{LANG.EVENT_TYPE}</th>
                <th>{LANG.GROUND_NAME}</th>
                <th>{LANG.DURATION}</th>
                <th>{LANG.DATE}</th>
                <th>{LANG.PARTICIPANTS}</th>
                <th>{LANG.TRAINER}</th>
                <th>{LANG.ACTION}</th>
              </tr>
            </thead>
            <tbody>
              {
                eventLists.length ? <>
                  {
                    eventLists.map((event: any, index: number) =>

                      <tr key={index}>
                        <td className="text-capitalize">{event?.eventType}</td>
                        <td>{event?.room?.roomName}</td>
                        <td>{event?.eventDuration}</td>
                        <td>{moment(event?.date).format("DD MMM YYYY")} {event?.time}</td>
                        <td className="text-capitalize">{event?.participantType}</td>
                        <td>{event?.createdBy?.firstName} {event?.createdBy?.lastName}</td>

                        <td>
                          <div className="d-flex gap-2">
                            <span className={eventStatus(event)=='accepted' ? "event_approved text-capitalize fs-6" : "event_under_review text-capitalize fs-6"}>
                                {status_translation[eventStatus(event)]}
                            </span>
                            <span onClick={() => viewEvent(event)} className="editDropdown">
                              <VThreeDots  />
                            </span>
                            {/* <Dropdown className="editDropdown">
                              <Dropdown.Toggle
                                variant="success"
                                id="dropdown-basic"
                              >
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item
                                  
                                >
                                  {LANG.VIEW_DETAILS}
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown> */}
                          </div>
                        </td>
                      </tr>
                    )
                  }
                </> :
                  <>
                    <p className="text-center w-100 p-3">
                      {LANG.EVENT_NOT_FOUNDS}
                    </p>
                  </>
              }
            </tbody>
          </Table>
        </div>
      </div>
      <div className="">
        <div className="row">
          <div className="col-lg-9">
            <div className="commonGrid">


              <VisibilityBox show={friends?.length > 0}>
                <h4 className="mb-32px">{LANG.FRIENDS}</h4>
                <div className="row">
                  {friends.map((item, index) => (
                    <div className="col-sm-6 col-md-4 col-lg-4 mb-3" key={index}>
                      <FriendsBlock user={item?.senderId == id ? item?.user : item?.sender} click={() => friendClick(item)} />
                    </div>
                  ))}
                </div>
              </VisibilityBox>


              <h4 className="mb-32px">{LANG.MY_TEAM}</h4>
              <VisibilityBox show={myTeams.length > 0}>
                <div className="row">
                  {myTeams.map((item: any, index: number) => (
                    <div className="col-sm-6 col-md-4 col-lg-4 mb-3" key={index}>
                      <TeamBlock team={item} open={() => openTeam(item)} />
                    </div>
                  ))}
                </div>
              </VisibilityBox>
              <VisibilityBox show={myTeams.length == 0}>
                <div>
                  <p className="text-center w-100 p-3">
                    {LANG.NO_DATA}
                  </p>
                </div>
              </VisibilityBox>

            </div>
          </div>
          <div className="col-lg-3">
            <div className="rightSidebar">
              <VisibilityBox show={friendsRequest?.length > 0}>
                <div className="friendsRequest">
                  <div className="title_Counter d-flex justify-content-between mb-3 align-items-center">
                    <h4 className="mb-0">{LANG.FRIEND_REQUESTS}</h4>
                    <div className="pendingCounter cursor-pointer" onClick={openRequestModal}>
                      <span>{friendsRequest?.length}</span>
                    </div>
                  </div>
                  <div className="pendingRequest">
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="pendingFriendImg d-flex align-items-center flex-wrap">
                        <img
                          src={friendsRequest[0]?.sender?.avatar ? fileUrl + friendsRequest[0]?.sender?.avatar : "/assets/img/default-avatar.png"}
                          width={40}
                          height={40}
                          alt="friendOne"
                        />
                        <h6 title={`${friendsRequest[0]?.sender?.firstName} ${friendsRequest[0]?.sender?.lastName}`}>{friendsRequest[0]?.sender?.firstName} {friendsRequest[0]?.sender?.lastName}</h6>
                      </div>
                      <div className="bothBtn">
                        <button className="crossBtn" onClick={() => declineRequest(friendsRequest[0])}>
                          <CrossRedCircleIcon />
                        </button>
                        <button className="tickBtn" onClick={() => acceptRequest(friendsRequest[0])}>
                          <TickBlueCircleIcon />
                        </button>
                      </div>
                    </div>
                    <div className=""></div>
                  </div>
                </div>
              </VisibilityBox>
              <DigitalCard />
              {/* <h2 className="text-center">Calendar Pending </h2> */}
              <div className='p-2 rounded bg-light mb-3'>
                <VisibilityBox show={visible}>
                  <EventCalendar events={eventLists} />
                </VisibilityBox>
                <div className='d-flex gap-3 mt-3 mb-2'>
                  <div className='cal-practice-card rounded p-2'>
                    <span></span>
                    {LANG.GAME}
                  </div>
                  <div className='cal-training-card rounded p-2'>
                    <span></span>
                    {LANG.TRAINING}
                  </div>
                  <div className='cal-event-card rounded p-2'>
                    <span></span>
                    {LANG.EVENT}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ProfileCard show={show} user={user}
        onHide={() => setShow(false)} />
      {requestModalStatus && <FriendsRequestModal refresh={getAllFriendsData} />}

      <EventDetailModal show={showEvent} onHide={() => setShowEvent(false)} event={selectedEvent} />
      <RejectEventModal show={showReject} onHide={() => setShowReject(false)} submit={rejectEventRequest}/>
    </div>
  )
}
