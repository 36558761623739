import { WebPage } from "../../../../types/webpage";

export const HomePageSelector = (state:any) => state.webpage.webpages.find((wp:WebPage) => wp.page === 'home');

export const VorstandPageSelector = (state:any) => state.webpage.webpages.find((wp:WebPage) => wp.page === 'vorstand');

export const AnsprechpartnerPageSelector = (state:any) => state.webpage.webpages.find((wp:WebPage) => wp.page === 'ansprechpartner');

export const GeschichtePageSelector = (state:any) => state.webpage.webpages.find((wp:WebPage) => wp.page === 'geschichte');

export const MannschaftPage1Selector = (state:any) => state.webpage.webpages.find((wp:WebPage) => wp.page === 'mannschaft1');

export const MannschaftPage2Selector = (state:any) => state.webpage.webpages.find((wp:WebPage) => wp.page === 'mannschaft2');

export const AlteHerrenPageSelector = (state:any) => state.webpage.webpages.find((wp:WebPage) => wp.page === 'alte_herren');

export const U17B1PageSelector = (state:any) => state.webpage.webpages.find((wp:WebPage) => wp.page === 'u17_b1');

export const U14C1PageSelector = (state:any) => state.webpage.webpages.find((wp:WebPage) => wp.page === 'u14_c1');

export const U15C2PageSelector = (state:any) => state.webpage.webpages.find((wp:WebPage) => wp.page === 'u15_c2');

export const U12C3PageSelector = (state:any) => state.webpage.webpages.find((wp:WebPage) => wp.page === 'u12_c3');

export const U13D1PageSelector = (state:any) => state.webpage.webpages.find((wp:WebPage) => wp.page === 'u13_d1');

export const U12D2PageSelector = (state:any) => state.webpage.webpages.find((wp:WebPage) => wp.page === 'u12_d2');

export const U12D3PageSelector = (state:any) => state.webpage.webpages.find((wp:WebPage) => wp.page === 'u12_d3');

export const U11E1PageSelector = (state:any) => state.webpage.webpages.find((wp:WebPage) => wp.page === 'u11_e3');

export const U9F1PageSelector = (state:any) => state.webpage.webpages.find((wp:WebPage) => wp.page === 'u9_f1');

export const U10F2PageSelector = (state:any) => state.webpage.webpages.find((wp:WebPage) => wp.page === 'u10_f2');

export const U8F3PageSelector = (state:any) => state.webpage.webpages.find((wp:WebPage) => wp.page === 'u8_f3');

export const U9F4PageSelector = (state:any) => state.webpage.webpages.find((wp:WebPage) => wp.page === 'u9_f4');

export const U7G1PageSelector = (state:any) => state.webpage.webpages.find((wp:WebPage) => wp.page === 'u7_g1');

export const SponsorenPageSelector = (state:any) => state.webpage.webpages.find((wp:WebPage) => wp.page === 'sponsoren');

export const DownloadPageSelector = (state:any) => state.webpage.webpages.find((wp:WebPage) => wp.page === 'downloads');

export const ContactPageSelector = (state:any) => state.webpage.webpages.find((wp:WebPage) => wp.page === 'kontakt');

export const ApplicationPageSelector = (state:any) => state.webpage.webpages.find((wp:WebPage) => wp.page === 'app');

export const ImpressumPageSelector = (state:any) => state.webpage.webpages.find((wp:WebPage) => wp.page === 'impressum');

export const DatenschutzPageSelector = (state:any) => state.webpage.webpages.find((wp:WebPage) => wp.page === 'datenschutz');

export const FooterPageSelector = (state:any) => state.webpage.webpages.find((wp:WebPage) => wp.page === 'footer');
