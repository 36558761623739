import React from "react";

export default function FacebookBlueIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="37.301"
      height="37.075"
      viewBox="0 0 37.301 37.075"
    >
      <path
        id="FontAwsome_facebook_"
        data-name="FontAwsome (facebook)"
        d="M45.3,26.65A18.651,18.651,0,1,0,23.736,45.075V32.042H19V26.65h4.738V22.541c0-4.674,2.783-7.256,7.044-7.256a28.7,28.7,0,0,1,4.175.364v4.587H32.6a2.7,2.7,0,0,0-3.039,2.913v3.5h5.173l-.827,5.391H29.565V45.075A18.657,18.657,0,0,0,45.3,26.65Z"
        transform="translate(-8 -8)"
        fill="#0062a7"
      />
    </svg>
  );
}
