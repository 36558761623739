import React from 'react'
import './style.scss';
import HomeHeader from '../home/home-header/HomeHeader';
import { FormGroup, Nav, Tab, Table } from 'react-bootstrap';
import HomeFollow from '../home/home-follow/HomeFollow';
import HomeSubscribe from '../home/home-subscribe/HomeSubscribe';
import HomeFooter from '../home/home-footer/HomeFooter';
export default function Teams() {
  return (
    <div className='teamsPage BgLightBlue'>
        <HomeHeader/>
        <div className='teamsPageContent'>
            <div className='singleTeam'>
                <div className='row'>
                    <div className='col-sm-7'>
                        <div className='teamContent'>
                            <h1 className='pb-4'>1. Mannschaft</h1>
                            <FormGroup className='mb-5'>
                                <h4>Max Mustermann</h4>
                                <p>Trainer - 1. Mannschaft</p>
                            </FormGroup>
                            <FormGroup className='mb-5'>
                                <h4>Trainingszeiten:</h4>
                                <p>Dienstag & Donnerstag ab 19:00 Uhr</p>
                            </FormGroup>    
                        </div>
                    </div>
                    <div className='col-sm-5'>
                        <div className='teamImg'>
                            <img src='/assets/img/profiles/avatar-06.jpg' alt='avatar-06'/>
                        </div>
                    </div>
                </div>
            </div>
            <div className='tabWrapper'>
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                    <div className='upperTab'>
                        <div className='container'>
                            <Nav variant="pills" className="flex-row">
                                <Nav.Item className='w-33'>
                                    <Nav.Link eventKey="first">NACHSTE SPIELE</Nav.Link>
                                </Nav.Item>
                                <Nav.Item className='w-33'>
                                    <Nav.Link eventKey="second">LETZTE SPIELE</Nav.Link>
                                </Nav.Item>
                                <Nav.Item className='w-33'>
                                    <Nav.Link eventKey="third">VEREINSSPIELPLAN</Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </div>
                    </div>
                    <Tab.Content>
                        <Tab.Pane eventKey="first">
                            <div className='firstTabContent'>
                                <div className='table-responsive'>
                                    <Table striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th className='text-end bg-white'>Datum Zeit</th>
                                            <th className='text-center bg-white'>Wettbewerb</th>
                                            <th className='text-start bg-white'>Info</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className='text-end'>So, 12.01.25 | 14:14</td>
                                            <td>
                                                <div className='w-100 d-flex align-items-center m-auto flex-column matchBetween'>
                                                    <div className='d-flex justify-content-center'>
                                                        <p>C-Junioren | Hallen-Kreisturnier</p>
                                                    </div>
                                                    <div className='d-flex justify-content-btween'>
                                                        <div className='d-flex justify-content-start'>
                                                            <label className='d-flex align-items-center gap-3'><img className='w-50px' src='/assets/img/logo.png' alt='logo'/> <span>KV Muhlheim</span></label>
                                                        </div>
                                                        <div className='d-flex justify-content-start'>
                                                        <label className='d-flex align-items-center gap-3'><span className='between'>: </span><img className='w-50px' src='/assets/img/logo.png' alt='logo'/> <span>KV Muhlheim</span></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                            <div className='d-flex align-items-center flex-column matchBetween fit-content'>
                                                    <div className='d-flex justify-content-center'>
                                                        <p>HM | 365677002</p>
                                                    </div>
                                                    <div className='d-flex justify-content-between gap-5'>
                                                        <div className='d-flex justify-content-start'>
                                                            <label className='d-flex align-items-center gap-3'>-:-</label>
                                                        </div>
                                                        <div className='d-flex justify-content-between'>
                                                            <label className='d-flex align-items-center gap-3'><span>Zum Spiel</span></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='text-end'>So, 12.01.25 | 14:14</td>
                                            <td>
                                                <div className='w-100 d-flex align-items-center m-auto flex-column matchBetween'>
                                                    <div className='d-flex justify-content-center'>
                                                        <p>C-Junioren | Hallen-Kreisturnier</p>
                                                    </div>
                                                    <div className='d-flex justify-content-btween'>
                                                        <div className='d-flex justify-content-start'>
                                                            <label className='d-flex align-items-center gap-3'><img className='w-50px' src='/assets/img/logo.png' alt='logo'/> <span>KV Muhlheim</span></label>
                                                        </div>
                                                        <div className='d-flex justify-content-start'>
                                                        <label className='d-flex align-items-center gap-3'><span className='between'>: </span><img className='w-50px' src='/assets/img/logo.png' alt='logo'/> <span>KV Muhlheim</span></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                            <div className='d-flex align-items-center flex-column matchBetween fit-content'>
                                                    <div className='d-flex justify-content-center'>
                                                        <p>HM | 365677002</p>
                                                    </div>
                                                    <div className='d-flex justify-content-between gap-5'>
                                                        <div className='d-flex justify-content-start'>
                                                            <label className='d-flex align-items-center gap-3'>-:-</label>
                                                        </div>
                                                        <div className='d-flex justify-content-between'>
                                                            <label className='d-flex align-items-center gap-3'><span>Zum Spiel</span></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='text-end'>So, 12.01.25 | 14:14</td>
                                            <td>
                                                <div className='w-100 d-flex align-items-center m-auto flex-column matchBetween'>
                                                    <div className='d-flex justify-content-center'>
                                                        <p>C-Junioren | Hallen-Kreisturnier</p>
                                                    </div>
                                                    <div className='d-flex justify-content-btween'>
                                                        <div className='d-flex justify-content-start'>
                                                            <label className='d-flex align-items-center gap-3'><img className='w-50px' src='/assets/img/logo.png' alt='logo'/> <span>KV Muhlheim</span></label>
                                                        </div>
                                                        <div className='d-flex justify-content-start'>
                                                        <label className='d-flex align-items-center gap-3'><span className='between'>: </span><img className='w-50px' src='/assets/img/logo.png' alt='logo'/> <span>KV Muhlheim</span></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                            <div className='d-flex align-items-center flex-column matchBetween fit-content'>
                                                    <div className='d-flex justify-content-center'>
                                                        <p>HM | 365677002</p>
                                                    </div>
                                                    <div className='d-flex justify-content-between gap-5'>
                                                        <div className='d-flex justify-content-start'>
                                                            <label className='d-flex align-items-center gap-3'>-:-</label>
                                                        </div>
                                                        <div className='d-flex justify-content-between'>
                                                            <label className='d-flex align-items-center gap-3'><span>Zum Spiel</span></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='text-end'>So, 12.01.25 | 14:14</td>
                                            <td>
                                                <div className='w-100 d-flex align-items-center m-auto flex-column matchBetween'>
                                                    <div className='d-flex justify-content-center'>
                                                        <p>C-Junioren | Hallen-Kreisturnier</p>
                                                    </div>
                                                    <div className='d-flex justify-content-btween'>
                                                        <div className='d-flex justify-content-start'>
                                                            <label className='d-flex align-items-center gap-3'><img className='w-50px' src='/assets/img/logo.png' alt='logo'/> <span>KV Muhlheim</span></label>
                                                        </div>
                                                        <div className='d-flex justify-content-start'>
                                                        <label className='d-flex align-items-center gap-3'><span className='between'>: </span><img className='w-50px' src='/assets/img/logo.png' alt='logo'/> <span>KV Muhlheim</span></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                            <div className='d-flex align-items-center flex-column matchBetween fit-content'>
                                                    <div className='d-flex justify-content-center'>
                                                        <p>HM | 365677002</p>
                                                    </div>
                                                    <div className='d-flex justify-content-between gap-5'>
                                                        <div className='d-flex justify-content-start'>
                                                            <label className='d-flex align-items-center gap-3'>-:-</label>
                                                        </div>
                                                        <div className='d-flex justify-content-between'>
                                                            <label className='d-flex align-items-center gap-3'><span>Zum Spiel</span></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='text-end'>So, 12.01.25 | 14:14</td>
                                            <td>
                                                <div className='w-100 d-flex align-items-center m-auto flex-column matchBetween'>
                                                    <div className='d-flex justify-content-center'>
                                                        <p>C-Junioren | Hallen-Kreisturnier</p>
                                                    </div>
                                                    <div className='d-flex justify-content-btween'>
                                                        <div className='d-flex justify-content-start'>
                                                            <label className='d-flex align-items-center gap-3'><img className='w-50px' src='/assets/img/logo.png' alt='logo'/> <span>KV Muhlheim</span></label>
                                                        </div>
                                                        <div className='d-flex justify-content-start'>
                                                        <label className='d-flex align-items-center gap-3'><span className='between'>: </span><img className='w-50px' src='/assets/img/logo.png' alt='logo'/> <span>KV Muhlheim</span></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                            <div className='d-flex align-items-center flex-column matchBetween fit-content'>
                                                    <div className='d-flex justify-content-center'>
                                                        <p>HM | 365677002</p>
                                                    </div>
                                                    <div className='d-flex justify-content-between gap-5'>
                                                        <div className='d-flex justify-content-start'>
                                                            <label className='d-flex align-items-center gap-3'>-:-</label>
                                                        </div>
                                                        <div className='d-flex justify-content-between'>
                                                            <label className='d-flex align-items-center gap-3'><span>Zum Spiel</span></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='text-end'>So, 12.01.25 | 14:14</td>
                                            <td>
                                                <div className='w-100 d-flex align-items-center m-auto flex-column matchBetween'>
                                                    <div className='d-flex justify-content-center'>
                                                        <p>C-Junioren | Hallen-Kreisturnier</p>
                                                    </div>
                                                    <div className='d-flex justify-content-btween'>
                                                        <div className='d-flex justify-content-start'>
                                                            <label className='d-flex align-items-center gap-3'><img className='w-50px' src='/assets/img/logo.png' alt='logo'/> <span>KV Muhlheim</span></label>
                                                        </div>
                                                        <div className='d-flex justify-content-start'>
                                                        <label className='d-flex align-items-center gap-3'><span className='between'>: </span><img className='w-50px' src='/assets/img/logo.png' alt='logo'/> <span>KV Muhlheim</span></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                            <div className='d-flex align-items-center flex-column matchBetween fit-content'>
                                                    <div className='d-flex justify-content-center'>
                                                        <p>HM | 365677002</p>
                                                    </div>
                                                    <div className='d-flex justify-content-between gap-5'>
                                                        <div className='d-flex justify-content-start'>
                                                            <label className='d-flex align-items-center gap-3'>-:-</label>
                                                        </div>
                                                        <div className='d-flex justify-content-between'>
                                                            <label className='d-flex align-items-center gap-3'><span>Zum Spiel</span></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='text-end'>So, 12.01.25 | 14:14</td>
                                            <td>
                                                <div className='w-100 d-flex align-items-center m-auto flex-column matchBetween'>
                                                    <div className='d-flex justify-content-center'>
                                                        <p>C-Junioren | Hallen-Kreisturnier</p>
                                                    </div>
                                                    <div className='d-flex justify-content-btween'>
                                                        <div className='d-flex justify-content-start'>
                                                            <label className='d-flex align-items-center gap-3'><img className='w-50px' src='/assets/img/logo.png' alt='logo'/> <span>KV Muhlheim</span></label>
                                                        </div>
                                                        <div className='d-flex justify-content-start'>
                                                        <label className='d-flex align-items-center gap-3'><span className='between'>: </span><img className='w-50px' src='/assets/img/logo.png' alt='logo'/> <span>KV Muhlheim</span></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                            <div className='d-flex align-items-center flex-column matchBetween fit-content'>
                                                    <div className='d-flex justify-content-center'>
                                                        <p>HM | 365677002</p>
                                                    </div>
                                                    <div className='d-flex justify-content-between gap-5'>
                                                        <div className='d-flex justify-content-start'>
                                                            <label className='d-flex align-items-center gap-3'>-:-</label>
                                                        </div>
                                                        <div className='d-flex justify-content-between'>
                                                            <label className='d-flex align-items-center gap-3'><span>Zum Spiel</span></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='text-end'>So, 12.01.25 | 14:14</td>
                                            <td>
                                                <div className='w-100 d-flex align-items-center m-auto flex-column matchBetween'>
                                                    <div className='d-flex justify-content-center'>
                                                        <p>C-Junioren | Hallen-Kreisturnier</p>
                                                    </div>
                                                    <div className='d-flex justify-content-btween'>
                                                        <div className='d-flex justify-content-start'>
                                                            <label className='d-flex align-items-center gap-3'><img className='w-50px' src='/assets/img/logo.png' alt='logo'/> <span>KV Muhlheim</span></label>
                                                        </div>
                                                        <div className='d-flex justify-content-start'>
                                                        <label className='d-flex align-items-center gap-3'><span className='between'>: </span><img className='w-50px' src='/assets/img/logo.png' alt='logo'/> <span>KV Muhlheim</span></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                            <div className='d-flex align-items-center flex-column matchBetween fit-content'>
                                                    <div className='d-flex justify-content-center'>
                                                        <p>HM | 365677002</p>
                                                    </div>
                                                    <div className='d-flex justify-content-between gap-5'>
                                                        <div className='d-flex justify-content-start'>
                                                            <label className='d-flex align-items-center gap-3'>-:-</label>
                                                        </div>
                                                        <div className='d-flex justify-content-between'>
                                                            <label className='d-flex align-items-center gap-3'><span>Zum Spiel</span></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='text-end'>So, 12.01.25 | 14:14</td>
                                            <td>
                                                <div className='w-100 d-flex align-items-center m-auto flex-column matchBetween'>
                                                    <div className='d-flex justify-content-center'>
                                                        <p>C-Junioren | Hallen-Kreisturnier</p>
                                                    </div>
                                                    <div className='d-flex justify-content-btween'>
                                                        <div className='d-flex justify-content-start'>
                                                            <label className='d-flex align-items-center gap-3'><img className='w-50px' src='/assets/img/logo.png' alt='logo'/> <span>KV Muhlheim</span></label>
                                                        </div>
                                                        <div className='d-flex justify-content-start'>
                                                        <label className='d-flex align-items-center gap-3'><span className='between'>: </span><img className='w-50px' src='/assets/img/logo.png' alt='logo'/> <span>KV Muhlheim</span></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                            <div className='d-flex align-items-center flex-column matchBetween fit-content'>
                                                    <div className='d-flex justify-content-center'>
                                                        <p>HM | 365677002</p>
                                                    </div>
                                                    <div className='d-flex justify-content-between gap-5'>
                                                        <div className='d-flex justify-content-start'>
                                                            <label className='d-flex align-items-center gap-3'>-:-</label>
                                                        </div>
                                                        <div className='d-flex justify-content-between'>
                                                            <label className='d-flex align-items-center gap-3'><span>Zum Spiel</span></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                    </Table>
                                </div>
                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="second">
                            <div className='firstTabContent'>
                                <div className='table-responsive'>
                                    <Table striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th className='text-end bg-white'>Datum Zeit</th>
                                            <th className='text-center bg-white'>Wettbewerb</th>
                                            <th className='text-start bg-white'>Info</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className='text-end'>So, 12.01.25 | 14:14</td>
                                            <td>
                                                <div className='w-100 d-flex align-items-center m-auto flex-column matchBetween'>
                                                    <div className='d-flex justify-content-center'>
                                                        <p>C-Junioren | Hallen-Kreisturnier</p>
                                                    </div>
                                                    <div className='d-flex justify-content-btween'>
                                                        <div className='d-flex justify-content-start'>
                                                            <label className='d-flex align-items-center gap-3'><img className='w-50px' src='/assets/img/logo.png' alt='logo'/> <span>KV Muhlheim</span></label>
                                                        </div>
                                                        <div className='d-flex justify-content-start'>
                                                        <label className='d-flex align-items-center gap-3'><span className='between'>: </span><img className='w-50px' src='/assets/img/logo.png' alt='logo'/> <span>KV Muhlheim</span></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                            <div className='d-flex align-items-center flex-column matchBetween fit-content'>
                                                    <div className='d-flex justify-content-center'>
                                                        <p>HM | 365677002</p>
                                                    </div>
                                                    <div className='d-flex justify-content-between gap-5'>
                                                        <div className='d-flex justify-content-start'>
                                                            <label className='d-flex align-items-center gap-3'>-:-</label>
                                                        </div>
                                                        <div className='d-flex justify-content-between'>
                                                            <label className='d-flex align-items-center gap-3'><span>Zum Spiel</span></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='text-end'>So, 12.01.25 | 14:14</td>
                                            <td>
                                                <div className='w-100 d-flex align-items-center m-auto flex-column matchBetween'>
                                                    <div className='d-flex justify-content-center'>
                                                        <p>C-Junioren | Hallen-Kreisturnier</p>
                                                    </div>
                                                    <div className='d-flex justify-content-btween'>
                                                        <div className='d-flex justify-content-start'>
                                                            <label className='d-flex align-items-center gap-3'><img className='w-50px' src='/assets/img/logo.png' alt='logo'/> <span>KV Muhlheim</span></label>
                                                        </div>
                                                        <div className='d-flex justify-content-start'>
                                                        <label className='d-flex align-items-center gap-3'><span className='between'>: </span><img className='w-50px' src='/assets/img/logo.png' alt='logo'/> <span>KV Muhlheim</span></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                            <div className='d-flex align-items-center flex-column matchBetween fit-content'>
                                                    <div className='d-flex justify-content-center'>
                                                        <p>HM | 365677002</p>
                                                    </div>
                                                    <div className='d-flex justify-content-between gap-5'>
                                                        <div className='d-flex justify-content-start'>
                                                            <label className='d-flex align-items-center gap-3'>-:-</label>
                                                        </div>
                                                        <div className='d-flex justify-content-between'>
                                                            <label className='d-flex align-items-center gap-3'><span>Zum Spiel</span></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='text-end'>So, 12.01.25 | 14:14</td>
                                            <td>
                                                <div className='w-100 d-flex align-items-center m-auto flex-column matchBetween'>
                                                    <div className='d-flex justify-content-center'>
                                                        <p>C-Junioren | Hallen-Kreisturnier</p>
                                                    </div>
                                                    <div className='d-flex justify-content-btween'>
                                                        <div className='d-flex justify-content-start'>
                                                            <label className='d-flex align-items-center gap-3'><img className='w-50px' src='/assets/img/logo.png' alt='logo'/> <span>KV Muhlheim</span></label>
                                                        </div>
                                                        <div className='d-flex justify-content-start'>
                                                        <label className='d-flex align-items-center gap-3'><span className='between'>: </span><img className='w-50px' src='/assets/img/logo.png' alt='logo'/> <span>KV Muhlheim</span></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                            <div className='d-flex align-items-center flex-column matchBetween fit-content'>
                                                    <div className='d-flex justify-content-center'>
                                                        <p>HM | 365677002</p>
                                                    </div>
                                                    <div className='d-flex justify-content-between gap-5'>
                                                        <div className='d-flex justify-content-start'>
                                                            <label className='d-flex align-items-center gap-3'>-:-</label>
                                                        </div>
                                                        <div className='d-flex justify-content-between'>
                                                            <label className='d-flex align-items-center gap-3'><span>Zum Spiel</span></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='text-end'>So, 12.01.25 | 14:14</td>
                                            <td>
                                                <div className='w-100 d-flex align-items-center m-auto flex-column matchBetween'>
                                                    <div className='d-flex justify-content-center'>
                                                        <p>C-Junioren | Hallen-Kreisturnier</p>
                                                    </div>
                                                    <div className='d-flex justify-content-btween'>
                                                        <div className='d-flex justify-content-start'>
                                                            <label className='d-flex align-items-center gap-3'><img className='w-50px' src='/assets/img/logo.png' alt='logo'/> <span>KV Muhlheim</span></label>
                                                        </div>
                                                        <div className='d-flex justify-content-start'>
                                                        <label className='d-flex align-items-center gap-3'><span className='between'>: </span><img className='w-50px' src='/assets/img/logo.png' alt='logo'/> <span>KV Muhlheim</span></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                            <div className='d-flex align-items-center flex-column matchBetween fit-content'>
                                                    <div className='d-flex justify-content-center'>
                                                        <p>HM | 365677002</p>
                                                    </div>
                                                    <div className='d-flex justify-content-between gap-5'>
                                                        <div className='d-flex justify-content-start'>
                                                            <label className='d-flex align-items-center gap-3'>-:-</label>
                                                        </div>
                                                        <div className='d-flex justify-content-between'>
                                                            <label className='d-flex align-items-center gap-3'><span>Zum Spiel</span></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='text-end'>So, 12.01.25 | 14:14</td>
                                            <td>
                                                <div className='w-100 d-flex align-items-center m-auto flex-column matchBetween'>
                                                    <div className='d-flex justify-content-center'>
                                                        <p>C-Junioren | Hallen-Kreisturnier</p>
                                                    </div>
                                                    <div className='d-flex justify-content-btween'>
                                                        <div className='d-flex justify-content-start'>
                                                            <label className='d-flex align-items-center gap-3'><img className='w-50px' src='/assets/img/logo.png' alt='logo'/> <span>KV Muhlheim</span></label>
                                                        </div>
                                                        <div className='d-flex justify-content-start'>
                                                        <label className='d-flex align-items-center gap-3'><span className='between'>: </span><img className='w-50px' src='/assets/img/logo.png' alt='logo'/> <span>KV Muhlheim</span></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                            <div className='d-flex align-items-center flex-column matchBetween fit-content'>
                                                    <div className='d-flex justify-content-center'>
                                                        <p>HM | 365677002</p>
                                                    </div>
                                                    <div className='d-flex justify-content-between gap-5'>
                                                        <div className='d-flex justify-content-start'>
                                                            <label className='d-flex align-items-center gap-3'>-:-</label>
                                                        </div>
                                                        <div className='d-flex justify-content-between'>
                                                            <label className='d-flex align-items-center gap-3'><span>Zum Spiel</span></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='text-end'>So, 12.01.25 | 14:14</td>
                                            <td>
                                                <div className='w-100 d-flex align-items-center m-auto flex-column matchBetween'>
                                                    <div className='d-flex justify-content-center'>
                                                        <p>C-Junioren | Hallen-Kreisturnier</p>
                                                    </div>
                                                    <div className='d-flex justify-content-btween'>
                                                        <div className='d-flex justify-content-start'>
                                                            <label className='d-flex align-items-center gap-3'><img className='w-50px' src='/assets/img/logo.png' alt='logo'/> <span>KV Muhlheim</span></label>
                                                        </div>
                                                        <div className='d-flex justify-content-start'>
                                                        <label className='d-flex align-items-center gap-3'><span className='between'>: </span><img className='w-50px' src='/assets/img/logo.png' alt='logo'/> <span>KV Muhlheim</span></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                            <div className='d-flex align-items-center flex-column matchBetween fit-content'>
                                                    <div className='d-flex justify-content-center'>
                                                        <p>HM | 365677002</p>
                                                    </div>
                                                    <div className='d-flex justify-content-between gap-5'>
                                                        <div className='d-flex justify-content-start'>
                                                            <label className='d-flex align-items-center gap-3'>-:-</label>
                                                        </div>
                                                        <div className='d-flex justify-content-between'>
                                                            <label className='d-flex align-items-center gap-3'><span>Zum Spiel</span></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='text-end'>So, 12.01.25 | 14:14</td>
                                            <td>
                                                <div className='w-100 d-flex align-items-center m-auto flex-column matchBetween'>
                                                    <div className='d-flex justify-content-center'>
                                                        <p>C-Junioren | Hallen-Kreisturnier</p>
                                                    </div>
                                                    <div className='d-flex justify-content-btween'>
                                                        <div className='d-flex justify-content-start'>
                                                            <label className='d-flex align-items-center gap-3'><img className='w-50px' src='/assets/img/logo.png' alt='logo'/> <span>KV Muhlheim</span></label>
                                                        </div>
                                                        <div className='d-flex justify-content-start'>
                                                        <label className='d-flex align-items-center gap-3'><span className='between'>: </span><img className='w-50px' src='/assets/img/logo.png' alt='logo'/> <span>KV Muhlheim</span></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                            <div className='d-flex align-items-center flex-column matchBetween fit-content'>
                                                    <div className='d-flex justify-content-center'>
                                                        <p>HM | 365677002</p>
                                                    </div>
                                                    <div className='d-flex justify-content-between gap-5'>
                                                        <div className='d-flex justify-content-start'>
                                                            <label className='d-flex align-items-center gap-3'>-:-</label>
                                                        </div>
                                                        <div className='d-flex justify-content-between'>
                                                            <label className='d-flex align-items-center gap-3'><span>Zum Spiel</span></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='text-end'>So, 12.01.25 | 14:14</td>
                                            <td>
                                                <div className='w-100 d-flex align-items-center m-auto flex-column matchBetween'>
                                                    <div className='d-flex justify-content-center'>
                                                        <p>C-Junioren | Hallen-Kreisturnier</p>
                                                    </div>
                                                    <div className='d-flex justify-content-btween'>
                                                        <div className='d-flex justify-content-start'>
                                                            <label className='d-flex align-items-center gap-3'><img className='w-50px' src='/assets/img/logo.png' alt='logo'/> <span>KV Muhlheim</span></label>
                                                        </div>
                                                        <div className='d-flex justify-content-start'>
                                                        <label className='d-flex align-items-center gap-3'><span className='between'>: </span><img className='w-50px' src='/assets/img/logo.png' alt='logo'/> <span>KV Muhlheim</span></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                            <div className='d-flex align-items-center flex-column matchBetween fit-content'>
                                                    <div className='d-flex justify-content-center'>
                                                        <p>HM | 365677002</p>
                                                    </div>
                                                    <div className='d-flex justify-content-between gap-5'>
                                                        <div className='d-flex justify-content-start'>
                                                            <label className='d-flex align-items-center gap-3'>-:-</label>
                                                        </div>
                                                        <div className='d-flex justify-content-between'>
                                                            <label className='d-flex align-items-center gap-3'><span>Zum Spiel</span></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='text-end'>So, 12.01.25 | 14:14</td>
                                            <td>
                                                <div className='w-100 d-flex align-items-center m-auto flex-column matchBetween'>
                                                    <div className='d-flex justify-content-center'>
                                                        <p>C-Junioren | Hallen-Kreisturnier</p>
                                                    </div>
                                                    <div className='d-flex justify-content-btween'>
                                                        <div className='d-flex justify-content-start'>
                                                            <label className='d-flex align-items-center gap-3'><img className='w-50px' src='/assets/img/logo.png' alt='logo'/> <span>KV Muhlheim</span></label>
                                                        </div>
                                                        <div className='d-flex justify-content-start'>
                                                        <label className='d-flex align-items-center gap-3'><span className='between'>: </span><img className='w-50px' src='/assets/img/logo.png' alt='logo'/> <span>KV Muhlheim</span></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                            <div className='d-flex align-items-center flex-column matchBetween fit-content'>
                                                    <div className='d-flex justify-content-center'>
                                                        <p>HM | 365677002</p>
                                                    </div>
                                                    <div className='d-flex justify-content-between gap-5'>
                                                        <div className='d-flex justify-content-start'>
                                                            <label className='d-flex align-items-center gap-3'>-:-</label>
                                                        </div>
                                                        <div className='d-flex justify-content-between'>
                                                            <label className='d-flex align-items-center gap-3'><span>Zum Spiel</span></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                    </Table>
                                </div>
                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="third">
                            <div className='firstTabContent'>
                                <div className='table-responsive'>
                                    <Table striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th className='text-end bg-white'>Datum Zeit</th>
                                            <th className='text-center bg-white'>Wettbewerb</th>
                                            <th className='text-start bg-white'>Info</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className='text-end'>So, 12.01.25 | 14:14</td>
                                            <td>
                                                <div className='w-100 d-flex align-items-center m-auto flex-column matchBetween'>
                                                    <div className='d-flex justify-content-center'>
                                                        <p>C-Junioren | Hallen-Kreisturnier</p>
                                                    </div>
                                                    <div className='d-flex justify-content-btween'>
                                                        <div className='d-flex justify-content-start'>
                                                            <label className='d-flex align-items-center gap-3'><img className='w-50px' src='/assets/img/logo.png' alt='logo'/> <span>KV Muhlheim</span></label>
                                                        </div>
                                                        <div className='d-flex justify-content-start'>
                                                        <label className='d-flex align-items-center gap-3'><span className='between'>: </span><img className='w-50px' src='/assets/img/logo.png' alt='logo'/> <span>KV Muhlheim</span></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                            <div className='d-flex align-items-center flex-column matchBetween fit-content'>
                                                    <div className='d-flex justify-content-center'>
                                                        <p>HM | 365677002</p>
                                                    </div>
                                                    <div className='d-flex justify-content-between gap-5'>
                                                        <div className='d-flex justify-content-start'>
                                                            <label className='d-flex align-items-center gap-3'>-:-</label>
                                                        </div>
                                                        <div className='d-flex justify-content-between'>
                                                            <label className='d-flex align-items-center gap-3'><span>Zum Spiel</span></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='text-end'>So, 12.01.25 | 14:14</td>
                                            <td>
                                                <div className='w-100 d-flex align-items-center m-auto flex-column matchBetween'>
                                                    <div className='d-flex justify-content-center'>
                                                        <p>C-Junioren | Hallen-Kreisturnier</p>
                                                    </div>
                                                    <div className='d-flex justify-content-btween'>
                                                        <div className='d-flex justify-content-start'>
                                                            <label className='d-flex align-items-center gap-3'><img className='w-50px' src='/assets/img/logo.png' alt='logo'/> <span>KV Muhlheim</span></label>
                                                        </div>
                                                        <div className='d-flex justify-content-start'>
                                                        <label className='d-flex align-items-center gap-3'><span className='between'>: </span><img className='w-50px' src='/assets/img/logo.png' alt='logo'/> <span>KV Muhlheim</span></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                            <div className='d-flex align-items-center flex-column matchBetween fit-content'>
                                                    <div className='d-flex justify-content-center'>
                                                        <p>HM | 365677002</p>
                                                    </div>
                                                    <div className='d-flex justify-content-between gap-5'>
                                                        <div className='d-flex justify-content-start'>
                                                            <label className='d-flex align-items-center gap-3'>-:-</label>
                                                        </div>
                                                        <div className='d-flex justify-content-between'>
                                                            <label className='d-flex align-items-center gap-3'><span>Zum Spiel</span></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='text-end'>So, 12.01.25 | 14:14</td>
                                            <td>
                                                <div className='w-100 d-flex align-items-center m-auto flex-column matchBetween'>
                                                    <div className='d-flex justify-content-center'>
                                                        <p>C-Junioren | Hallen-Kreisturnier</p>
                                                    </div>
                                                    <div className='d-flex justify-content-btween'>
                                                        <div className='d-flex justify-content-start'>
                                                            <label className='d-flex align-items-center gap-3'><img className='w-50px' src='/assets/img/logo.png' alt='logo'/> <span>KV Muhlheim</span></label>
                                                        </div>
                                                        <div className='d-flex justify-content-start'>
                                                        <label className='d-flex align-items-center gap-3'><span className='between'>: </span><img className='w-50px' src='/assets/img/logo.png' alt='logo'/> <span>KV Muhlheim</span></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                            <div className='d-flex align-items-center flex-column matchBetween fit-content'>
                                                    <div className='d-flex justify-content-center'>
                                                        <p>HM | 365677002</p>
                                                    </div>
                                                    <div className='d-flex justify-content-between gap-5'>
                                                        <div className='d-flex justify-content-start'>
                                                            <label className='d-flex align-items-center gap-3'>-:-</label>
                                                        </div>
                                                        <div className='d-flex justify-content-between'>
                                                            <label className='d-flex align-items-center gap-3'><span>Zum Spiel</span></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='text-end'>So, 12.01.25 | 14:14</td>
                                            <td>
                                                <div className='w-100 d-flex align-items-center m-auto flex-column matchBetween'>
                                                    <div className='d-flex justify-content-center'>
                                                        <p>C-Junioren | Hallen-Kreisturnier</p>
                                                    </div>
                                                    <div className='d-flex justify-content-btween'>
                                                        <div className='d-flex justify-content-start'>
                                                            <label className='d-flex align-items-center gap-3'><img className='w-50px' src='/assets/img/logo.png' alt='logo'/> <span>KV Muhlheim</span></label>
                                                        </div>
                                                        <div className='d-flex justify-content-start'>
                                                        <label className='d-flex align-items-center gap-3'><span className='between'>: </span><img className='w-50px' src='/assets/img/logo.png' alt='logo'/> <span>KV Muhlheim</span></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                            <div className='d-flex align-items-center flex-column matchBetween fit-content'>
                                                    <div className='d-flex justify-content-center'>
                                                        <p>HM | 365677002</p>
                                                    </div>
                                                    <div className='d-flex justify-content-between gap-5'>
                                                        <div className='d-flex justify-content-start'>
                                                            <label className='d-flex align-items-center gap-3'>-:-</label>
                                                        </div>
                                                        <div className='d-flex justify-content-between'>
                                                            <label className='d-flex align-items-center gap-3'><span>Zum Spiel</span></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='text-end'>So, 12.01.25 | 14:14</td>
                                            <td>
                                                <div className='w-100 d-flex align-items-center m-auto flex-column matchBetween'>
                                                    <div className='d-flex justify-content-center'>
                                                        <p>C-Junioren | Hallen-Kreisturnier</p>
                                                    </div>
                                                    <div className='d-flex justify-content-btween'>
                                                        <div className='d-flex justify-content-start'>
                                                            <label className='d-flex align-items-center gap-3'><img className='w-50px' src='/assets/img/logo.png' alt='logo'/> <span>KV Muhlheim</span></label>
                                                        </div>
                                                        <div className='d-flex justify-content-start'>
                                                        <label className='d-flex align-items-center gap-3'><span className='between'>: </span><img className='w-50px' src='/assets/img/logo.png' alt='logo'/> <span>KV Muhlheim</span></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                            <div className='d-flex align-items-center flex-column matchBetween fit-content'>
                                                    <div className='d-flex justify-content-center'>
                                                        <p>HM | 365677002</p>
                                                    </div>
                                                    <div className='d-flex justify-content-between gap-5'>
                                                        <div className='d-flex justify-content-start'>
                                                            <label className='d-flex align-items-center gap-3'>-:-</label>
                                                        </div>
                                                        <div className='d-flex justify-content-between'>
                                                            <label className='d-flex align-items-center gap-3'><span>Zum Spiel</span></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='text-end'>So, 12.01.25 | 14:14</td>
                                            <td>
                                                <div className='w-100 d-flex align-items-center m-auto flex-column matchBetween'>
                                                    <div className='d-flex justify-content-center'>
                                                        <p>C-Junioren | Hallen-Kreisturnier</p>
                                                    </div>
                                                    <div className='d-flex justify-content-btween'>
                                                        <div className='d-flex justify-content-start'>
                                                            <label className='d-flex align-items-center gap-3'><img className='w-50px' src='/assets/img/logo.png' alt='logo'/> <span>KV Muhlheim</span></label>
                                                        </div>
                                                        <div className='d-flex justify-content-start'>
                                                        <label className='d-flex align-items-center gap-3'><span className='between'>: </span><img className='w-50px' src='/assets/img/logo.png' alt='logo'/> <span>KV Muhlheim</span></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                            <div className='d-flex align-items-center flex-column matchBetween fit-content'>
                                                    <div className='d-flex justify-content-center'>
                                                        <p>HM | 365677002</p>
                                                    </div>
                                                    <div className='d-flex justify-content-between gap-5'>
                                                        <div className='d-flex justify-content-start'>
                                                            <label className='d-flex align-items-center gap-3'>-:-</label>
                                                        </div>
                                                        <div className='d-flex justify-content-between'>
                                                            <label className='d-flex align-items-center gap-3'><span>Zum Spiel</span></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='text-end'>So, 12.01.25 | 14:14</td>
                                            <td>
                                                <div className='w-100 d-flex align-items-center m-auto flex-column matchBetween'>
                                                    <div className='d-flex justify-content-center'>
                                                        <p>C-Junioren | Hallen-Kreisturnier</p>
                                                    </div>
                                                    <div className='d-flex justify-content-btween'>
                                                        <div className='d-flex justify-content-start'>
                                                            <label className='d-flex align-items-center gap-3'><img className='w-50px' src='/assets/img/logo.png' alt='logo'/> <span>KV Muhlheim</span></label>
                                                        </div>
                                                        <div className='d-flex justify-content-start'>
                                                        <label className='d-flex align-items-center gap-3'><span className='between'>: </span><img className='w-50px' src='/assets/img/logo.png' alt='logo'/> <span>KV Muhlheim</span></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                            <div className='d-flex align-items-center flex-column matchBetween fit-content'>
                                                    <div className='d-flex justify-content-center'>
                                                        <p>HM | 365677002</p>
                                                    </div>
                                                    <div className='d-flex justify-content-between gap-5'>
                                                        <div className='d-flex justify-content-start'>
                                                            <label className='d-flex align-items-center gap-3'>-:-</label>
                                                        </div>
                                                        <div className='d-flex justify-content-between'>
                                                            <label className='d-flex align-items-center gap-3'><span>Zum Spiel</span></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='text-end'>So, 12.01.25 | 14:14</td>
                                            <td>
                                                <div className='w-100 d-flex align-items-center m-auto flex-column matchBetween'>
                                                    <div className='d-flex justify-content-center'>
                                                        <p>C-Junioren | Hallen-Kreisturnier</p>
                                                    </div>
                                                    <div className='d-flex justify-content-btween'>
                                                        <div className='d-flex justify-content-start'>
                                                            <label className='d-flex align-items-center gap-3'><img className='w-50px' src='/assets/img/logo.png' alt='logo'/> <span>KV Muhlheim</span></label>
                                                        </div>
                                                        <div className='d-flex justify-content-start'>
                                                        <label className='d-flex align-items-center gap-3'><span className='between'>: </span><img className='w-50px' src='/assets/img/logo.png' alt='logo'/> <span>KV Muhlheim</span></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                            <div className='d-flex align-items-center flex-column matchBetween fit-content'>
                                                    <div className='d-flex justify-content-center'>
                                                        <p>HM | 365677002</p>
                                                    </div>
                                                    <div className='d-flex justify-content-between gap-5'>
                                                        <div className='d-flex justify-content-start'>
                                                            <label className='d-flex align-items-center gap-3'>-:-</label>
                                                        </div>
                                                        <div className='d-flex justify-content-between'>
                                                            <label className='d-flex align-items-center gap-3'><span>Zum Spiel</span></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='text-end'>So, 12.01.25 | 14:14</td>
                                            <td>
                                                <div className='w-100 d-flex align-items-center m-auto flex-column matchBetween'>
                                                    <div className='d-flex justify-content-center'>
                                                        <p>C-Junioren | Hallen-Kreisturnier</p>
                                                    </div>
                                                    <div className='d-flex justify-content-btween'>
                                                        <div className='d-flex justify-content-start'>
                                                            <label className='d-flex align-items-center gap-3'><img className='w-50px' src='/assets/img/logo.png' alt='logo'/> <span>KV Muhlheim</span></label>
                                                        </div>
                                                        <div className='d-flex justify-content-start'>
                                                        <label className='d-flex align-items-center gap-3'><span className='between'>: </span><img className='w-50px' src='/assets/img/logo.png' alt='logo'/> <span>KV Muhlheim</span></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                            <div className='d-flex align-items-center flex-column matchBetween fit-content'>
                                                    <div className='d-flex justify-content-center'>
                                                        <p>HM | 365677002</p>
                                                    </div>
                                                    <div className='d-flex justify-content-between gap-5'>
                                                        <div className='d-flex justify-content-start'>
                                                            <label className='d-flex align-items-center gap-3'>-:-</label>
                                                        </div>
                                                        <div className='d-flex justify-content-between'>
                                                            <label className='d-flex align-items-center gap-3'><span>Zum Spiel</span></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                    </Table>
                                </div>
                            </div>
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
            </div>
        </div>
        <HomeFollow/>
        <HomeSubscribe/>
        <HomeFooter/>
    </div>
  )
}
