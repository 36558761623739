import React, { useEffect, useState } from 'react';
import { Card, Form, Button, Container, ListGroup } from 'react-bootstrap';
import PlusIcon from '../../../../icons/PlusIcon';
import { LANG } from '../../../../constants/language';
import VisibilityBox from '../../VisibilityBox';
import ProfileCard from '../../profile-card/page';
import { useSearchParams } from 'react-router-dom';
import { deleteTeamById, getTeamById, updateTeam } from '../../../../services/teams.service';
import FriendsBlock from '../../friendsBlock/page';
import { EditMembersModal } from '../edit-members-modal/page';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { getUserID } from '../../../../services/user.service';
import { ConfirmDeleteModal } from '../delete-modal/page';


interface Props {
    back(): void,
    update(): void,
}

const ViewTeamList = ({ back, update }: Props) => {
    const fileUrl = process.env.REACT_APP_FILE_URL;
    const [params] = useSearchParams();
    const [members, setMembers] = useState<any[]>([]);
    const [show, setShow] = useState<boolean>(false);
    const [showDelete, setShowDelete] = useState<boolean>(false);
    const [user, setUser] = useState<any>();
    const [openMember, setOpenMember] = useState<boolean>(false);
    const role= localStorage.getItem('role');
    const [team, setTeam]= useState<any>();
    const userId= getUserID();
    const viewMemberDetail = (userDetail: any) => {
        setUser(userDetail);
        setShow(true);
    }

    useEffect(() => {
        if(params.get("teamId")){
            fetchTeamMembers(params.get("teamId"));
        }
    }, [params.get("teamId")])

    const fetchTeamMembers = async (id: any) => {
        try {
            const result = await getTeamById(id);
            if (result.status == 200) {
                setMembers(result.data.data?.members);
                setTeam(result.data.data);
                update();
            }
        } catch (error) {
            console.log(error)
        }
    }

    const updateTeamSubmit= async (event:any)=>{
        try {
            const id= params.get("teamId");
            const memberIds= event?.members.map((member:any) => member?._id);
            const result= await updateTeam(id,{members:memberIds, teamName: event?.teamName|| team?.teamName});
            if(result.status == 200){
               await fetchTeamMembers(id);
               toast.success(LANG.UPDATED_SUCCESSFULLY);
            }
        } catch (error) {
            if(error instanceof AxiosError){
                toast.error(error.response?.data?.responseMessage);
            }
        }
    }

    const deleteTeam= async ()=>{
         try {
            const result = await deleteTeamById(team?._id);
            if (result.status == 200) {
                setShowDelete(false);
                update();
                toast.success(LANG.DELETED_SUCCESSFULLY);
                back();
            }
         } catch (error) {
            if(error instanceof AxiosError){
                toast.error(error.response?.data?.responseMessage);
            }
         }
    }

    return (
        <>
            <Container className="mt-5">
                {/* Breadcrumb */}
                <div className='my-2 d-flex justify-content-between'>
                   <div>
                      <span className='text-link cursor-pointer' onClick={back} >{LANG.TEAM}</span> /
                      <span className='text-primary'> {team?.teamName}</span>
                   </div>
                   <div>
                       <VisibilityBox show={userId== team?.createdBy}>
                          <button className='btn btn-primary' onClick={()=>setOpenMember(true)}>{LANG.EDIT}</button>
                          <button className='btn btn-danger mx-2' onClick={()=>setShowDelete(true)}>{LANG.DELETE}</button>
                       </VisibilityBox>
                   </div>
                </div>
                <VisibilityBox show={members.length > 0}>
                    <h4 className="mb-32px">{LANG.ALL_MEMBERS}</h4>
                    <div className="row">
                        {members?.map((item, index) => (
                                <div className="col-sm-6 col-md-4 col-lg-3 mb-3" key={index}>
                                    <FriendsBlock user={item} click={() => viewMemberDetail(item)} buttonText={LANG.VIEW_DETAILS} />
                                </div>
                            ))}
                    </div>
                </VisibilityBox>
            </Container>
            <ProfileCard show={show} user={user}
                onHide={() => setShow(false)} />
            <EditMembersModal show={openMember} team={team?.teamName} members={members} handleClose={() => setOpenMember(false)} select={updateTeamSubmit} />   
            <ConfirmDeleteModal show={showDelete} handleSubmit={deleteTeam} handleClose={()=>setShowDelete(false)} />     
        </>
    )
}

export default ViewTeamList;