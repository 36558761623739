import React from 'react'
import HomeHeader from '../home/home-header/HomeHeader';
import './style.scss';
import HomeFooter from '../home/home-footer/HomeFooter';
import HomeFollow from '../home/home-follow/HomeFollow';
import HomeSubscribe from '../home/home-subscribe/HomeSubscribe';
import { WebPage } from '../../types/webpage';
import { useSelector } from 'react-redux';
import { ApplicationPageSelector } from '../../core/data/redux/webpage/webPageAction';
import { Link } from 'react-router-dom';

const Application = () => {
    const application: WebPage = useSelector(ApplicationPageSelector);

    return (
        <div className='IMPressumPage BgLightBlue'>
            <div className='headerCover'>
                <HomeHeader />
            </div>
            <div className='container pb-md-5 my-4'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='textFull'>
                            <h1>{application?.application?.title}</h1>
                            <p>{application?.application?.text}</p>
                        </div>
                    </div>
                </div>
                <div className=''>
                    <div className="btns d-flex gap-3">
                        <Link to={application?.application?.iosLink||"#"} target="_blank"><img src={'/assets/img/ios.png'} alt='ios' /></Link>
                        <Link to={application?.application?.androidLink||"#"} target="_blank"><img src={'/assets/img/android.png'} style={{height:61}} alt='android' /></Link>
                    </div>
                </div>
            </div>
            <HomeFollow />
            <HomeSubscribe />
            <HomeFooter />
        </div>
    )
}
export default Application;