import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { all_routes } from "../router/all_routes";
import { useDispatch, useSelector } from "react-redux";
import { setCommentModalStatus, setFormDetail, setForumModalStatus } from "../../core/data/redux/shared/sharedSlice";
import { LANG } from "../../constants/language";
import PlusIcon from "../../icons/PlusIcon";
import ForumModal from "../../core/components/forum/forum-modal/page";
import { deleteForums, getAllForums, voteForums } from "../../services/forum.service";
import { deleteForumState, setForumState, setForumTotalRecords, updateForumVotes } from "../../core/data/redux/forum/forumSlice";
import VisibilityBox from "../../core/components/VisibilityBox";
import { IForum } from "../../core/data/interface/forum";
import { getUserID } from "../../services/user.service";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import ForumCard from "../../core/components/forum/forum-card/ForumCard";
import ForumCommentModal from "../../core/components/forum/comment-modal/page";
import { CommonPagination } from "../../core/components/common/CommnPagination";
import { ConfirmDeleteModal } from "../../core/components/trainer/delete-modal/page";

const ForumList = () => {
  const routes = all_routes;
  const [page, setPage] = useState<number>(1);
  const { forumModal, commentModal } = useSelector((state: any) => state.shared);
  const { forums, totalRecords } = useSelector((state: any) => state.forum);
  const dispatch = useDispatch();
  const fileUrl = process.env.REACT_APP_FILE_URL;
  const userId = getUserID();
  const [showDelete, setShowDelete]= useState<boolean>(false);
  const [selectedForum, setSelectedForum]= useState<any>();

  useEffect(() => {
    getData();
  }, [page])
  const openCreate = () => {
    dispatch(setForumModalStatus(true));
    dispatch(setFormDetail({}));
  }

  const getData = async () => {
    try {
      const result = await getAllForums({ limit: 10, page });
      if (result.status == 200) {
        dispatch(setForumState(result.data?.data));
        dispatch(setForumTotalRecords(result.data?.totalRecord))
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleToggleEdit = (forum: IForum) => {
    dispatch(setFormDetail(forum));
    dispatch(setForumModalStatus(true));
  }


  const setDeleteItem=(forum: IForum)=>{
      setShowDelete(true);
      setSelectedForum(forum._id);
  }
  const handleToggleDelete = async () => {
    try {
      const result = await deleteForums(selectedForum);
      if (result.status == 200) {
        toast.success(result?.data?.responseMessage);
        dispatch(deleteForumState(selectedForum));
        setShowDelete(false);
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        toast.error(error.response?.data?.responseMessage);
      }
    }
  }
  const handleToggleVote = async (forum: IForum) => {
    try {
      const result= await voteForums(forum._id, {});
      if (result.status == 200) {
        if(forum.votes?.includes(userId)){
          const votes = [...forum.votes]?.filter((vote: string) => vote!== userId);
          dispatch(updateForumVotes({id: forum._id, data: votes}))
        }else{
          const votes= [...forum.votes, userId];
          dispatch(updateForumVotes({id: forum._id, data: votes}))
        }
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        toast.error(error.response?.data?.responseMessage);
      }
    }
  }

  const handleToggleComment = async (forum: IForum) => {
    dispatch(setCommentModalStatus(true));
    dispatch(setFormDetail({forumId: forum._id}));
  }

  return (
    <>
      <div>
        {/* Page Content */}
        <div className="content blog-grid">
          <div className="container">
            <div className="row ">
              <div className="col-sm-12 col-md-10 col-lg-8 mx-auto d-flex justify-content-between">
                <div>
                  <h3>{LANG.FORUM}</h3>
                </div>
                <div>
                  <button className="btn btn-secondary AddIcon" onClick={openCreate}>
                    <PlusIcon />
                    {LANG.CREATE_FORUM}</button>
                </div>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-sm-12 col-md-10 col-lg-8 mx-auto">
                {/* Blog */}
                <div className="featured-venues-item">
                  <VisibilityBox show={forums?.length == 0} >
                    <h2 className="text-center p-4">
                      {LANG.NO_DATA}
                    </h2>
                  </VisibilityBox>

                  <VisibilityBox show={forums?.length}>
                    {
                      forums?.map((forum: IForum, index: number) =>
                        <ForumCard key={index} forum={forum} updateForum={handleToggleEdit} deleteForum={setDeleteItem}
                         voteForum={handleToggleVote} commentForum={handleToggleComment}
                        />
                      )
                    }
                  </VisibilityBox>

                </div>
              </div>
            </div>
            {/*Pagination*/}
            <div className="blog-pagination">
              <nav>
                <VisibilityBox show={totalRecords> 10}>
                <ul className="pagination justify-content-center pagination-center">
                  <CommonPagination totalRecords={totalRecords} onPageChange={(n)=>setPage(n)} />
                </ul>
                </VisibilityBox>
              </nav>
            </div>
          </div>
        </div>
      </div>
      {forumModal && <ForumModal />}

      {commentModal && <ForumCommentModal />}

      <ConfirmDeleteModal show={showDelete} handleClose={()=>setShowDelete(false)} handleSubmit={handleToggleDelete}/>
    </>
  );
};

export default ForumList;
