import React, { useEffect, useState } from 'react';
import { Form, Button, Modal } from 'react-bootstrap';
import { LANG } from '../../../../constants/language';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import * as Yup from "yup";
import { useFormik } from "formik";
import clsx from 'clsx';
import { updateUserById } from '../../../../services/user.service';
import { useDispatch } from 'react-redux';
import { setUserDetail } from '../../../data/redux/user/userSlice';

const eventSchema: any = Yup.object().shape({
    onVocation: Yup.bool().required(LANG.FIELD_IS_REQUIRED),
    vocationStartDate: Yup.date().required(LANG.FIELD_IS_REQUIRED),
    vocationEndDate: Yup.date().required(LANG.FIELD_IS_REQUIRED),
    vocationText: Yup.string().required(LANG.FIELD_IS_REQUIRED),
});

const VocationModal = (props: any) => {
    const dispatch = useDispatch();
    const formIntialValues: any = {
        onVocation: true,
        vocationStartDate: "",
        vocationEndDate: "",
        vocationText: ""
    };

    const vocationFormik = useFormik({
        initialValues: formIntialValues,
        validationSchema: eventSchema,
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            try {
                const result = await updateUserById(values);
                dispatch(setUserDetail(result?.data?.data));
                setSubmitting(true);
                resetForm();
                props?.onHide();
            } catch (error) {
                if (error instanceof AxiosError) {
                    toast.error(error.response?.data?.responseMessage);
                }
                setSubmitting(false);
            }
        },
    });
    console.log(props)
    return (
        <Modal
            {...props}
            show={props.show}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <span className='fs-6'>{LANG.VOCATION_DETAIL}</span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={vocationFormik.handleSubmit}>
                    <div className="row">
                        <div className="col-sm-12 col-md-12 mb-2">
                            <Form.Group>
                                <label>
                                    {LANG.DESCRIPTION}
                                </label>
                                <textarea
                                    placeholder={LANG.DESCRIPTION}
                                    className={clsx(
                                        "form-control commonInput",
                                        { "border border-danger": vocationFormik.touched.vocationText && vocationFormik.errors.vocationText },
                                    )}
                                    {...vocationFormik.getFieldProps("vocationText")}
                                />
                            </Form.Group>
                        </div>
                        <div className="col-sm-12  col-md-6 mb-5">
                            <label>
                                {LANG.VOCATION_DATE_FROM}
                            </label>
                            <Form.Group>
                                <input
                                    className={clsx(
                                        "form-control commonInput",
                                        { "border border-danger": vocationFormik.touched.vocationStartDate && vocationFormik.errors.vocationStartDate },
                                    )}
                                    type="date"
                                    {...vocationFormik.getFieldProps("vocationStartDate")}
                                    min={new Date().toISOString().split("T")[0]}
                                />
                            </Form.Group>
                            {vocationFormik.errors.vocationStartDate && vocationFormik.touched.vocationStartDate && (
                                <div className="formik-errors text-danger">{`${vocationFormik.errors.vocationStartDate}`}</div>
                            )}
                        </div>
                        <div className="col-sm-12  col-md-6 mb-5">
                            <label>
                                {LANG.VOCATION_DATE_TO}
                            </label>
                            <Form.Group>
                                <input
                                    className={clsx(
                                        "form-control commonInput",
                                        { "border border-danger": vocationFormik.touched.vocationEndDate && vocationFormik.errors.vocationEndDate },
                                    )}
                                    type="date"
                                    {...vocationFormik.getFieldProps("vocationEndDate")}
                                    min={new Date().toISOString().split("T")[0]}
                                />
                            </Form.Group>
                            {vocationFormik.errors.vocationEndDate && vocationFormik.touched.vocationEndDate && (
                                <div className="formik-errors text-danger">{`${vocationFormik.errors.vocationEndDate}`}</div>
                            )}
                        </div>

                    </div>
                    <div className='d-flex justify-content-end'>
                        <Button type='button' onClick={() => props?.onHide()} variant='outline-secondary' className='me-2' >
                            {LANG.CANCEL}
                        </Button>
                        <Button type='submit' variant="primary" className='' >
                            {LANG.UPDATE}
                        </Button>
                    </div>
                </form>
            </Modal.Body >
        </Modal >

    )
}

export default VocationModal;