import React from "react";

export default function ProfileBlackIcon() {
  return (
<svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
<mask id="mask0_338_834" style={{maskType:"alpha"}} maskUnits="userSpaceOnUse" x="0" y="0" width="33" height="33">
<rect x="0.399414" y="0.5" width="32" height="32" fill="#D9D9D9"/>
</mask>
<g mask="url(#mask0_338_834)">
<path d="M16.4001 16.5001C18.2001 16.5001 20.0778 16.3167 22.0334 15.9501C23.989 15.5834 25.6667 15.1001 27.0667 14.5001V27.8334C25.7334 28.4334 24.1112 28.9167 22.2001 29.2834C20.289 29.6501 18.3556 29.8334 16.4001 29.8334C14.4445 29.8334 12.5112 29.6501 10.6001 29.2834C8.68895 28.9167 7.06673 28.4334 5.7334 27.8334V14.5001C7.1334 15.1001 8.81118 15.5834 10.7667 15.9501C12.7223 16.3167 14.6001 16.5001 16.4001 16.5001ZM16.4001 3.16675C17.8667 3.16675 19.1223 3.68897 20.1667 4.73341C21.2112 5.77786 21.7334 7.03341 21.7334 8.50008C21.7334 9.96675 21.2112 11.2223 20.1667 12.2667C19.1223 13.3112 17.8667 13.8334 16.4001 13.8334C14.9334 13.8334 13.6778 13.3112 12.6334 12.2667C11.589 11.2223 11.0667 9.96675 11.0667 8.50008C11.0667 7.03341 11.589 5.77786 12.6334 4.73341C13.6778 3.68897 14.9334 3.16675 16.4001 3.16675Z" fill="#1A1A1A"/>
</g>
</svg>


  );
}
