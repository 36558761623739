import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { LANG } from "../../../../constants/language";
import PlusIcon from "../../../../icons/PlusIcon";
import VisibilityBox from "../../VisibilityBox";
import ProfileCard from "../../profile-card/page";
import CalendarIcon from "../../../../icons/CalendarIcon";
import { Button, Dropdown, Form, Modal, Table } from "react-bootstrap";
import CrossRedCircleIcon from "../../../../icons/CrossRedCircleIcon";
import TickBlueCircleIcon from "../../../../icons/TickBlueCircleIcon";
import { acceptEvent, getAllEventInvitations, getAllEventInvitationsWithSlots, getAllMyEvents, rejectEvent } from "../../../../services/event.service";
import VThreeDots from "../../../../icons/VThreeDots";
import moment from "moment";
import EventCalendar from "../../trainer/event-calendar/EventCalendar";
import { checkEventAccpeted, getEventStatus } from "../../../../utils";
import { AxiosError } from "axios";
import { toast } from "react-toastify";
import EventDetailModal from "../../event-detail-modal/EventDetailModal";
import RejectEventModal from "../../reject-event-modal/RejectEventModal";
import { status_translation } from "../../../../constants";

export default function MemberEventTabContent() {
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const [events, setEvents] = useState<any[]>([]);
  const userId = localStorage.getItem("id");
  const [requestEventLists, setRequestEventLists] = useState<any[]>([]);
  const [eventLists, setEventLists] = useState<any[]>([]);
  const [show, setShow] = useState<boolean>(false);
  const [user, setUser] = useState<any>();
  const [visible, setVisible] = useState(false);
  const [showReject, setShowReject] = useState<boolean>(false);

  const [showModal, setshowModal] = useState(false);
  const handleClose = () => setshowModal(false);
  const handleshowModal = () => setshowModal(true);

  const [showEvent, setShowEvent] = useState<boolean>(false);
  const [selectedEvent, setSelectedEvent] = useState<any>();


  const [showRejectModal, setshowRejectModal] = useState(false);
  const handleRejectClose = () => setshowRejectModal(false);
  const handleRejectShowModal = () => setshowRejectModal(true);

  useEffect(() => {
    getAllData();
  }, []);

  useEffect(() => {
    if(params?.get("tab")=='events')
      getAllData();
  }, [params?.get("tab")])

  useEffect(() => {
    setVisible(false);
    if (params.get("tab")) {
      setTimeout(() => {
        setVisible(true);
      }, 200);
    }
  }, [params.get("tab")]);

  const getAllData = async () => {
    try {
      const [ eventList] = await Promise.all([getAllEventInvitations({})])
      if (eventList?.status == 200 && eventList.data?.data?.length) {
        const requested = eventList.data?.data?.filter((event: any) =>
          checkEventAccpeted(event, userId) && event?.approved
        );
        const accepted = eventList.data?.data?.filter(
          (event: any) => !checkEventAccpeted(event, userId)
        );
        console.log(accepted, requested);
        setEventLists(accepted);
        setRequestEventLists(requested);
      }
      if (eventList?.status == 200 && eventList.data?.data?.length) {
        const evs: Event[] = eventList.data?.data?.map((booking: any) => ({
          title: booking?.eventType,
          date: booking?.date,
          extendedProps: booking,
        }));
        setEvents(evs);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const acceptEventRequest = async (event: any) => {
    try {
      const result = await acceptEvent(event?._id, userId);
      if (result?.status == 200) {
        toast.success(LANG.EVENT_REQUEST_ACCEPTED_SUCCESSFULLY);
        getAllData();
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        toast.error(error.response?.data?.responseMessage);
      }
    }
  };

  const rejectEventRequest = async (event: any) => {
    try {
      const result = await rejectEvent(selectedEvent?._id, userId, event?.reason);
      if (result?.status == 200) {
        setShowReject(false);
        toast.success(LANG.EVENT_REQUEST_REJECTED_SUCCESSFULLY);
        getAllData();
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        toast.error(error.response?.data?.responseMessage);
      }
    }
  };
  const viewEvent = (event: any) => {
    setSelectedEvent(event);
    setShowEvent(true);
  }

  const eventStatus= (event: any) =>{
    return getEventStatus(event, userId);
  }
  return (
    <>
      <div className="commonGrid">
        <div className="my-3">
          <div className="mt-3 calendarWrapper">
            <VisibilityBox show={visible}>
              <EventCalendar events={events} />
            </VisibilityBox>
          </div>
          <div className="tableWrapper alerttable mt-3">
            <div className="title_Counter d-flex justify-content-between mb-3">
              {/* z */}
              {/* <div className="pendingCounter">
                  Pending<span>8+</span>
                </div> */}
            </div>
            <div className="table-responsive scrollHeight">
              <Table className="table-event-request">
                <thead>
                  <tr>
                    <th>{LANG.EVENT_TYPE}</th>
                    <th>{LANG.GROUND_NAME}</th>
                    <th>{LANG.DURATION}</th>
                    <th>{LANG.DATE}</th>
                    <th>{LANG.PARTICIPANTS}</th>
                    <th>{LANG.TRAINER}</th>
                    <th>{LANG.ACTION}</th>
                  </tr>
                </thead>
                <tbody>
                  {requestEventLists.length ? (
                    <>
                      {requestEventLists.map((event: any, index: number) => (
                        <tr key={index}>
                          <td className="text-capitalize">
                            {event?.eventType}
                          </td>
                          <td>{event?.room?.roomName}</td>
                          <td>{event?.eventDuration}</td>
                          <td>
                            {moment(event?.date).format("DD MMM YYYY")}{" "}
                            {event?.time}
                          </td>
                          <td className="text-capitalize">
                            {event?.participantType}
                          </td>
                          <td>
                            {event?.createdBy?.firstName}{" "}
                            {event?.createdBy?.lastName}
                          </td>
                          <td>
                            <button
                              className="crossBtn"
                              onClick={() => {
                                setSelectedEvent(event);
                                setShowReject(true);
                              }}
                            >
                              <CrossRedCircleIcon />
                            </button>
                            <button
                              className="tickBtn"
                              onClick={() => acceptEventRequest(event)}
                            >
                              <TickBlueCircleIcon />
                            </button>
                          </td>
                        </tr>
                      ))}
                    </>
                  ) : (
                    <>
                      <p className="w-100 p-3">{LANG.EVENT_NOT_FOUNDS}</p>
                    </>
                  )}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
        <div className="tableWrapper eventstable">
          <div className="title_Counter d-flex justify-content-between mb-3">
            <h4>{LANG.EVENTS}</h4>
            {/* <div className="pendingCounter">
                  Pending<span>8+</span>
                </div> */}
          </div>
          <div className="table-responsive scrollHeight">
            <Table className="">
              <thead>
                <tr>
                  <th>{LANG.EVENT_TYPE}</th>
                  <th>{LANG.GROUND_NAME}</th>
                  <th>{LANG.DURATION}</th>
                  <th>{LANG.DATE}</th>
                  <th>{LANG.PARTICIPANTS}</th>
                  <th>{LANG.TRAINER}</th>
                  <th>{LANG.ACTION}</th>
                </tr>
              </thead>
              <tbody>
                {
                  eventLists.length ? <>
                    {
                      eventLists.map((event: any, index: number) =>

                        <tr key={index}>
                          <td className="text-capitalize">{event?.eventType}</td>
                          <td>{event?.room?.roomName}</td>
                          <td>{event?.eventDuration}</td>
                          <td>{moment(event?.date).format("DD MMM YYYY")} {event?.time}</td>
                          <td className="text-capitalize">{event?.participantType}</td>
                          <td>{event?.createdBy?.firstName} {event?.createdBy?.lastName}</td>

                          <td>
                            <div className="d-flex gap-2">
                              <span className={eventStatus(event)=='accepted' ? "event_approved text-capitalize fs-6" : "event_under_review text-capitalize fs-6"}>
                                {status_translation[eventStatus(event)]}
                              </span>
                              <Dropdown className="editDropdown">
                                <Dropdown.Toggle
                                  variant="success"
                                  id="dropdown-basic"
                                >
                                  <VThreeDots />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <Dropdown.Item
                                    onClick={() => viewEvent(event)}
                                  >
                                    {LANG.VIEW_DETAILS}
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </td>
                        </tr>
                      )
                    }
                  </> :
                    <>
                      <p className="text-center w-100 p-3">
                        {LANG.EVENT_NOT_FOUNDS}
                      </p>
                    </>
                }
              </tbody>
            </Table>
          </div>
        </div>


        <ProfileCard show={show} user={user} onHide={() => setShow(false)} />
      </div>
      <EventDetailModal show={showEvent} onHide={() => setShowEvent(false)} event={selectedEvent} />
      <Modal
        className="eventConfirmModal"
        show={showModal}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton className="border-0">
          {/* <Modal.Title>Modal title</Modal.Title> */}
        </Modal.Header>
        <Modal.Body className="py-0">
          <div className="text-center">
            <img src="/assets/img/confirmTick.png" alt='confirmTick' className="mb-3" width={80} height={80} />
            <h2>Event Attendance Confirmed</h2>
            <h3 className="mb-5">Thank you for confirming your attendance! We’re excited to see you at the event.</h3>
            <p>Details of the event have been sent to your email. If anything changes, feel free to update your RSVP.</p>
          </div>

        </Modal.Body>
        <Modal.Footer className="flex-column border-0 pt-0">
          <Button className="blueButton w-100">View Event Details</Button>
          <Button variant="default" className="defaultButton" onClick={handleClose}> Go to dashboard</Button>
        </Modal.Footer>
      </Modal>



      <Modal
        className="eventRejectModal"
        show={showRejectModal}
        onHide={handleRejectClose}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton className="border-0">
          {/* <Modal.Title>Modal title</Modal.Title> */}
        </Modal.Header>
        <Modal.Body className="py-0">
          <div className="text-center">
            <h2>Attendance Acknowledgement</h2>
            <h3 className="mb-3">We noticed you were unable to attend the event. Please provide a reason for your absence.</h3>
            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
              <Form.Control as="textarea" rows={3} placeholder="Please share your reason for not attending..." />
            </Form.Group>
            <p>If you change your mind, you can update your RSVP up to one day before the event date.</p>
          </div>

        </Modal.Body>
        <Modal.Footer className="flex-column border-0 pt-0">
          <Button className="blueButton w-100" onClick={handleRejectClose}>Submit Reason</Button>
        </Modal.Footer>
      </Modal>

      <RejectEventModal show={showReject} onHide={() => setShowReject(false)} submit={rejectEventRequest}/>
    </>
  );
}
