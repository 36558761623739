import React, { useEffect, useState } from "react";
import { Nav, Tab } from "react-bootstrap";
import "./style.scss";
import DashboardIcon from "../../../icons/DashboardIcon";
import CalendarIcon from "../../../icons/CalendarIcon";
import TeamBlackIcons from "../../../icons/TeamBlackIcons";
import FriendsBlackIcon from "../../../icons/FriendsBlackIcon";
import ProfileBlackIcon from "../../../icons/ProfileBlackIcon";
import ProfileTabContent from "../../../core/components/profile-setting/page";
import DashboardTabContent from "../../../core/components/dashboardTabContent/page";
import TeamTabContent from "../../../core/components/member/teamTabContent/page";
import FriendTabContent from "../../../core/components/member/friendTabContent/page";
import { getUserById } from "../../../services/user.service";
import { LANG } from "../../../constants/language";
import { useNavigate, useSearchParams } from "react-router-dom";
import MemberEventTabContent from "../../../core/components/member/memberEventTabContent/page";
import { setProfileReadableOnly } from "../../../core/data/redux/shared/sharedSlice";
import { useDispatch } from "react-redux";
import MemberDashboardTabContent from "../../../core/components/member/memberDashboardTab/page";
export default function MainTab() {
  const [userDetail, setUserDetail]= useState<any>();
  const [params]= useSearchParams();
  const [activeKey, setActiveKey]= useState<string>(params.get('tab')||'dashboard');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(()=>{
    getUserDetails();
  },[]);

  useEffect(()=>{
     if(params.get('tab')){
      setActiveKey(params.get('tab')||'dashboard')
     }
  },[params.get('tab')])

  const getUserDetails = async () => {
    try {
      const result = await getUserById();
      setUserDetail(result?.data?.data);
    } catch (error) {
      console.error(error);
    }
  }

  const navigateToTab=(tab:string)=>{
    navigate(`/user/user-dashboard?tab=${tab}`);
    setActiveKey(tab)
  }
  return (
    <div>
      <Tab.Container id="left-tabs-example" activeKey={activeKey} defaultActiveKey="dashboard">
        <Nav variant="pills" className="tabWrapper">
          <Nav.Item onClick={()=>navigateToTab('dashboard')}>
            <Nav.Link eventKey="dashboard">
              <DashboardIcon />
              <h5 className="mb-0">{LANG.DASHBAORD}</h5>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item onClick={()=>navigateToTab('events')}>
            <Nav.Link eventKey="events">
              <CalendarIcon />
              <h5 className="mb-0">{LANG.EVENTS}</h5>
            </Nav.Link>
          </Nav.Item> 
          <Nav.Item  onClick={()=>navigateToTab('team')}>
            <Nav.Link eventKey="team">
              <TeamBlackIcons />
              <h5 className="mb-0">Team</h5>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item  onClick={()=>navigateToTab('friends')}>
            <Nav.Link eventKey="friends">
              <FriendsBlackIcon />
              <h5 className="mb-0">{LANG.FRIENDS}</h5>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item  onClick={()=>navigateToTab('profile')}>
            <Nav.Link eventKey="profile">
              <ProfileBlackIcon />
              <h5 className="mb-0">{LANG.PROFILE}</h5>
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <Tab.Content>
          <Tab.Pane eventKey="dashboard">
            <MemberDashboardTabContent />
          </Tab.Pane>
          <Tab.Pane eventKey="events">
            <MemberEventTabContent />
          </Tab.Pane>
          <Tab.Pane eventKey="team">
            <TeamTabContent/>
          </Tab.Pane>
          <Tab.Pane eventKey="friends">
            <FriendTabContent/>
          </Tab.Pane>
          <Tab.Pane eventKey="profile">
            <ProfileTabContent userDetail={userDetail}/>
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </div>
  );
}
