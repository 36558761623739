import React from "react";

export default function CrossRedCircleIcon() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_229_9578"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="32"
        height="32"
      >
        <rect width="32" height="32" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_229_9578)">
        <path
          d="M11.2003 22.6667L16.0003 17.8667L20.8003 22.6667L22.667 20.8001L17.867 16.0001L22.667 11.2001L20.8003 9.33341L16.0003 14.1334L11.2003 9.33341L9.33366 11.2001L14.1337 16.0001L9.33366 20.8001L11.2003 22.6667ZM16.0003 29.3334C14.1559 29.3334 12.4225 28.9834 10.8003 28.2834C9.1781 27.5834 7.76699 26.6334 6.56699 25.4334C5.36699 24.2334 4.41699 22.8223 3.71699 21.2001C3.01699 19.5779 2.66699 17.8445 2.66699 16.0001C2.66699 14.1556 3.01699 12.4223 3.71699 10.8001C4.41699 9.17786 5.36699 7.76675 6.56699 6.56675C7.76699 5.36675 9.1781 4.41675 10.8003 3.71675C12.4225 3.01675 14.1559 2.66675 16.0003 2.66675C17.8448 2.66675 19.5781 3.01675 21.2003 3.71675C22.8225 4.41675 24.2337 5.36675 25.4337 6.56675C26.6337 7.76675 27.5837 9.17786 28.2837 10.8001C28.9837 12.4223 29.3337 14.1556 29.3337 16.0001C29.3337 17.8445 28.9837 19.5779 28.2837 21.2001C27.5837 22.8223 26.6337 24.2334 25.4337 25.4334C24.2337 26.6334 22.8225 27.5834 21.2003 28.2834C19.5781 28.9834 17.8448 29.3334 16.0003 29.3334ZM16.0003 26.6667C18.9781 26.6667 21.5003 25.6334 23.567 23.5667C25.6337 21.5001 26.667 18.9779 26.667 16.0001C26.667 13.0223 25.6337 10.5001 23.567 8.43341C21.5003 6.36675 18.9781 5.33341 16.0003 5.33341C13.0225 5.33341 10.5003 6.36675 8.43366 8.43341C6.36699 10.5001 5.33366 13.0223 5.33366 16.0001C5.33366 18.9779 6.36699 21.5001 8.43366 23.5667C10.5003 25.6334 13.0225 26.6667 16.0003 26.6667Z"
          fill="#C71A1D"
        />
      </g>
    </svg>
  );
}
