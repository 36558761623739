import http from "./http.service";
import { getUserID } from "./user.service";

// forum
export const getAllPlans= async (payload:any)=>{
    return http.get(`/plans/getAllPlans`,{params:payload});
}


export const createSubscriptions= async (payload:any)=>{
    return http.post(`/subscriptions`,payload);
}

export const getSubscriptionsUser= async (payload:any)=>{
    const id= getUserID();
    return http.get(`/subscriptions/user/${id}`,{params:payload});
}


export const getAllMemberPlans= async (payload:any)=>{
    return http.get(`/memberPlans/getAllMemberPlans`,{params:payload});
}


export const createMemberSubscriptions= async (payload:any)=>{
    return http.post(`/memberSubscriptions`,payload);
}

export const getMemberSubscriptionsUser= async (payload:any)=>{
    const id= getUserID();
    return http.get(`/memberSubscriptions/user/${id}`,{params:payload});
}