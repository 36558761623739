import React, { useEffect, useState } from "react";
import TeamBlock from "../../teamBlock/page";
import { useSearchParams } from "react-router-dom";
import { LANG } from "../../../../constants/language";
import { getAllUserTeams } from "../../../../services/teams.service";
import UserViewTeamList from "../user-view-team-list/page";

export default function TeamTabContent() {
  const [params] = useSearchParams();
  const [enableViewTeam, setEnableViewTeam] = useState<boolean>(false);

  const [myTeams, setMyTeams] = useState<any[]>([]);

  useEffect(() => {
    if(params.get("teamId")){
      setEnableViewTeam(true);
    }
}, [params.get("teamId")])

  useEffect(() => {
    getAllData();
  }, [])

  const getAllData = async () => {
    try {
      const teams = await getAllUserTeams({})
      setMyTeams(teams.data?.data);
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <div className="commonGrid">
      { enableViewTeam? <UserViewTeamList back={()=>setEnableViewTeam(false)}/>:
        <>
        <h4 className="mb-32px">{LANG.MY_TEAM}</h4>
            <div className="row">
              {myTeams.map((item, index) => (
                <div className="col-sm-6 col-md-4 col-lg-3 mb-3" key={index}>
                  <TeamBlock team={item} open={()=>{
                    setEnableViewTeam(true);
                  }}/>
                </div>
              ))}
            </div>
        </>
        
      }
    </div>
  );
}
