import React from 'react'
import { Link } from 'react-router-dom';
import { LANG } from '../../../constants/language';
import { WebPage } from '../../../types/webpage';
import { useSelector } from 'react-redux';
import { ApplicationPageSelector, HomePageSelector } from '../../../core/data/redux/webpage/webPageAction';
const HomeSubscribe = () => {
  const homepage: WebPage = useSelector(HomePageSelector);
  const application: WebPage = useSelector(ApplicationPageSelector);
  return (
    <section className="subscribeSection">
          <div className="container">
            <div className="row">
              <div className="col-lg-5">
                <div className="appDownload">
                  <label>{LANG.DOWNLOAD_THE_APP}:</label>
                  <div className="btns">
                    <Link to={application?.application?.iosLink||'https://www.apple.com/in/app-store/'} target="_blank"><img src={'/assets/img/ios.png'} alt='ios' /></Link>
                    <Link to={application?.application?.androidLink||'https://play.google.com/store/apps'} target="_blank"><img src={'/assets/img/android.png'} style={{height:61}} alt='android' /></Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-7">
                <div className="subscribeFormWrap">
                  <h3 className="mb-3 fw-bold">{homepage?.home?.newsletterTitle}</h3>
                  <form className="d-flex">
                    <div className='col-lg-7 col-sm-12'>
                      <p className='text-gray' style={{color:"#666666"}}>{LANG.BECOME_PART_OF_THE_CLUB_SUBTITLE}</p>
                    </div>
                   <div className='col-lg-5 col-sm-12'>
                     <Link to={"/auth/register"}>
                       <button type="button" className='w-100'>{LANG.JOIN_NOW}</button>
                     </Link>
                   </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
  )
}

export default HomeSubscribe