import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { getUserID } from '../../../../services/user.service';
import { IComment, IForum } from '../../../data/interface/forum';
import { LANG } from '../../../../constants/language';
import moment from 'moment';
import VisibilityBox from '../../VisibilityBox';
import { Dropdown } from 'react-bootstrap';
import VThreeDots from '../../../../icons/VThreeDots';
import { useDispatch } from 'react-redux';
import { setCommentModalStatus, setFormDetail } from '../../../data/redux/shared/sharedSlice';
import { AxiosError } from 'axios';
import { deleteCommentById } from '../../../../services/forum.service';
import { toast } from 'react-toastify';
import { deletForumComment } from '../../../data/redux/forum/forumSlice';

interface Props {
    forum: IForum;
    commentForum(f: IForum): void;
}

const ForumComment = ({ forum, commentForum }: Props) => {
    const fileUrl = process.env.REACT_APP_FILE_URL;
    const userId = getUserID();
    const dispatch = useDispatch();
    const [commentsToShow, setCommentsToShow] = useState<number>(5); // Number of comments to display initially

    const updateComment = (comment: IComment) => {
        dispatch(setFormDetail(comment));
        dispatch(setCommentModalStatus(true));
    };

    const deleteComment = async (comment: IComment) => {
        try {
            const result = await deleteCommentById(comment._id, {});
            dispatch(deletForumComment({ id: forum?._id, commentId: comment?._id }));
        } catch (error) {
            if (error instanceof AxiosError) {
                toast.error(error.response?.data?.responseMessage);
            }
        }
    };

    const handleShowMore = () => {
        setCommentsToShow((prev) => prev + 5); // Show 5 more comments
    };

    return (
        <div className="blog-comments">
            <div className="dull-bg">
                <div className="d-flex justify-content-between">
                    <h4 className="mb-0">{LANG.COMMENTS} {forum?.comments?.length? (forum?.comments?.length):""}</h4>
                    <button className="btn btn-primary" onClick={() => commentForum(forum)}>
                        {LANG.ADD_COMMENT}
                    </button>
                </div>
                <hr />
                <ul>
                    {forum?.comments?.slice(0, commentsToShow).map((comment: IComment, index: number) => (
                        <li key={index} className="position-relative author-group d-md-flex align-items-start justify-content-start">
                            <div className="profile-pic">
                                <img
                                    src={comment.addedBy?.avatar ? fileUrl + comment.addedBy?.avatar : '/assets/img/default-avatar.png'}
                                    className="rounded-circle"
                                    height={60}
                                    width={60}
                                    alt="Venue"
                                />
                            </div>
                            <div className="info">
                                <div className="head d-flex align-items-center justify-content-start">
                                    <h5>
                                        {comment.addedBy?.firstName} {comment.addedBy?.lastName}
                                    </h5>
                                    <i className="fa-solid fa-circle" />
                                    <span>{moment(comment.createdAt).locale("de").format('DD MMM YYYY HH:MM A')}</span>
                                </div>
                                <VisibilityBox show={comment.image !== undefined}>
                                    <div className="listing-img">
                                        <img
                                            src={fileUrl + comment?.image}
                                            className=" w-100"
                                            alt="Venue"
                                            height={100}
                                        />
                                    </div>
                                </VisibilityBox>
                                <p>{comment.message}</p>
                            </div>
                            <div className="position-absolute end-0 top-0">
                                <VisibilityBox show={userId == comment?.addedBy?._id}>
                                    <Dropdown placement="bottom-end">
                                        <Dropdown.Toggle
                                            className="p-0 drop-toggle border-0 text-dark bg-transparent"
                                            id="dropdown-basic"
                                        >
                                            <VThreeDots />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={() => updateComment(comment)}>{LANG.EDIT}</Dropdown.Item>
                                            <Dropdown.Item onClick={() => deleteComment(comment)}>{LANG.DELETE}</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </VisibilityBox>
                            </div>
                        </li>
                    ))}
                </ul>
                {forum?.comments?.length > commentsToShow && (
                    <div className='text-end'>
                        <span className="cursor-pointer" onClick={handleShowMore}>
                            {LANG.SHOW_MORE} ...
                        </span>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ForumComment;
