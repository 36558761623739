import React from 'react'
import HomeHeader from '../home/home-header/HomeHeader';
import './style.scss';
import HomeFooter from '../home/home-footer/HomeFooter';
import HomeFollow from '../home/home-follow/HomeFollow';
import HomeSubscribe from '../home/home-subscribe/HomeSubscribe';
import { Link } from 'react-router-dom';
import { FormGroup } from 'react-bootstrap';
import { WebPage } from '../../types/webpage';
import { useSelector } from 'react-redux';
import { DownloadPageSelector } from '../../core/data/redux/webpage/webPageAction';
import { LANG } from '../../constants/language';

const Downloads = () => {
    const downloads: WebPage = useSelector(DownloadPageSelector);

    return (
        <div className='IMPressumPage BgLightBlue'>
            <div className='headerCover'>
                <HomeHeader />
            </div>
            <div className='container pb-md-5'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='textFull'>
                            <h1>{downloads?.downloads?.title}</h1>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    {
                        downloads?.downloads?.items?.map((item, index: number) => (
                            <div className='col-md-4 col-sm-12' key={index}>
                                <div className='passBlock'>
                                    <FormGroup className='mb-3' >
                                        <h4 className='text-wrap'>{item.name}</h4>
                                        <div className='w-100'>
                                        <Link to={item?.fileUrl || "#"} relative={'path'} target='_blank' className='text-wrap text-break'>{LANG.PDF_DOWNLOAD}</Link>
                                        </div>
                                    </FormGroup>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
            <HomeFollow />
            <HomeSubscribe />
            <HomeFooter />
        </div>
    )
}
export default Downloads;