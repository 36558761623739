import React from "react";

const GroupFriendsIcon = () => {
  return (
    <svg
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="56" height="56" rx="8" fill="#E8E8E8" />
      <mask
        id="mask0_146_2094"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="12"
        y="12"
        width="32"
        height="32"
      >
        <rect x="12" y="12" width="32" height="32" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_146_2094)">
        <path
          d="M13.334 38.6663V34.933C13.334 34.1775 13.5284 33.483 13.9173 32.8497C14.3062 32.2163 14.8229 31.733 15.4673 31.3997C16.8451 30.7108 18.2451 30.1941 19.6673 29.8497C21.0895 29.5052 22.534 29.333 24.0007 29.333C25.4673 29.333 26.9118 29.5052 28.334 29.8497C29.7562 30.1941 31.1562 30.7108 32.534 31.3997C33.1784 31.733 33.6951 32.2163 34.084 32.8497C34.4729 33.483 34.6673 34.1775 34.6673 34.933V38.6663H13.334ZM37.334 38.6663V34.6663C37.334 33.6886 37.0618 32.7497 36.5173 31.8497C35.9729 30.9497 35.2007 30.1775 34.2007 29.533C35.334 29.6663 36.4007 29.8941 37.4007 30.2163C38.4007 30.5386 39.334 30.933 40.2007 31.3997C41.0007 31.8441 41.6118 32.3386 42.034 32.883C42.4562 33.4275 42.6673 34.0219 42.6673 34.6663V38.6663H37.334ZM24.0007 27.9997C22.534 27.9997 21.2784 27.4775 20.234 26.433C19.1895 25.3886 18.6673 24.133 18.6673 22.6663C18.6673 21.1997 19.1895 19.9441 20.234 18.8997C21.2784 17.8552 22.534 17.333 24.0007 17.333C25.4673 17.333 26.7229 17.8552 27.7673 18.8997C28.8118 19.9441 29.334 21.1997 29.334 22.6663C29.334 24.133 28.8118 25.3886 27.7673 26.433C26.7229 27.4775 25.4673 27.9997 24.0007 27.9997ZM37.334 22.6663C37.334 24.133 36.8118 25.3886 35.7673 26.433C34.7229 27.4775 33.4673 27.9997 32.0007 27.9997C31.7562 27.9997 31.4451 27.9719 31.0673 27.9163C30.6895 27.8608 30.3784 27.7997 30.134 27.733C30.734 27.0219 31.1951 26.233 31.5173 25.3663C31.8395 24.4997 32.0007 23.5997 32.0007 22.6663C32.0007 21.733 31.8395 20.833 31.5173 19.9663C31.1951 19.0997 30.734 18.3108 30.134 17.5997C30.4451 17.4886 30.7562 17.4163 31.0673 17.383C31.3784 17.3497 31.6895 17.333 32.0007 17.333C33.4673 17.333 34.7229 17.8552 35.7673 18.8997C36.8118 19.9441 37.334 21.1997 37.334 22.6663Z"
          fill="#1A1A1A"
        />
      </g>
    </svg>
  );
};

export default GroupFriendsIcon;
