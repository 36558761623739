import React, { useEffect, useState } from "react";
import { LANG } from "../../constants/language";
import { Dropdown, Form } from "react-bootstrap";
import EditIcon from "../../icons/EditIcon";
import GroupUsersIcon from "../../icons/GroupUsersIcon";
import DoubleUserIcon from "../../icons/DoubleUserIcon";
import "./style/style.scss"
import MainTab from "./mainTab/page";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { getAssignedTrainer, updateUserById } from "../../services/user.service";
import { setUserDetail } from "../../core/data/redux/user/userSlice";
import { useNavigate } from "react-router-dom";
import { getMyFriends } from "../../services/friends.service";
import { getAllTeamMembers } from "../../services/teams.service";
import VisibilityBox from "../../core/components/VisibilityBox";
import PlusIcon from "../../icons/PlusIcon";
import VocationModal from "../../core/components/member/vocation-modal/page";

const UserDashboard = () => {
  const user = useSelector((state: any) => state.user?.userDetail);
  const [friends, setFriends] = useState<any[]>([]);
  const [myTrainers, setMyTrainers] = useState<any[]>([]);
  const [teamMember, setTeamMember] = useState<number>(0)
  const fileUrl = process.env.REACT_APP_FILE_URL;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [show, setShow]= useState<boolean>(false);

  useEffect(() => {
    getFriends();
  }, []);

  const updateUser = async (values: any) => {
    const result = await updateUserById(values);
    dispatch(setUserDetail(result?.data?.data));
  }

  const navigateToProfile = () => {
    navigate('/user/user-dashboard?tab=profile&tabKey=accountSetting');
    setTimeout(() => {
      document.getElementById('tabs-layout')?.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }, 400)
  }

  const getFriends = async () => {
    try {
      const [result, teamMembersData, assignedTrainers] = await Promise.all([getMyFriends({}), getAllTeamMembers({}), getAssignedTrainer()]);
      if (result.status == 200) {
        setFriends(result?.data?.data);
      }
      if (teamMembersData.status == 200) {
        setTeamMember(teamMembersData.data.data?.length);
      }
      if (assignedTrainers?.data) {
        setMyTrainers(assignedTrainers?.data);
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div>
      <div className="memeberDashboard">
        <div className="banner">
          <img src={"/assets/img/bannerTop.jpg"} alt="bannerTop" className="w-100 h-auto" />
        </div>
        <div className="container">
          <div className="profileBlock d-flex align-items-start">
            <div className="memberImage">
              <img src={user?.avatar ? fileUrl + user?.avatar : "/assets/img/default-avatar.png"} alt="memberImg" />
            </div>
            <div className="memberInfo">
              <div className="name_private  d-flex">
                <h3>{user?.firstName} {user?.lastName}</h3>
                <div className="checkboxWrap d-flex">
                  <Dropdown>
                    <Dropdown.Toggle variant="success" className="text-capitalize" id="dropdown-basic">{user?.visibility == 'public' ? LANG.PUBLIC : LANG.PRIVATE}</Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Form.Check type="switch" id="custom-switch" checked={user?.visibility == 'private'}
                        onChange={(event) => {
                          if (event.target.checked) {
                            updateUser({ visibility: 'private' })
                          } else {
                            updateUser({ visibility: 'public' })
                          }
                        }} />
                    </Dropdown.Menu>
                  </Dropdown>
                  <Dropdown>
                    <Dropdown.Toggle variant="success" id="dropdown-basic">Urlaub</Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Form.Check type="switch" checked={user?.onVocation} id="custom-switch"
                        onChange={(event) => {
                          if (event.target.checked) {
                            console.log("object")
                            setShow(true);
                          } else {
                            updateUser({ onVocation: false, vocationStartDate:"", vocationEndDate:"", vocationText:"" })
                          }
                        }} />
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              {user?.description && <p>{user?.description}</p>}
              <ul className="memberDetail">
                <li>{LANG.MEMBER_ID} : <label>{user?._id}</label></li>
                <li>{LANG.SEASON} : <label>2024 / 2025</label></li>
                <li>{LANG.JOINED} : <label>{moment(user?.joinedAt).format("DD MMM YYYY")}</label></li>
                <li>{LANG.ASSIGNED_TRAINER} : <label>
                  {myTrainers?.length ? <>
                    <span className="text-capitalize">{myTrainers[0]?.firstName} {myTrainers[0]?.lastName}</span>
                    <VisibilityBox show={myTrainers?.length > 1}>
                        <span className="fw-bold">
                          <PlusIcon />
                          {myTrainers?.slice(1).length}
                        </span>
                    </VisibilityBox>
                  </> : "--"}
                </label></li>
                <li>{LANG.CONTACT} : <label>{user?.phone}</label></li>
                <li>Email : <label>{user?.email}</label></li>
                <li>{LANG.ADDRESS} : <label>{user?.street} {user?.houseNumber} {user?.zipcode} {user?.city} {user?.birthPlaceCity} {user?.birthPlaceCountry} {user?.nationality}</label> </li>
              </ul>
            </div>
            <button className="eidtProfileBtn" onClick={navigateToProfile}>{LANG.EDIT_PROFILE} <EditIcon /></button>
          </div>
          <div className="threeBlocks">
            <div className="row">
              {/* <div className="col-md-4">
              <div className="blockWrapper d-flex justify-content-between">
                <div className="">
                  <h4>11M 14D</h4>
                  <span>Membership expires</span>
                </div>
                <MembershipIcon/>
              </div>
            </div>*/}
              <div className="col-md-4">
                <div className="blockWrapper d-flex justify-content-between">
                  <div className="">
                    <h4>{teamMember}</h4>
                    <span>{LANG.TOTAL_TEAM_MEMBER}</span>
                  </div>
                  <GroupUsersIcon />
                </div>
              </div>
              <div className="col-md-4 my-1">
                <div className="blockWrapper d-flex justify-content-between">
                  <div className="">
                    <h4>{friends?.length}</h4>
                    <span>{LANG.FRIENDS}</span>
                  </div>
                  <DoubleUserIcon />
                </div>
              </div>
            </div>
          </div>
          <hr className="divider"></hr>
          <div className="tabOuter" id="tabs-layout">
            <MainTab />
          </div>
        </div>
      </div>


      <VocationModal show={show} onHide={()=>setShow(false)} />

    </div>
  );
};

export default UserDashboard;
