import React from 'react'
import CalendarIcon from '../../../icons/CalendarIcon'
import TaskIcon from '../../../icons/TaskIcon';
import TeamBlackIcons from '../../../icons/TeamBlackIcons';
import FriendsBlackIcon from '../../../icons/FriendsBlackIcon';
import SubscriptionIcon from '../../../icons/SubscriptionIcon';
import { FaComment } from 'react-icons/fa';
const NotificationIcon = ({ iconType }: any) => {
    const icon = (type: any) => {
        switch (type) {
            case 'event':
                return <CalendarIcon />;
            case 'task':
                return <TaskIcon />;
            case 'team':
                return <TeamBlackIcons />;
            case 'friend':
                return <FriendsBlackIcon />;
            case 'subscription':
                return <SubscriptionIcon />;
            case 'forum':
                return <FaComment />;
            default:
                return <CalendarIcon />;
        }
    }
    return (
        <> {icon(iconType)} </>
    )
}

export default NotificationIcon