import React, { useRef } from 'react'
import SignaturePad from 'react-signature-canvas';
import VisibilityBox from '../../VisibilityBox';
import "./style.scss";

interface Props {
  value: string,
  onChange(v: any): void,
}
const Signature = ({ value, onChange }: Props) => {
    const sigCanvas: any = useRef({});
  
    const clear = () => {
      onChange(null);
      setTimeout(() => {
        sigCanvas.current.clear();
      }, 100)
    };
    const edit = () => {
      const url= value;
      onChange(null);
      setTimeout(() => {
        sigCanvas.current.fromDataURL(url);
      }, 100)
    };
  
    const save = () => {
      onChange(sigCanvas.current.getTrimmedCanvas().toDataURL("image/png") );
    }
   const onTouched=()=>{
    // touched
   }
  return (
    <div onClick={onTouched}>
      {value
        ? <div className="sigContainer">
          <img className={'sigImage'}
            src={value} />
        </div>
        : <SignaturePad
          clearOnResize={false}
          ref={sigCanvas}
          canvasProps={{
            className: "signatureCanvas"
          }}
        />}
      {/* Button to trigger save canvas image */}
      <VisibilityBox show={value == null}>
        <span className="p-2 cursor-pointer" onClick={save}>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-check-lg" viewBox="0 0 16 16">
            <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
          </svg>
        </span>
      </VisibilityBox>
      <VisibilityBox show={value !== null}>
        <span className="p-2 cursor-pointer" onClick={edit}>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil" viewBox="0 0 16 16">
            <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325" />
          </svg>
        </span>
      </VisibilityBox>
      <span className="p-2 cursor-pointer" onClick={clear}>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
          <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
        </svg>
      </span>
    </div>
  )
}

export default Signature