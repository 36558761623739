import React from "react";

export default function InstagramBlueIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="37.084"
      height="37.075"
      viewBox="0 0 37.084 37.075"
    >
      <path
        id="FontAwsome_instagram_"
        data-name="FontAwsome (instagram)"
        d="M18.471,40.857a9.506,9.506,0,1,0,9.506,9.506A9.491,9.491,0,0,0,18.471,40.857Zm0,15.685a6.18,6.18,0,1,1,6.18-6.18,6.191,6.191,0,0,1-6.18,6.18ZM30.583,40.468a2.217,2.217,0,1,1-2.217-2.217A2.212,2.212,0,0,1,30.583,40.468Zm6.3,2.25c-.141-2.97-.819-5.6-2.995-7.768s-4.8-2.846-7.768-2.995c-3.061-.174-12.236-.174-15.3,0-2.962.141-5.593.819-7.768,2.987S.2,39.74.055,42.71c-.174,3.061-.174,12.236,0,15.3.141,2.97.819,5.6,2.995,7.768s4.8,2.846,7.768,2.995c3.061.174,12.236.174,15.3,0,2.97-.141,5.6-.819,7.768-2.995s2.846-4.8,2.995-7.768c.174-3.061.174-12.227,0-15.288ZM32.924,61.291A6.257,6.257,0,0,1,29.4,64.815c-2.441.968-8.232.745-10.929.745s-8.5.215-10.929-.745a6.257,6.257,0,0,1-3.524-3.524c-.968-2.44-.745-8.232-.745-10.928s-.215-8.5.745-10.928A6.257,6.257,0,0,1,7.542,35.91c2.441-.968,8.232-.745,10.929-.745s8.5-.215,10.929.745a6.257,6.257,0,0,1,3.524,3.524c.968,2.441.745,8.232.745,10.928S33.892,58.859,32.924,61.291Z"
        transform="translate(0.075 -31.825)"
        fill="#0062a7"
      />
    </svg>
  );
}
