import React, { useEffect, useState } from "react";
import TeamBlock from "../../teamBlock/page";
import FriendsBlock from "../../friendsBlock/page";
import { useNavigate, useSearchParams } from "react-router-dom";
import TeamBlackIcons from "../../../../icons/TeamBlackIcons";
import { LANG } from "../../../../constants/language";
import PlusIcon from "../../../../icons/PlusIcon";
import CreateNewTeam from "../create-new-team/page";
import {
  getAllTeamMembers,
  getAllTeams,
} from "../../../../services/teams.service";
import VisibilityBox from "../../VisibilityBox";
import ProfileCard from "../../profile-card/page";
import ViewTeamList from "../view-team-list/page";
import { useDispatch, useSelector } from "react-redux";
import { setTeamMembers, setTeams } from "../../../data/redux/teams/teamSlice";

export default function TrainerTeamTabContent() {
  const [params, setParams] = useSearchParams();
  const navigate = useNavigate();
  const [enableAdd, setEnableAdd] = useState<boolean>(false);
  const [enableViewTeam, setEnableViewTeam] = useState<boolean>(false);

  const myTeams = useSelector((state: any) => state?.team?.teams);
  const myTeamMembers = useSelector((state: any) => state?.team?.teamMembers);

  const dispatch = useDispatch();

  const [show, setShow] = useState<boolean>(false);
  const [user, setUser] = useState<any>();

  useEffect(() => {
    if (params.get("teamId")) {
      setEnableViewTeam(true);
      setEnableAdd(false);
    } else {
      setEnableViewTeam(false);
    }
  }, [params.get("teamId")]);

  useEffect(() => {
    if (params.get("create") && params.get('tab')=='team') {
      setTimeout(() => {
        setEnableAdd(true);
      }, 200);
    }
  }, [params.get("create")]);

  useEffect(() => {
    getAllData();
  }, []);

  const getAllData = async () => {
    try {
      const [members] = await Promise.all([getAllTeamMembers({})]);
      dispatch(setTeamMembers(members?.data?.data));
    } catch (error) {
      console.log(error);
    }
  };
  const viewMemberDetail = (userDetail: any) => {
    setUser(userDetail);
    setShow(true);
  };

  const createdTeam = async () => {
    await getAllData();
    const result = await getAllTeams({ limit: 100, page: 1 });
    dispatch(setTeams(result?.data?.data || []));
  };

  const viewTeam = async (teamId: string) => {
    navigate(`/trainer/trainer-dashboard?tab=team&teamId=${teamId}`);
    setEnableViewTeam(true);
  };

  return (
    <div className="commonGrid">
      {enableAdd ? (
        <>
          <CreateNewTeam
            back={() => {
              setEnableAdd(false)
              setParams((pre) => {
                pre.delete("create");
                return pre;
              });
            }}
            create={createdTeam}
          />
        </>
      ) : enableViewTeam ? (
        <ViewTeamList
          back={() => {
            setEnableViewTeam(false);
            setParams((pre) => {
              pre.delete("create");
              return pre;
            });
          }}
          update={createdTeam}
        />
      ) : (
        <>
          <div className="my-3">
            <div className="card col-md-4 col-sm-12">
              <div className="mb-3">
                <span className="bg-blue-200 p-2">
                  <TeamBlackIcons />
                </span>{" "}
                {myTeamMembers?.length} {LANG.TOTAL_TEAM_MEMBER}
              </div>
              <button
                className="btn btn-primary blueButton  w-100"
                onClick={() => {
                  setEnableAdd(true);
                  setParams((pre) => {
                    pre.set("create", "true");
                    return pre;
                  });
                }}
              >
                <PlusIcon />
                {LANG.CREATE_NEW_TEAM}
              </button>
            </div>
          </div>
          <VisibilityBox show={myTeams.length > 0}>
            <h4 className="mb-32px">{LANG.MY_TEAM}</h4>
            <div className="row">
              {myTeams.map((item: any, index: any) => (
                <div className="col-sm-6 col-md-4 col-lg-3 mb-3" key={index}>
                  <TeamBlock
                    team={item}
                    open={() => {
                      setEnableAdd(false);
                      setEnableViewTeam(true);
                    }}
                  />
                </div>
              ))}
            </div>
          </VisibilityBox>
          <VisibilityBox show={myTeamMembers.length > 0}>
            <h4 className="mb-32px">{LANG.ALL_MEMBERS}</h4>
            <div className="row">
              {myTeamMembers.map((item: any, index: number) => (
                <div className="col-sm-6 col-md-4 col-lg-3 mb-3" key={index}>
                  <FriendsBlock
                    user={item}
                    click={() => viewMemberDetail(item)}
                    buttonText={LANG.VIEW_DETAILS}
                  />
                </div>
              ))}
            </div>
          </VisibilityBox>
        </>
      )}

      <ProfileCard show={show} user={user} onHide={() => setShow(false)} />
    </div>
  );
}
