import React from 'react';
import './style.scss';
import ThankYou from '../../../home/thank-you/ThankYou';
import HomeSubscribe from '../../../home/home-subscribe/HomeSubscribe';
import HomeFollow from '../../../home/home-follow/HomeFollow';
import { useSelector } from 'react-redux';
import { AnsprechpartnerPageSelector, VorstandPageSelector } from '../../../../core/data/redux/webpage/webPageAction'
import { WebPage } from '../../../../types/webpage';


const Vorstand = () => {
    const vorstandPage: WebPage = useSelector(VorstandPageSelector);
    const ansprechpartner: WebPage = useSelector(AnsprechpartnerPageSelector);
    return (
        <div className='vorstandPage BgLightBlue'>
            {/* <HomeHeader/> */}
            <div className='container'>
                <div className='row'>
                    <div className='col-sm-12 text-center my-5 '>
                        <h2>{vorstandPage?.vorstand?.title}</h2>
                    </div>
                </div>
                <div className='row'>
                    {vorstandPage?.vorstand?.boardMembers?.map((item)=>(
                    <div className='col-md-4 col-sm-6 px-lg-5 mb-5' key={item?._id}>
                        <div className='BlockWrap text-center d-flex flex-column align-items-center gap-3'>
                            <div className='imgWrap'>
                                <img src={item?.image} alt='Vorstand'  onError={(e) => { 
                                    e.currentTarget.src = '/assets/img/default-avatar.png'; 
                                  }} />
                            </div>
                            <h3>{item.firstName} {item.lastName}</h3>
                            <p>{item.jobRole}</p>
                        </div>
                    </div>
                    ))}
                </div>
                <div className='row'>
                    <div className='col-sm-12 text-center my-5 '>
                        <h2>Funktionäre</h2>
                    </div>
                </div>
                <div className='row'>
                    {ansprechpartner?.ansprechpartner?.contactPersons?.map((item)=>(
                    <div className='col-md-4 col-sm-6 px-lg-5 mb-5' key={item?._id}>
                        <div className='BlockWrap text-center d-flex flex-column align-items-center gap-3'>
                            <div className='imgWrap'>
                                <img src={item?.image}
                                 alt='Vorstand'
                                 onError={(e) => { 
                                    e.currentTarget.src = '/assets/img/default-avatar.png'; 
                                  }} />
                            </div>
                            <h3>{item.firstName} {item.lastName}</h3>
                            <p>{item.jobRole}</p>
                        </div>
                    </div>
                    ))}
                </div>
            </div>
            <ThankYou/>
            <HomeFollow/>
            <HomeSubscribe/>
        </div>
    )
}

export default Vorstand