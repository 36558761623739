import React, { useEffect, useState } from 'react';
import { Card, Form, Button, Container, ListGroup } from 'react-bootstrap';
import { LANG } from '../../../../constants/language';
import VisibilityBox from '../../VisibilityBox';
import ProfileCard from '../../profile-card/page';
import { useSearchParams } from 'react-router-dom';
import { getTeamById, updateTeam } from '../../../../services/teams.service';
import FriendsBlock from '../../friendsBlock/page';
import { EditMembersModal } from '../../trainer/edit-members-modal/page';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { getAllSendedFriendRequests, getMyFriends, sendFriend } from '../../../../services/friends.service';
import { getUserID } from '../../../../services/user.service';
import clsx from 'clsx';


interface Props {
    back(): void,
}

const UserViewTeamList = ({ back }: Props) => {
    const fileUrl = process.env.REACT_APP_FILE_URL;
    const [params] = useSearchParams();
    const [members, setMembers] = useState<any[]>([]);
    const [friends, setFriends] = useState<any[]>([]);
    const [teamName, setTeamName] = useState<string>("");
    const [show, setShow] = useState<boolean>(false);
    const [user, setUser] = useState<any>();

    useEffect(() => {
        fetchTeamMembers(params.get("teamId"));
    }, [params.get("teamId")])

    const fetchTeamMembers = async (id: any) => {
        try {
            const [result, friendsData] = await Promise.all([getTeamById(id), getAllSendedFriendRequests({})]);
            if (result.status == 200) {
                setMembers(result.data.data?.members);
                setTeamName(result.data.data?.teamName);
            }
            if (friendsData.status == 200) {
                setFriends(friendsData.data?.data);
            }
        } catch (error) {
            console.log(error)
        }
    }

    const sendFriendRequest = async (user: any) => {
        try {
            if (checkHasFriend(user?._id)) return;

            const senderId = getUserID();
            const result = await sendFriend({ senderId, userId: user?._id });
            if (result.status == 200) {
                toast.success('Freundschaftsanfrage erfolgreich gesendet')
                await fetchTeamMembers(params.get("teamId"));
            } else if (result.status == 400) {
                toast.error('Es wurde bereits eine Freundschaftsanfrage gesendet.')
            }
        } catch (error) {
            if (error instanceof AxiosError) {
                toast.error(error.response?.data?.responseMessage);
            }
        }
    }

    const checkHasFriend = (id: any) => {
        return friends?.some((f: any) => f?.userId== id);
    }

    return (
        <>
            <Container className="mt-5">
                {/* Breadcrumb */}
                <div className='my-2 d-flex justify-content-between'>
                    <div>
                        <span className='text-link cursor-pointer' onClick={back} >{LANG.TEAM}</span> /
                        <span className='text-primary'> {teamName}</span>
                    </div>
                    <div>
                    </div>
                </div>
                <VisibilityBox show={members.length > 0}>
                    <h4 className="mb-32px">{LANG.ALL_MEMBERS}</h4>
                    <div className="row">
                        {members
                            .map((item, index) =>
                                <div className={clsx("col-sm-6 col-md-4 col-lg-3 mb-3")} key={index}>
                                    <FriendsBlock user={item} click={() => sendFriendRequest(item)} buttonText={checkHasFriend(item?._id) ? LANG.REQUEST_SENDED : LANG.SEND_FRIEND_REQUESTS} />
                                </div>
                            )}
                    </div>
                </VisibilityBox>
            </Container>
        </>
    )
}

export default UserViewTeamList;