import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { LANG } from '../../../constants/language'
import "./style.scss";
import { toast } from 'react-toastify'

const RejectEventModal = (props: any) => {

    const [reason, setReason]= useState<any>("");

    const handleSubmit=async()=>{
        // submit form
        if(!reason?.trim()){
            return toast.error(LANG.FIELD_IS_REQUIRED)
        }
        await props.submit({reason});
    }

    return (
        <Modal
            {...props}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className='eventDetailModal'
        >
            <Modal.Header closeButton className=' border-0 pb-0'>
                <Modal.Title id="contained-modal-title-vcenter">
                    {LANG.REJECT}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className='p-3'>
                <div className='row mb-2'>
                    <div className='col-12 mb-2'>
                        <span className='fw-bold'>{LANG.REASON}</span>
                    </div>
                    <div className='col-12'>
                        <textarea placeholder={LANG.ENTER_YOUR_MSG_HERE} className='form-control'
                         value={reason} onChange={(e)=>setReason(e.target.value)}/>
                    </div>
                </div>
                <div className='row mb-2'>
                
                    <div className='mt-3 d-flex gap-2'>
                        <button className='blueButton' onClick={handleSubmit} disabled={!reason} >
                            {LANG.SUBMIT}
                        </button>
                        <button className='btn btn-danger' onClick={props.onHide}>
                            {LANG.CANCEL}
                        </button>
                    </div>
                </div>


            </Modal.Body>
        </Modal>
    )
}

export default RejectEventModal;