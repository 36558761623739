import React from "react";

export default function YouTubeBlueIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="52.73"
      height="37.075"
      viewBox="0 0 52.73 37.075"
    >
      <path
        id="FontAwsome_youtube_"
        data-name="FontAwsome (youtube)"
        d="M66.561,69.8A6.625,6.625,0,0,0,61.9,65.109C57.787,64,41.3,64,41.3,64s-16.489,0-20.6,1.109A6.626,6.626,0,0,0,16.035,69.8c-1.1,4.139-1.1,12.774-1.1,12.774s0,8.635,1.1,12.774A6.527,6.527,0,0,0,20.7,99.966c4.112,1.109,20.6,1.109,20.6,1.109s16.489,0,20.6-1.109a6.527,6.527,0,0,0,4.662-4.617c1.1-4.139,1.1-12.774,1.1-12.774S67.663,73.94,66.561,69.8ZM35.9,90.415V74.735l13.782,7.84L35.9,90.415Z"
        transform="translate(-14.933 -64)"
        fill="#0062a7"
      />
    </svg>
  );
}
