import React from 'react'

const ActiveSponsorIcon = () => {
    return (
        <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_902_3189" style={{maskType:'alpha'}} maskUnits="userSpaceOnUse" x="0" y="0" width="33" height="33">
                <rect x="0.799927" y="0.5" width="32" height="32" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_902_3189)">
                <path d="M26.1333 17.8333H23.4666C23.0888 17.8333 22.7721 17.7055 22.5166 17.45C22.261 17.1944 22.1333 16.8778 22.1333 16.5C22.1333 16.1222 22.261 15.8055 22.5166 15.55C22.7721 15.2944 23.0888 15.1666 23.4666 15.1666H26.1333C26.511 15.1666 26.8277 15.2944 27.0833 15.55C27.3388 15.8055 27.4666 16.1222 27.4666 16.5C27.4666 16.8778 27.3388 17.1944 27.0833 17.45C26.8277 17.7055 26.511 17.8333 26.1333 17.8333ZM20.2666 22.9C20.4888 22.5889 20.7777 22.4111 21.1333 22.3666C21.4888 22.3222 21.8221 22.4111 22.1333 22.6333L24.2666 24.2333C24.5777 24.4555 24.7555 24.7444 24.7999 25.1C24.8444 25.4555 24.7555 25.7889 24.5333 26.1C24.311 26.4111 24.0221 26.5889 23.6666 26.6333C23.311 26.6778 22.9777 26.5889 22.6666 26.3666L20.5333 24.7666C20.2221 24.5444 20.0444 24.2555 19.9999 23.9C19.9555 23.5444 20.0444 23.2111 20.2666 22.9ZM24.2666 8.76664L22.1333 10.3666C21.8221 10.5889 21.4888 10.6778 21.1333 10.6333C20.7777 10.5889 20.4888 10.4111 20.2666 10.1C20.0444 9.78886 19.9555 9.45553 19.9999 9.09997C20.0444 8.74442 20.2221 8.45553 20.5333 8.23331L22.6666 6.63331C22.9777 6.41109 23.311 6.3222 23.6666 6.36664C24.0221 6.41109 24.311 6.58886 24.5333 6.89997C24.7555 7.21109 24.8444 7.54442 24.7999 7.89997C24.7555 8.25553 24.5777 8.54442 24.2666 8.76664ZM10.1333 20.5H6.13326C5.75548 20.5 5.43882 20.3722 5.18326 20.1166C4.9277 19.8611 4.79993 19.5444 4.79993 19.1666V13.8333C4.79993 13.4555 4.9277 13.1389 5.18326 12.8833C5.43882 12.6278 5.75548 12.5 6.13326 12.5H10.1333L14.5333 8.09997C14.9555 7.67775 15.4388 7.58331 15.9833 7.81664C16.5277 8.04997 16.7999 8.46664 16.7999 9.06664V23.9333C16.7999 24.5333 16.5277 24.95 15.9833 25.1833C15.4388 25.4166 14.9555 25.3222 14.5333 24.9L10.1333 20.5Z" fill="#1A1A1A" />
            </g>
        </svg>

    )
}

export default ActiveSponsorIcon