import { createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  forums: [],
  totalRecords: 0
};

export const forumReducer = createSlice({
  name: "forum",
  initialState: initialState,
  reducers: {
    setForumState: (state, action) => {
      state.forums = action.payload;
    },
    setForumTotalRecords: (state, action) => {
      state.totalRecords = action.payload;
    },
    addNewForumState: (state, action) => {
      state.forums.unshift(action.payload);
      state.totalRecords+=1; 
    },
    updateForumState: (state, action) => {
      const index = state.forums.findIndex((f: any) => f?._id == action.payload?.id);
      if (index > -1) {
        const lastesData: any = [...state.forums];
        lastesData[index] = action.payload.data;
        state.forums = lastesData;
      }
    },
    deleteForumState: (state, action) => {
      const index = state.forums.findIndex((f: any) => f?._id == action.payload);
      state.forums.splice(index, 1);
      state.totalRecords+=1; 
    },
    updateForumVotes: (state, action) => {
      const index = state.forums.findIndex((f: any) => f?._id == action.payload?.id);
      if (index > -1) {
        const lastesData: any = [...state.forums];
        lastesData[index].votes = action.payload.data;
        state.forums = lastesData;
      }
    },
    deletForumComment: (state, action) => {
      const index = state.forums.findIndex((f: any) => f?._id == action.payload?.id);
      if (index > -1) {
        const lastesData: any = [...state.forums];
        lastesData[index].comments = lastesData[index].comments.filter((c:any)=>c?._id!=action.payload?.commentId);
        state.forums = lastesData;
      }
    },
  },
});

export const {
  setForumState, addNewForumState, updateForumState, deleteForumState,
  setForumTotalRecords,updateForumVotes,deletForumComment
} = forumReducer.actions;
