import React from "react";

const MobileIcon = () => {
  return (
<svg width="24" height="27" viewBox="0 0 24 27" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
<rect width="24" height="27" fill="url(#pattern0_22_9)"/>
<defs>
<pattern id="pattern0_22_9" patternContentUnits="objectBoundingBox" width="1" height="1">
<use xlinkHref="#image0_22_9" transform="scale(0.0416667 0.037037)"/>
</pattern>
<image id="image0_22_9" width="24" height="27" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAbCAYAAABm409WAAAA2ElEQVRIDe1W0Q1CIQxkBEdwBD8J9BJGcARHcAQ30Q1cwQ0cwREcQV8N5DVSDNJfXtLQ9HF39EJCnRNfCGFHRFcAr5EgojuAvaBcU+/9FsBzhPgbQ0RpZc4ZEZ3KRs5HQuKbAov6rfrZWWAsi/DhKkjpYApMi6rLIQvzFkk31HxapNoii9Mi6Yaamy0CcAFwVtmdcyaB/KR+3mrONRGTABPGGI8cGjnXzAIt4lLvEgDwSCltCqh3ZQxjmw8Ot16mAuvatLG0aBFgjp9dLxsSgMO/c1HGVAPXG5f5bHYJ1Gg3AAAAAElFTkSuQmCC"/>
</defs>
</svg>

  );
};

export default MobileIcon;
