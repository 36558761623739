import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { LANG } from "../../../../constants/language";
import moment from "moment";
import ProfileCard from "../../profile-card/page";
import ImageWithBasePath from "../../../data/img/ImageWithBasePath";
import { ConfirmBookingModal } from "../confirm-modal/page";
import { getAllPlans, getSubscriptionsUser } from "../../../../services/plan.service";
import { SponsorPlan } from "../../../../types/sponsor-plan";
import SponsorshipDetailModal from "../../sponsorship-detail/SponsorshipDetailModal";
import { AxiosError } from "axios";
import { toast } from "react-toastify";
import { bookSponsorSubscription } from "../../../../services/sponser.service";
import DealsModal from "../../deals-modal/DealsModal";

export default function SponsorDashboardTabContent() {
  const userId = localStorage.getItem("id");
  const [allPlans, setAllPlans] = useState<SponsorPlan[]>([]);
  const [showBooking, setShowBooking] = useState<boolean>(false);
  const [selectedPlan, setSelectedPlan] = useState<SponsorPlan>();
  const [show, setShow] = useState<boolean>(false);
  const [deal, setDeal] = useState<any>();
  const [dealsActive, setDealsActive] = useState<any[]>([]);
  const [dealsPast, setDealsPast] = useState<any[]>([]);
  useEffect(() => {
    getAllData();
    getAllPlansData();
  }, []);

  const getAllData = async () => {
    try {
      const now = moment();
      const [dealDetail] = await Promise.all([getSubscriptionsUser({})]);
      if (dealDetail?.status == 200 && dealDetail.data?.data?.length) {
        const sponsorships = dealDetail.data?.data || [];
        const pastSponsorships = sponsorships.filter((sp: any) => moment(sp?.endDate).isBefore(now));
        const activeSponsorships = sponsorships.filter((sp: any) => moment(sp?.endDate).isSameOrAfter(now));
        setDealsPast(pastSponsorships);
        setDealsActive(activeSponsorships)
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAllPlansData= async()=>{
    try {
      const plansData = await getAllPlans({ limit: 1000, page: 1 });
      setAllPlans(plansData?.data?.data || [])
    } catch (error) {
      console.log(error);
    }
  }

  const openDeal = (deal: any) => {
    setShow(true);
    setDeal(deal);
  }

    const bookPlan = async (event: any) => {
      try {
        const payload={
          user:userId,
          plan: selectedPlan?._id,
          name: selectedPlan?.title,
          price: Number((selectedPlan?.price||0) - (selectedPlan?.discount||0)),
          ...event
        }
        console.log(payload)
        const booking= await bookSponsorSubscription(payload);
        setShowBooking(false);
        await getAllData();
      } catch (error) {
        if (error instanceof AxiosError) {
          toast.error(error.response?.data?.responseMessage);
        }
      }
  }

  return (
    <div className="dashboardTabContent">
      <div className="tableWrapper alerttable mt-3">
        <div className="title_Counter d-flex justify-content-between mb-3">
          <h4>{LANG.ACTIVE_SPONSORSHIPS} </h4>
        </div>
        <div className="table-responsive scrollHeight">
          <Table className="">
            <thead>
              <tr>
                <th>{LANG.NAME}</th>
                <th>{LANG.PRICE}</th>
                <th>{LANG.START_DATE}</th>
                <th>{LANG.END_DATE}</th>
                <th>{LANG.details}</th>
              </tr>
            </thead>
            <tbody>
              {dealsActive.length > 0 ? (
                dealsActive.map((event: any, index: number) => (
                  <tr key={index}>
                    <td className="text-capitalize">{event?.name}</td>
                    <td className="text-capitalize">{event?.price}</td>
                    <td>
                      {moment(event?.startDate).format("DD MMM YYYY")}
                    </td>
                    <td>
                      {moment(event?.endDate).format("DD MMM YYYY")}
                    </td>
                    <td>
                      <span className="text-underline cursor-pointer" onClick={() => openDeal(event)}>
                        {LANG.VIEW_DETAILS}
                      </span>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={7} style={{ textAlign: "center" }}>
                    {LANG.NO_ANY_SPONSORING}
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </div>
      <div className="tableWrapper eventstable">
        <div className="title_Counter d-flex justify-content-between mb-3">
          <h4>{LANG.PAST_SPONSORSHIPS}</h4>
        </div>
        <div className="table-responsive scrollHeight">
          <Table className="">
            <thead>
              <tr>
                <th>{LANG.NAME}</th>
                <th>{LANG.PRICE}</th>
                <th>{LANG.START_DATE}</th>
                <th>{LANG.END_DATE}</th>
                <th>{LANG.details}</th>
              </tr>
            </thead>
            <tbody>
              {dealsPast.length > 0 ? (
                dealsPast.map((event: any, index: number) => (
                  <tr key={index}>
                    <td className="text-capitalize">{event?.name}</td>
                    <td className="text-capitalize">{event?.price}</td>
                    <td>
                      {moment(event?.startDate).format("DD MMM YYYY")}
                    </td>
                    <td>
                      {moment(event?.endDate).format("DD MMM YYYY")}
                    </td>
                    <td>
                      <span className="text-underline cursor-pointer" onClick={() => openDeal(event)}>
                        {LANG.VIEW_DETAILS}
                      </span>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={7} style={{ textAlign: "center" }}>
                    {LANG.NO_ANY_SPONSORING}
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </div>
      <div className="">
        <div className="row">
          <div className="col-lg-12 col-md-12">
          <div className="commonGrid available-slots">
              <h4 className="mb-4">{LANG.SLOTS}</h4>
              <div className="row">
                {allPlans.map((event, index) => (
                  <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12" key={index}>
                    <div className="slotCard">
                      <div className="teamImage position-relative">
                        <ImageWithBasePath
                          src={"assets/img/teamImage.png"}
                          className="w-100"
                          alt="teamImage"
                        />
                      </div>
                      <div className="slotDetails">
                        <div className="position-relative">
                          <h5>
                            {event.title}
                          </h5>
                          <div>
                            <strong>{LANG.PRICE}:</strong>
                            <span className='px-2'>€{event?.price - event?.discount} <span style={{ textDecoration: 'line-through' }} className={event?.discount < 1 ? 'd-none' : ''}> €{event?.price}</span> </span>
                          </div>
                        </div>
                        <p className="slotDescription">
                          {event.description || " "}
                        </p>
                        <p className="slotDescription mb-1">
                          <strong>{LANG.EVENT_TYPE} :</strong>
                          <span className="px-2 text-capitalize">{event.type}</span>
                        </p>

                        <button
                          onClick={() => { setShowBooking(true); setSelectedPlan(event) }}
                          className={`bookBtn`}
                        >
                          {LANG.BOOK_SLOT}
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <SponsorshipDetailModal show={show} onHide={() => setShow(false)} deal={deal} />
      <DealsModal show={showBooking} onHide={() => setShowBooking(false)} plan={selectedPlan} submit={bookPlan} />
    </div>
  );
}
