import React, { useEffect, useState } from 'react';
import { Formik, Form as FormikForm, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import { Modal, Button, Form, ListGroup, InputGroup } from 'react-bootstrap';
import SearchIcon from '../../../../icons/SearchIcon';
import { LANG } from '../../../../constants/language';
import { getAllMembers } from '../../../../services/friends.service';
import { FaCalendarAlt } from 'react-icons/fa';
import { updateTask } from '../../../../services/tasks.service';
import moment from 'moment';
interface Props {
    show: boolean;
    handleClose: () => void;
    initialData: {
      _id: any;
      taskName: string;
      description: string;
      status: string;
      comment: string;
      deadLine: string;
      assignDate: string;
    };
}

export function TaskModal({ show, handleClose,  initialData }:Props) {
  const [members, setMembers]= useState<any[]>([]);
  const validationSchema = Yup.object({
    taskName: Yup.string(),
    description: Yup.string(),
    status: Yup.string(),
    comment: Yup.string(),
    deadLine: Yup.date(),
    assignDate: Yup.date()
  });

  const handleSubmit = async(values: typeof initialData) => {
    const result = await updateTask(initialData?._id,{ comment: values.comment });
    if(result.status == 200){
      handleClose();
    }
    handleClose();
  };

  const markComplete = async() => {
    const result = await updateTask(initialData?._id,{ status: 'completed' });
    if(result.status == 200){
      handleClose();
    }
  }

  const formatDateToIST = (dateString: string) => {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = {
      weekday: 'short',
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    };
    
    // Format the date without converting it to IST
    const formattedDate = date.toLocaleString('de', options);
    return formattedDate;
  };

  return (
    <Modal show={show} onHide={handleClose} centered className='taskModal'>
    <Modal.Header closeButton>
      <Modal.Title className='text-capitalize'>{LANG.TASK_DETAILS}</Modal.Title>
    </Modal.Header>
    <Formik
      initialValues={initialData}
      validationSchema={validationSchema}
      onSubmit={ handleSubmit }
    >
      {({ handleSubmit }) => (
        <form onSubmit={ handleSubmit }>
          <Modal.Body>
            {/* Task Date */}
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
              <span style={{
                backgroundColor: '#ffef62',
                padding: '0.5rem 1rem',
                borderRadius: '4px',
                display: 'flex',
                alignItems: 'center',
                gap: '0.5rem',
                color: "#1A1A1A"
              }}>
                <FaCalendarAlt /> {/* Calendar Icon */}
                <span>{formatDateToIST(initialData.assignDate)}</span>
              </span>
              {initialData.status != 'completed' && (<Button type="button" className='blueButton' style={{ marginLeft: 'auto' }} onClick={()=> markComplete()}>{LANG.MARK_AS_COMPLETE}</Button>)}
            </div>

            {/* Task Information */}
            <h5>{initialData.taskName}</h5>
            <p>{initialData.description}</p>

            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '1rem' }}>
              <Form.Group controlId="status">
                <Form.Label>{LANG.TASK_STATUS}</Form.Label>
                <p style={{ fontWeight: 'bold' }} className='text-capitalize'>{(initialData.status == 'inprogress')?LANG.inprogress:LANG.complete}</p> {/* Display the task status */}
              </Form.Group>

              <div style={{ textAlign: 'right' }}>
                <Form.Label>{LANG.DEADLINE}</Form.Label>
                <p>
                  {moment(initialData.deadLine).locale("de").format("DD MMM YYYY")}
                </p>
              </div>
            </div>

            {/* Comments Section */}
            <Form.Group controlId="comment">
              <Form.Label>{LANG.COMMENTS}:</Form.Label>
              <Field as="textarea" name="comment" placeholder={LANG.ADD_COMMENT} rows={3} className="form-control" />
              <ErrorMessage name="comment" component="div" className="text-danger" />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button type="submit" variant="primary" className='blueButton w-100'>
              {LANG.ADD_COMMENT}
            </Button>
          </Modal.Footer>
        </form>
      )}
    </Formik>
    </Modal>
  );
}
