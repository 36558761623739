import React from "react";
import { Navigate, Route } from "react-router";
import { all_routes } from "./all_routes";
import Home from "../home/home";
import AboutUs from "../pages/about-us";
import ComingSoon from "../pages/coming-soon";
import ContactUs from "../contact-us/contact-us";
import Error404 from "../pages/error-404";
import Events from "../pages/events";
import EventDetails from "../pages/event-details";
import ListingGridSidebar from "../listing/listing-grid-sidebar";
import ListingGrid from "../listing/listing-grid";
import ListingListSidebar from "../listing/listing-list-sidebar";
import ListingMapSidebar from "../listing/listing-map-sidebar";
import UserDashboard from "../user/user-dashboard";
import ServiceDetail from "../pages/service-detail";
import Services from "../pages/services";
import TermsCondition from "../pages/terms-condition";
import PrivacyPolicy from "../pages/privacy-policy";
import Pricing from "../pages/pricing";
import OurTeams from "../pages/our-teams";
import MyProfile from "../pages/my-profile";
import ListingList from "../listing/listing-list";
import ListingMap from "../listing/listing-map";
import Testimonials from "../pages/testimonials";
import TestimonialsCarousel from "../pages/testimonials-carousel";
import Faq from "../pages/faq";
import ForgotPassword from "../auth/forgot-password";
import Gallery from "../pages/gallery";
import Signin from "../auth/register";
import Login from "../auth/login";
import ChangePassword from "../auth/change-password";
import Maintenance from "../pages/maintenance";
import VerifyAccountPage from "../auth/verify-account";
import SelectRoles from "../auth/select-roles";
import TrainerDashboard from "../trainer/trainer-dashboard";
import SponsorDashboard from "../sponsor/sponsor-dashboard";
import ForumList from "../forum/forum-list";
import ForumDetails from "../forum/forum-details";
import ValidationRedirect from "../auth/validation-redirect";
import Webpages from "../webpages";
import Sponsoren from "../sponsoren";
import Geschichte from "../geschichte";
import Impressum from "../Impressum";
import Downloads from "../Downloads";
import Teams from "../Teams";
import Application from "../application";
import Datenschutz from "../datenschutz";

const routes = all_routes;

const publicRoutes = [
  {
    path: routes.home,
    element: <Home />,
    route: Route,
  },
  {
    path: routes.aboutUs,
    element: <AboutUs />,
    route: Route,
  },
  {
    path: routes.events,
    element: <Events />,
    route: Route,
  },
  {
    path: routes.eventdetails,
    element: <EventDetails />,
    route: Route,
  },
  {
    path: routes.listingGridSidebar,
    element: <ListingGridSidebar />,
    route: Route,
  },
  {
    path: routes.listingGrid,
    element: <ListingGrid />,
    route: Route,
  },
  {
    path: routes.listingList,
    element: <ListingList />,
    route: Route,
  },
  {
    path: routes.listingListSidebar,
    element: <ListingListSidebar />,
    route: Route,
  },
  {
    path: routes.listingMap,
    element: <ListingMap />,
    route: Route,
  },
  {
    path: routes.listingMapSidebar,
    element: <ListingMapSidebar />,
    route: Route,
  },
  {
    path: routes.userDashboard,
    element: <UserDashboard />,
    route: Route,
  },
  {
    path: routes.trainerDashboard,
    element: <TrainerDashboard />,
    route: Route,
  },
  {
    path: routes.sponsorDashboard,
    element: <SponsorDashboard />,
    route: Route,
  },
  {
    path: routes.forumListMember,
    element: <ForumList />,
    route: Route,
  },
  {
    path: routes.forumDetailMember,
    element: <ForumDetails />,
    route: Route,
  },
  {
    path: routes.serviceDetail,
    element: <ServiceDetail />,
    route: Route,
  },
  {
    path: routes.services,
    element: <Services />,
    route: Route,
  },
  {
    path: routes.termsCondition,
    element: <TermsCondition />,
    route: Route,
  },
  {
    path: routes.privacyPolicy,
    element: <PrivacyPolicy />,
    route: Route,
  },
  {
    path: routes.pricing,
    element: <Pricing />,
    route: Route,
  },
  {
    path: routes.ourTeams,
    element: <OurTeams />,
    route: Route,
  },
  {
    path: routes.myProfile,
    element: <MyProfile />,
    route: Route,
  },
  {
    path: routes.testimonials,
    element: <Testimonials />,
    route: Route,
  },
  {
    path: routes.testimonialsCarousel,
    element: <TestimonialsCarousel />,
    route: Route,
  },
  {
    path: routes.faq,
    element: <Faq />,
    route: Route,
  },
 
  {
    path: routes.gallery,
    element: <Gallery />,
    route: Route,
  },
  {
    path: routes.changePassword,
    element: <ChangePassword />,
    route: Route,
  },
  {
    path: "/",
    name: "Root",
    element: <Navigate to="/" />,
    route: Route,
  },
  {
    path: "*",
    name: "NotFound",
    element: <Navigate to="/index" />,
    route: Route,
  },
];

const withoutHeaderRoutes = [
  {
    path: routes.comingSoon,
    element: <ComingSoon />,
    route: Route,
  },
  {
    path: routes.error404,
    element: <Error404 />,
    route: Route,
  },
  {
    path: routes.register,
    element: <Signin />,
    route: Route,
  },
  {
    path: routes.login,
    element: <Login />,
    route: Route,
  },
  {
    path: routes.verifyAccount,
    element: <VerifyAccountPage />,
    route: Route,
  },
  {
    path: routes.maintenance,
    element: <Maintenance />,
    route: Route,
  },
  {
    path: routes.forgotPasssword,
    element: <ForgotPassword />,
    route: Route,
  },
  {
    path: routes.selectRole,
    element: <SelectRoles />,
    route: Route,
  },
  {
    path: routes.login_token,
    element: <ValidationRedirect />,
    route: Route,
  },
  {
    path: routes.webpage,
    element: <Webpages />,
    route: Route,
  },
  {
    path: routes.sponsoren,
    element: <Sponsoren />,
    route: Route,
  },
  {
    path: routes.geschichte,
    element: <Geschichte/>,
    route: Route,
  },
  {
    path: routes.impressum,
    element: <Impressum/>,
    route: Route,
  },
  {
    path: routes.datenschutz,
    element: <Datenschutz/>,
    route: Route,
  },
  {
    path: routes.downloads,
    element: <Downloads />,
    route: Route,
  },
  {
    path: routes.application,
    element: <Application />,
    route: Route,
  },
  {
    path: routes.contactUs,
    element: <ContactUs />,
    route: Route,
  },
  {
    path: routes.teams,
    element: <Teams />,
    route: Route,
  },
];
export { publicRoutes, withoutHeaderRoutes };
