import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { LANG } from "../../../../constants/language";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import ImageWithBasePath from "../../../data/img/ImageWithBasePath";
import { getAllPlans } from "../../../../services/plan.service";
import { SponsorPlan } from "../../../../types/sponsor-plan";
import DealsModal from "../../deals-modal/DealsModal";
import { AxiosError } from "axios";
import { bookSponsorSubscription } from "../../../../services/sponser.service";

export default function SlotsTabContent() {
  const userId = localStorage.getItem("id");
  const [allPlans, setAllPlans] = useState<SponsorPlan[]>([]);
  const [showBooking, setShowBooking] = useState<boolean>(false);
  const [selectedPlan, setSelectedPlan] = useState<SponsorPlan>();
  const dispatch = useDispatch();

  const router = useNavigate();

  useEffect(() => {
    getAllData();
  }, []);

  const getAllData = async () => {
    try {
      const [plansData] = await Promise.all([getAllPlans({ limit: 1000, page: 1 })]);
      setAllPlans(plansData?.data?.data || [])
    } catch (error) {
      console.log(error);
    }
  };
  const bookPlan = async (event: any) => {
    try {
      const payload={
        user:userId,
        plan: selectedPlan?._id,
        name: selectedPlan?.title,
        price: Number((selectedPlan?.price||0) - (selectedPlan?.discount||0)),
        ...event
      }
      console.log(payload)
      const booking= await bookSponsorSubscription(payload);
      setShowBooking(false);
    } catch (error) {
      if (error instanceof AxiosError) {
        toast.error(error.response?.data?.responseMessage)
      }
    }
  }
  return (
    <div className="dashboardTabContent">
      <div className="">
        <div className="row">
          <div className="col-lg-12">
            <div className="commonGrid available-slots">
              <h4 className="mb-4">{LANG.SLOTS}</h4>
              <div className="row">
                {allPlans.map((event, index) => (
                  <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12" key={index}>
                    <div className="slotCard">
                      <div className="teamImage position-relative">
                        <ImageWithBasePath
                          src={"assets/img/teamImage.png"}
                          className="w-100"
                          alt="teamImage"
                        />
                      </div>
                      <div className="slotDetails">
                        <div className="position-relative">
                          <h5>
                            {event.title}
                          </h5>
                          <div>
                            <strong>{LANG.PRICE}:</strong>
                            <span className='px-2'>€{event?.price - event?.discount} <span style={{ textDecoration: 'line-through' }} className={event?.discount < 1 ? 'd-none' : ''}> €{event?.price}</span> </span>
                          </div>
                        </div>
                        <p className="slotDescription">
                          {event.description || " "}
                        </p>
                        <p className="slotDescription mb-1">
                          <strong>{LANG.EVENT_TYPE} :</strong>
                          <span className="px-2 text-capitalize">{event.type}</span>
                        </p>

                        <button
                          onClick={() => { setShowBooking(true); setSelectedPlan(event) }}
                          className={`bookBtn`}
                        >
                          {LANG.BOOK_SLOT}
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <DealsModal show={showBooking} onHide={() => setShowBooking(false)} plan={selectedPlan} submit={bookPlan} />
    </div>
  );
}
