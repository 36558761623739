import React from 'react'
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { useSelector } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import { ROLE_OBJ } from '../../../constants';
import { LANG } from '../../../constants/language';

const HomeHeader = () => {
    const user = useSelector((state: any) => state.user);
    const role = localStorage.getItem("role") || 'member';
    return (
        <Navbar collapseOnSelect expand="lg" className="headerHome">
            <Container className="align-items-start">
                <Link className="navbar-brand" to={"/"}><img src={'/assets/img/logo.png'} alt='logo' /></Link>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav align-items-start">
                    <Nav className="menu">
                        <ul className="d-flex gap-48px">
                            <li><NavLink to={"/"}>{LANG.HOME}</NavLink></li>
                            <li><NavDropdown title="Verein" id="collapsible-nav-dropdown">
                                <NavLink to={"/verein/vorstand"}>Vorstand</NavLink>
                                {/* <NavLink to={"/verein/ansprechpartner"}>Ansprechpartner</NavLink> */}
                                <NavLink to={"/verein/geschichte"}>Geschichte</NavLink>
                            </NavDropdown>
                            </li>
                            <li className="teamSubmenu">
                                <NavDropdown title={LANG.TEAMS} id="collapsible-nav-dropdown">
                                    <NavLink to={"/teams/mannschaft1"}>1. Mannschaft</NavLink>
                                    <NavLink to={"/teams/mannschaft2"}>2. Mannschaft</NavLink>
                                    <NavLink to={"/teams/alte_herren"}>Alte Herren</NavLink>
                                    <NavLink to={"/teams/u17_b1"}>U17 (B1)</NavLink>
                                    <NavLink to={"/teams/u14_c1"}>U14 (C1)</NavLink>
                                    <NavLink to={"/teams/u15_c2"}>U15 (C2)</NavLink>
                                    <NavLink to={"/teams/u12_c3"}>U12 (C3)</NavLink>
                                    <NavLink to={"/teams/u13_d1"}>U13 (D1)</NavLink>
                                    <NavLink to={"/teams/u12_d2"}>U12 (D2)</NavLink>
                                    <NavLink to={"/teams/u12_d3"}>U12 (D3)</NavLink>
                                    <NavLink to={"/teams/u11_e1"}>U11 (E1)</NavLink>
                                    <NavLink to={"/teams/u9_f1"}>U9 (F1)</NavLink>
                                    <NavLink to={"/teams/u10_f2"}>U10 (F2)</NavLink>
                                    <NavLink to={"/teams/u8_f3"}>U8 (F3)</NavLink>
                                    <NavLink to={"/teams/u9_f4"}>U9 (F4)</NavLink>
                                    <NavLink to={"/teams/u7_g1"}>U7 (G1)</NavLink>
                                </NavDropdown>
                            </li>
                            <li><NavLink to={"/sponsoren"}>{LANG.SPONSORS}</NavLink></li>
                            <li><NavLink to={"/downloads"}>{LANG.DOWNLOADS}</NavLink></li>
                            <li><NavLink to={"/application"}>{LANG.APP}</NavLink></li>
                            <li><NavLink to={"/contact"}>{LANG.CONTACT}</NavLink></li>
                            {
                                user?.isLogin ? <li> <Link className="text-capitalize" to={`/${ROLE_OBJ[role]}/${ROLE_OBJ[role]}-dashboard`}>
                                    <span className="me-1">
                                        <i className="feather-users" />
                                    </span>
                                    <span className='text-break'>{user?.userDetail?.firstName} {user?.userDetail?.lastName}</span>
                                </Link></li> :
                                    <li className="d-flex">
                                        <Link to="/auth/login">
                                            <span className="me-1">
                                                <i className="feather-users" />
                                            </span>
                                            {LANG.LOGIN}
                                        </Link>{" "}
                                        <span className="text-white mx-1"> / </span>
                                        <Link to="/auth/register" className="">{LANG.REGISTER}</Link>
                                    </li>
                            }
                        </ul>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default HomeHeader