import React from "react";

const AccountSettingIcon = () => {
  return (
<svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
<rect width="27" height="27" fill="url(#pattern0_22_6)"/>
<defs>
<pattern id="pattern0_22_6" patternContentUnits="objectBoundingBox" width="1" height="1">
<use xlinkHref="#image0_22_6" transform="scale(0.037037)"/>
</pattern>
<image id="image0_22_6" width="27" height="27" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAAbCAYAAACN1PRVAAAB5ElEQVRIDa1W0VHDMAzNCIzACHzmEunICGwAG8AGsAFskIzABnSDskHYIN2g8IqeT3UdxyH0rudYlvX0ZEl2VVVVJSKdiHyo6jH+i8heVe+gt/kHoBggNYfef4DtzPhBRF7iv6oebH3YDEYWAEkZE5E30xlT66tkIvIFYzCa2qiqD3So67qrlE6xTFUHA9unNtV1fU0wAKd0imVt2z7R2NwmslfV9zmdInnTNDcEm8s4x35XZDSn5MAuksScmUxnGzM4gfCYsbOMi4COmOecLlqLCjskwY+cNYjOEuRFRnNKznBgh9rLhThnL7tWwg46WSNrFj07FjBG17ImytfYTeqiuzNsqtpTKWI9xomCuYg8i8gjmgD3LY6sKQMNSeGLX1VRCqc11zvD9WTXUt+27X0WHGESkU8y9CyMOW8BGB+ptzAGpy/YWj+k0ckDYs07AxDM4aSdLxr3EOlMWYbROYWw0TOGm0CU+9HnwGImJ8L26o15xl7uvxneRTBsskwLZ4i3SjYkHum3FZ4SpwgMe+0s2D+ZdX0J6Cpm3lFrX0ycAJoL55/ByHIGdEKIUdyqegtd/6QoDqNnx2+rRzTqmCkZhxEZy32bRgPFIxfPQFxJZ+B4UuTCvAkcm2Ec7Q2hgzM0+A2cI4UQXD7iSwAAAABJRU5ErkJggg=="/>
</defs>
</svg>
  );
};

export default AccountSettingIcon;
