import { combineReducers } from "redux";
import { userReducer } from "./user/userSlice";
import { sharedReducer } from "./shared/sharedSlice";
import { notificationReducer } from "./notification/notificationSlice";
import { teamReducer } from "./teams/teamSlice";
import { forumReducer } from "./forum/forumSlice";
import { webpageReducer } from "./webpage/webpageSlice";

const rootReducer = combineReducers({
  user: userReducer.reducer,
  shared: sharedReducer.reducer,
  notification: notificationReducer.reducer,
  team: teamReducer.reducer,
  forum: forumReducer.reducer,
  webpage: webpageReducer.reducer
});

export default rootReducer;
