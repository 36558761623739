import React from 'react'

export default function Eyeclode() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.74577 13.0907C3.07127 13.4161 3.59887 13.416 3.92425 13.0905C4.24963 12.765 4.24955 12.2374 3.92405 11.9121L2.01189 10.0006L4.90245 7.11C6.64724 5.36522 9.12414 4.6543 11.4945 5.15374C11.9448 5.24862 12.3868 4.96047 12.4817 4.51013C12.5766 4.05979 12.2884 3.61782 11.8381 3.52294C8.92216 2.90859 5.87129 3.78418 3.72394 5.93153L0.244064 9.4114C-0.0813936 9.73686 -0.0813546 10.2646 0.244181 10.59L2.74577 13.0907Z" fill="#5A5A5A"/>
    <path d="M19.7556 9.41048L17.254 6.90975C16.9285 6.58437 16.4009 6.58445 16.0755 6.90995C15.7501 7.23544 15.7502 7.76304 16.0757 8.08842L17.9879 9.99992L15.0973 12.8905C13.3525 14.6353 10.8756 15.3462 8.50526 14.8467C8.05493 14.7519 7.61295 15.04 7.51807 15.4903C7.42319 15.9407 7.71134 16.3827 8.16168 16.4775C11.0776 17.0919 14.1285 16.2163 16.2758 14.069L19.7557 10.5891C20.0812 10.2636 20.0811 9.73586 19.7556 9.41048Z" fill="#5A5A5A"/>
    <path d="M5.8335 10.0002C5.8335 10.4605 6.20657 10.8335 6.66679 10.8335C7.12701 10.8335 7.50008 10.4605 7.50008 10.0002C7.50008 8.6197 8.61945 7.50032 9.99999 7.50032C10.4602 7.50032 10.8333 7.12725 10.8333 6.66703C10.8333 6.20681 10.4602 5.83374 9.99999 5.83374C7.69902 5.8337 5.8335 7.69922 5.8335 10.0002Z" fill="#5A5A5A"/>
    <path d="M12.4997 10.0003C12.4997 11.3808 11.3803 12.5002 9.9998 12.5002C9.53958 12.5002 9.1665 12.8732 9.1665 13.3335C9.1665 13.7937 9.53958 14.1668 9.9998 14.1668C12.3008 14.1668 14.1663 12.3012 14.1663 10.0002C14.1663 9.54 13.7933 9.16693 13.333 9.16693C12.8728 9.16693 12.4997 9.54004 12.4997 10.0003Z" fill="#5A5A5A"/>
    <path d="M19.7547 0.244064C19.4293 -0.0813546 18.9017 -0.0813546 18.5763 0.244064L0.243575 18.5767C-0.0818429 18.9022 -0.0818429 19.4298 0.243575 19.7552C0.568994 20.0806 1.09663 20.0806 1.42205 19.7552L19.7547 1.42254C20.0802 1.09712 20.0802 0.569482 19.7547 0.244064Z" fill="#5A5A5A"/>
    </svg>

  )
}
