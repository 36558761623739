import React, { useEffect, useState } from "react";
import { LANG } from "../../constants/language";
import "./style/style.scss"
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SponsorTab from "./tabs/page";
import ActiveSponsorIcon from "../../icons/ActiveSponsorIcon";
import PastSponsorIcon from "../../icons/PastSponsorIcon";
import moment from "moment";
import { getSubscriptionsUser } from "../../services/plan.service";

const SponsorDashboard = () => {
  const user = useSelector((state: any) => state.user?.userDetail)
  const [dealsActive, setDealsActive] = useState<any[]>([]);
  const [dealsPast, setDealsPast] = useState<any[]>([]);
  const fileUrl = process.env.REACT_APP_FILE_URL;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const role = localStorage.getItem('role');


  useEffect(() => {
    getData()
  }, []);

  const getData = async () => {
    try {
      const now = moment();
      const [dealDetail] = await Promise.all([getSubscriptionsUser({})]);
      if (dealDetail?.status == 200 && dealDetail.data?.data?.length) {
        const sponsorships = dealDetail.data?.data || [];
        const pastSponsorships = sponsorships.filter((sp: any) => moment(sp?.endDate).isBefore(now));
        const activeSponsorships = sponsorships.filter((sp: any) => moment(sp?.endDate).isSameOrAfter(now));
        setDealsPast(pastSponsorships);
        setDealsActive(activeSponsorships)
      }

    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div>
      <div className="memeberDashboard">
        <div className="banner">
          <img src={"/assets/img/bannerTop.jpg"} alt="bannerTop" className="w-100 h-auto" />
        </div>
        <div className="container">
          <div className="profileBlock d-flex align-items-start">
            <div className="memberImage">
              <img src={user?.avatar ? fileUrl + user?.avatar : "/assets/img/default-avatar.png"} style={{ minWidth: 120 }} alt="memberImg" />
            </div>
            <div className="memberInfo">
              <div className="name_private  d-flex">
                <h3>{user?.firstName} {user?.lastName}</h3>

              </div>
              <p>{user?.description}</p>
              <ul className="memberDetail">
                {/* <li>{LANG.MEMBER_ID} : <label>{user?._id}</label></li> */}
                {/* <li>{LANG.SEASON} : <label>2024 / 2025</label></li> */}
                {/* <li>{LANG.JOINED} : <label>{moment(user?.joinedAt).format("DD MMM YYYY")}</label></li> */}
                {/* <li>{LANG.BLOOD_GROUP} : <label>--</label></li> */}
                <li>{LANG.CONTACT} : <label>{user?.phone}</label></li>
                <li>{LANG.EMAIL} : <label>{user?.email}</label></li>
                {/* <li>{LANG.ADDRESS} : <label>{user?.street} {user?.houseNumber} {user?.zipcode} {user?.city} {user?.birthPlaceCity} {user?.birthPlaceCountry} {user?.nationality}</label></li> */}
              </ul>
            </div>
            {/* <button className="eidtProfileBtn" onClick={navigateToProfile}>{LANG.EDIT_PROFILE} <EditIcon /></button> */}
          </div>
          <div className="threeBlocks">
            <div className="row">
              <div className="col-md-6">
                <div className="blockWrapper d-flex justify-content-between">
                  <div className="">
                    <h4>{dealsActive?.length || 0}</h4>
                    <span>{LANG.ACTIVE_SPONSORSHIPS}</span>
                  </div>
                  <button className="bg-yellow-light btn">
                    <ActiveSponsorIcon />
                  </button>
                </div>
              </div>
              <div className="col-md-6">
                <div className="blockWrapper d-flex justify-content-between">
                  <div className="">
                    <h4>{dealsPast?.length || 0}</h4>
                    <span>{LANG.PAST_SPONSORSHIPS}</span>
                  </div>
                  <button className="bg-yellow-light btn">
                    <PastSponsorIcon />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <hr className="divider"></hr>
          <div className="tabOuter" id="tabs-layout">
            <SponsorTab />
          </div>
        </div>
      </div>

      <div>
      </div>

    </div>
  );
};

export default SponsorDashboard;
