import React from 'react'
import './style.scss';
import ThankYou from '../../../home/thank-you/ThankYou';
import HomeFollow from '../../../home/home-follow/HomeFollow';
import HomeSubscribe from '../../../home/home-subscribe/HomeSubscribe';
import { WebPage } from '../../../../types/webpage';
import { useSelector } from 'react-redux';
import { GeschichtePageSelector } from '../../../../core/data/redux/webpage/webPageAction';
import ReactQuill from 'react-quill';

const Geschichte = () => {
    const geschichte: WebPage = useSelector(GeschichtePageSelector);
    return (
        <div className='geschichtePage BgLightBlue'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-6 mr-lg-5'>
                        <div className='imgFull me-lg-5 mr-md-0 mb-sm-4'>
                            <img className='w-100 h-auto' src='/assets/img/leftImg.jpg' alt='leftImg.jpg' />
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='textFull ms-lg-5 ms-lg-0'>
                            <h1>{geschichte?.geschichte?.title}</h1>
                            <div>
                                <ReactQuill value={geschichte?.geschichte?.content}
                                    readOnly={true}
                                    theme={"bubble"} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ThankYou />
            <HomeFollow />
            <HomeSubscribe />
        </div>
    )
}
export default Geschichte;