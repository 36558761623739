import React from "react";

export default function CalendarIcon() {
  return (
<svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
<mask id="mask0_338_819" style={{maskType:"alpha"}} maskUnits="userSpaceOnUse" x="0" y="0" width="33" height="33">
<rect x="0.800293" y="0.5" width="32" height="32" fill="#D9D9D9"/>
</mask>
<g mask="url(#mask0_338_819)">
<path d="M7.46696 29.8334C6.73363 29.8334 6.10585 29.5723 5.58363 29.0501C5.0614 28.5279 4.80029 27.9001 4.80029 27.1667V8.50008C4.80029 7.76675 5.0614 7.13897 5.58363 6.61675C6.10585 6.09453 6.73363 5.83341 7.46696 5.83341H8.80029V3.16675H11.467V5.83341H22.1336V3.16675H24.8003V5.83341H26.1336C26.867 5.83341 27.4947 6.09453 28.017 6.61675C28.5392 7.13897 28.8003 7.76675 28.8003 8.50008V27.1667C28.8003 27.9001 28.5392 28.5279 28.017 29.0501C27.4947 29.5723 26.867 29.8334 26.1336 29.8334H7.46696ZM7.46696 27.1667H26.1336V13.8334H7.46696V27.1667Z" fill="#1A1A1A"/>
</g>
</svg>

  );
}
