import { createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  teams:[],
  teamMembers:[]
};

export const teamReducer = createSlice({
  name: "team",
  initialState: initialState,
  reducers: {
    setTeams:(state, action)=>{
      state.teams= action.payload;
    },
    setTeamMembers:(state, action)=>{
      state.teamMembers= action.payload;
    }
  },
});

export const { setTeams, setTeamMembers } = teamReducer.actions;
