import React, { useCallback } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import CommonButton from "../button/page";
import ImageWithBasePath from "../../data/img/ImageWithBasePath";
import { LANG } from "../../../constants/language";

interface Props{
  team:any,
  open():void
}
export default function TeamBlock({team,open}:Props) {
  const router = useNavigate();
  const role=localStorage.getItem('role');

  const openTeam=()=>{
    open();
    console.log(role=='trainer', role)
    if(role=='trainer'){
      router(`/trainer/trainer-dashboard?tab=team&teamId=${team?._id}`)
    }else{
      router(`/user/user-dashboard?tab=team&teamId=${team?._id}`)
    }
   
  }

  return (
    <div className="teamBlock">
      <div className="teamImage">
        <ImageWithBasePath src={'assets/img/teamImage.png'} className="w-100" alt="teamImage" />
      </div>
      <h5 className="text-start">{team?.teamName||"NA"}</h5>
      <CommonButton
        label={LANG.VIEW_TEAM}
        type="button"
        className="teamViewBtn w-100"
        onPress={openTeam}
      />
    </div>
  );
}
