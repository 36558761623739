import React, { useEffect, useState } from "react";
import { LANG } from "../../../constants/language";
import { getUserID } from "../../../services/user.service";
import { Table } from "react-bootstrap";
import { getAllTasks } from "../../../services/tasks.service";
import { TaskModal} from "../trainer/task-modal/page";
import "./style.scss";
import moment from "moment";

export default function TaskTabContent() {
  const [ongoing, setOngoing] = useState<any>([]);
  const [completed, setCompleted] = useState<any>([]);
  const [showtaskModal, setShowTaskModal] = useState<boolean>(false);
  const [selectedTask, setSelectedTask] = useState<any>({})
  useEffect(() => {
    getTasks();
  }, []);
  const senderId = getUserID();
  const getTasks = async () => {
    try {
      const [Tasks] = await Promise.all([getAllTasks({ limit: 12, page: 1, trainer: senderId})]);
      const ongoingArray: any = Tasks?.data?.data?.ongoingTasks?.data || [];
      const completedArray: any = Tasks?.data?.data?.completedTasks?.data || [];
      setOngoing(ongoingArray);
      setCompleted(completedArray);
    } catch (error) {
      console.log(error);
    }
  };

  const onClose = async() => {
    setShowTaskModal(false)
    getTasks();
  }

  return (
    <div className="dashboardTabContent">
      <div className="tableWrapper alerttable">
        <div className="title_Counter d-flex justify-content-between mb-3">
          <h4>{LANG.ongoingTask}</h4>
        </div>
        <div className="table-responsive scrollHeight">
          <Table className="">
            <thead>
              <tr>
                <th>{LANG.task_name}</th>
                <th>{LANG.assign_date}</th>
                <th>{LANG.description}</th>
                <th>{LANG.status}</th>
                <th>{LANG.comment}</th>
                <th>{LANG.deadline}</th>
                <th>{LANG.action}</th>
              </tr>
            </thead>
            <tbody>
              {ongoing.length > 0 ? (
                ongoing.map((item: any, index: number) => (
                  <tr key={index}>
                    <td>{item.taskName}</td>
                    <td>
                      {moment(item.assignDate).locale("de").format("DD MMM YYYY")}
                    </td>
                    <td>
                     <p className="feedback-column">{item.description}</p> 
                    </td>
                    <td>{(item.status == 'inprogress')?LANG.inprogress:LANG.complete}</td>
                    <td className="feedback-column">{item.comment}</td>
                    <td>
                      {moment(item.deadLine).locale("de").format("DD MMM YYYY")}
                    </td>
                    <td>
                      <button
                        style={{ color: "#1a80c7" }}
                        onClick={() => {
                          setSelectedTask(item);
                          setShowTaskModal(true);
                        }}
                      >
                        {LANG.details}
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={7} style={{ textAlign: "center" }}>
                    {LANG.no_ongoing_task_available}
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </div>
      <div className="tableWrapper eventstable">
        <div className="title_Counter d-flex justify-content-between mb-3">
          <h4>{LANG.completed_task}</h4>
        </div>
        <div className="table-responsive scrollHeight">
          <Table className="">
            <thead>
              <tr>
                <th>{LANG.task_name}</th>
                <th>{LANG.assign_date}</th>
                <th>{LANG.description}</th>
                <th>{LANG.status}</th>
                <th>{LANG.comment}</th>
                <th>{LANG.deadline}</th>
                <th>{LANG.action}</th>
              </tr>
            </thead>
            <tbody>
              {completed.length > 0 ? (
                completed.map((item: any, index: number) => (
                  <tr key={index}>
                    <td>{item.taskName}</td>
                    <td>
                      {moment(item.assignDate).locale("de").format("DD MMM YYYY")}
                    </td>
                    <td>{item.description}</td>
                    <td>{(item.status == 'inprogress')?LANG.inprogress:LANG.complete}</td>
                    <td className="feedback-column">{item.comment}</td>
                    <td>
                      {moment(item.deadLine).locale("de").format("DD MMM YYYY")}
                    </td>
                    <td>
                      <button
                        style={{ color: "#1a80c7" }}
                        onClick={() => {
                          setSelectedTask(item);
                          setShowTaskModal(true);
                        }}
                      >
                      {LANG.details}
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={7} style={{ textAlign: "center" }}>
                    {LANG.no_completed_tasks_available}
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
        <TaskModal
          show={showtaskModal}
          handleClose={() => onClose()}
          initialData={selectedTask}
        />
      </div>
    </div>
  );
}
