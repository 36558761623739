import { WebPageData } from './../../../../types/webpage';
import { createSlice } from "@reduxjs/toolkit";


const initialState: WebPageData= {
  webpages:[]
};

export const webpageReducer = createSlice({
  name: "webpage",
  initialState: initialState,
  reducers: {
    setWebpageState: (state, action) => {
      state.webpages = action.payload;
    },
  },
});

export const {  setWebpageState } = webpageReducer.actions;
