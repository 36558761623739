import React from 'react'

const TaskIcon = () => {
    return (
        <svg width="22" height="28" viewBox="0 0 22 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.46686 21.8333L17.0002 14.3L15.0669 12.3667L9.43353 18L6.63353 15.2L4.73353 17.1L9.46686 21.8333ZM2.86686 27.1667C2.13353 27.1667 1.50575 26.9056 0.983529 26.3833C0.461306 25.8611 0.200195 25.2333 0.200195 24.5V3.16667C0.200195 2.43333 0.461306 1.80556 0.983529 1.28333C1.50575 0.761111 2.13353 0.5 2.86686 0.5H13.5335L21.5335 8.5V24.5C21.5335 25.2333 21.2724 25.8611 20.7502 26.3833C20.228 26.9056 19.6002 27.1667 18.8669 27.1667H2.86686ZM12.2002 9.83333H18.8669L12.2002 3.16667V9.83333Z" fill="#1A1A1A" />
        </svg>
    )
}

export default TaskIcon