import React from "react";
import TickBlueIcon from "../../../icons/TickBlueIcon";
import { LANG } from "../../../constants/language";
import moment from "moment";
import VisibilityBox from "../VisibilityBox";
import clsx from "clsx";

interface Props {
  user: any,
  buttonText?: string,
  click(): void
}
export default function FriendsBlock({ user, buttonText, click }: Props) {
  const fileUrl = process.env.REACT_APP_FILE_URL;
  const userId = localStorage.getItem('id');
  
  return (
    <div className={clsx("friendBlock","request_status")}>
      <div className="friendImage">
        <img src={user?.avatar ? fileUrl + user?.avatar : "/assets/img/default-avatar.png"} alt="friendTwo" />
      </div>
      <div className="d-flex align-items-center justify-content-center flex-column">
        <h5 className="w-100 text-center overflow-hidden text-nowrap">
          {user?.firstName || 'NA'} {user?.lastName}
        </h5>
        <label className="d-flex align-items-center justify-content-center">
          <span>{LANG.JOINED} :</span>
          <strong> {moment(user?.joinedAt).format("DD MMM YYYY")}</strong>
        </label>
        <label className="d-flex align-items-center justify-content-center">
          <span>{LANG.CONTACT} :</span>
          <strong> {user?.phone || 'NA'}</strong>
        </label>
        {user?._id!=userId ?
          <button className="friendBtn w-100" onClick={click}>
            <TickBlueIcon />
            {buttonText || LANG.FRIEND}
          </button>:<div className="py-4 mt-1"></div>
         }
      </div>
    </div>
  );
}
