import React, { useEffect, useState } from 'react';
import { Form, Button, Modal } from 'react-bootstrap';
import { LANG } from '../../../../constants/language';
import VisibilityBox from '../../VisibilityBox';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import * as Yup from "yup";
import { useFormik } from "formik";
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { setFormDetail, setForumModalStatus } from '../../../data/redux/shared/sharedSlice';
import { addNewForums, updateForums } from '../../../../services/forum.service';
import { checkFileSizeMoreThanTen, getFormData } from '../../../../utils';
import { addNewForumState, updateForumState } from '../../../data/redux/forum/forumSlice';
import { ALLOWED_IMAGES } from '../../../../constants';

const forumSchema: any = Yup.object().shape({
    image: Yup.mixed(),
    title: Yup.string().required(LANG.FIELD_IS_REQUIRED),
    description: Yup.string().required(LANG.FIELD_IS_REQUIRED),
});

const ForumModal = (props: any) => {
    const dispatch: any = useDispatch();
    const fileUrl = process.env.REACT_APP_FILE_URL;
    const { formDetails: forumDetail, forumModal } = useSelector((state: any) => state.shared);

    const [imageUrl, setImageUrl] = useState<any>("");

    useEffect(() => {
        // get data
    }, [])

    const formIntialValues: any = {
        ...forumDetail
    };

    const forumFormik = useFormik({
        initialValues: formIntialValues,
        validationSchema: forumSchema,
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            try {
                const formData = getFormData(values);
                if (forumDetail?._id) {
                    const result = await updateForums(forumDetail?._id, formData);
                    if (result.status == 200) {
                        setSubmitting(true);
                        resetForm();
                        dispatch(setForumModalStatus(false));
                        dispatch(updateForumState({ id: forumDetail?._id, data: result.data?.data }));
                        props?.update(result.data?.data);
                    }
                } else {
                    const result = await addNewForums(formData);
                    if (result.status == 200) {
                        setSubmitting(true);
                        resetForm();
                        dispatch(addNewForumState(result.data?.data));
                        dispatch(setForumModalStatus(false));
                        props?.create(result.data?.data);
                    }
                }
            } catch (error) {
                if (error instanceof AxiosError) {
                    toast.error(error.response?.data?.responseMessage);
                }
                setSubmitting(false);
            }
        },
    });

    const closeModal = () => {
        dispatch(setForumModalStatus(false));
        dispatch(setFormDetail({}));
    }

    const handleChangeCompanyImage = async (e: any) => {
        const selectedFile = e.target.files[0];
        if(!ALLOWED_IMAGES.includes(selectedFile?.type)){
            toast.error(LANG.ONLY_JPG_PNG_IMAGES_ALLOWED);
            return;
        }
        if (checkFileSizeMoreThanTen(selectedFile)) {
            toast.error(LANG.MAXIMUM_FILE_SIZE_IS_TEN_MB);
        } else {
            const url = URL.createObjectURL(selectedFile);
            setImageUrl(url);
            forumFormik.setFieldValue('image', selectedFile);
        }
    };

    return (
        <Modal
            {...props}
            onHide={closeModal}
            show={forumModal}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <h2 className='fs-6'>{LANG.CREATE_FORUM}</h2>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={forumFormik.handleSubmit}>
                    <div className="row">
                        <div className='uploadImageWrapper mb-3'>
                            <div className='uploadImg'>
                                <VisibilityBox show={forumDetail?.image && !imageUrl}>
                                    <img src={fileUrl + forumDetail?.image} height={120} style={{ borderRadius: 20 }} 
                                    alt="forum logo" />
                                </VisibilityBox>
                                <VisibilityBox show={imageUrl}>
                                    <img src={imageUrl} height={120} style={{ borderRadius: 20 }} alt="forum logo" />
                                </VisibilityBox>
                            </div>
                            <div className='mt-2'>
                                <label className='uploaddBtn btn btn-sm btn-outline-primary' ><Form.Control accept='.png,.jpg,.jpeg' className='d-none' onChange={handleChangeCompanyImage} type="file" />{LANG.ADD_COVER}</label>
                            </div>
                        </div>
                        <div className="col-sm-12  col-md-12 mb-2">
                            <Form.Label>
                                {LANG.TITLE}
                            </Form.Label>
                            <Form.Group>
                                <input
                                    type="text"
                                    placeholder={LANG.TITLE}
                                    maxLength={1000}
                                    className={clsx(
                                        "form-control commonInput",
                                        { "border border-danger": forumFormik.touched.title && forumFormik.errors.title },
                                    )}
                                    {...forumFormik.getFieldProps("title")}
                                />
                            </Form.Group>
                            {(forumFormik.errors.title && forumFormik.touched.title) && (
                                <div className="formik-errors text-danger">{`${forumFormik.errors.title}`}</div>
                            )}
                        </div>
                        <div className="col-sm-12  col-md-12 mb-2">
                            <Form.Label>
                                {LANG.DESCRIPTION}
                            </Form.Label>
                            <Form.Group>
                                <textarea
                                    placeholder={LANG.DESCRIPTION}
                                    className={clsx(
                                        "form-control commonInput",
                                        { "border border-danger": forumFormik.touched.description && forumFormik.errors.description },
                                    )}
                                    {...forumFormik.getFieldProps("description")}
                                />
                            </Form.Group>
                            {(forumFormik.errors.description && forumFormik.touched.description) && (
                                <div className="formik-errors text-danger">{`${forumFormik.errors.description}`}</div>
                            )}
                        </div>
                    </div>
                    <div className='d-flex justify-content-end'>
                        <Button type='button' onClick={closeModal} variant='outline-secondary' className='me-2' >
                            {LANG.CANCEL}
                        </Button>
                        <Button type='submit' variant="primary" className='' >
                            {forumDetail?._id ? LANG.UPDATE : LANG.CONFIRM}
                        </Button>
                    </div>
                </form>
            </Modal.Body >
        </Modal >

    )
}

export default ForumModal;