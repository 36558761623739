import React, { useState } from 'react'
import { IForum } from '../../../data/interface/forum'
import { Link } from 'react-router-dom'
import moment from 'moment';
import VisibilityBox from '../../VisibilityBox';
import { Dropdown } from 'react-bootstrap';
import VThreeDots from '../../../../icons/VThreeDots';
import { getUserID } from '../../../../services/user.service';
import { LANG } from '../../../../constants/language';
import ForumComment from '../forum-comment/ForumComment';
import clsx from 'clsx';

interface Props{
    forum: IForum, 
    deleteForum(f:IForum):void,
    updateForum(f:IForum):void,
    voteForum(f:IForum):void,
    commentForum(f:IForum):void,
}
const ForumCard = ({forum, deleteForum, updateForum, voteForum,commentForum}:Props) => {
    const fileUrl = process.env.REACT_APP_FILE_URL;
    const userId = getUserID();

    const [enableComment, setEnableComment]= useState<boolean>(false);

    return (
        <div className="listing-item card mb-3" >
            <div>
                <div className="listing-venue-owner">
                    <div className="navigation d-flex justify-content-between">
                        <div className="d-flex mb-2">
                            <div>
                                <span >
                                    <img
                                        src={forum.createdBy?.avatar ? fileUrl + forum.createdBy?.avatar : "/assets/img/default-avatar.png"}
                                        alt="User" height={60} width={60} style={{ minWidth:45}}
                                    />
                                </span>
                            </div>
                            <div className="px-2">
                                <div className='w-100' style={{fontSize: 12}}>
                                    <span className="pe-3 text-capitalize"style={{fontSize: 12}}>{forum.createdBy?.firstName} {forum.createdBy?.lastName}</span>
                                    <i className="feather-calendar" />
                                    <span className="" style={{fontSize: 12, paddingLeft: 5}}>{moment(forum.createdAt).format("DD MMM YYYY HH:MM A")}</span>
                                </div>
                                <Link to={`/user/forum/${forum._id}`}>
                                    <h4 className='text-wrap text-break'>{forum?.title}</h4>
                                </Link>
                                
                            </div>
                        </div>
                        <div>
                            <VisibilityBox show={userId == forum.createdBy?._id}>
                                <Dropdown placement="bottom-end">
                                    <Dropdown.Toggle className="p-0 drop-toggle border-0 text-dark bg-transparent" id="dropdown-basic">
                                        <VThreeDots />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={() => updateForum(forum)}>{LANG.EDIT}</Dropdown.Item>
                                        <Dropdown.Item onClick={() => deleteForum(forum)}>{LANG.DELETE}</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </VisibilityBox>
                        </div>
                    </div>
                </div>
            </div>
            <div className="listing-content news-content p-2">
                <p>
                    {forum.description}
                </p>
            </div>    
            <VisibilityBox show={forum.image !== undefined}>
                <div className="listing-img">
                    <Link to={`/user/forum/${forum._id}`}>
                        <img
                            src={fileUrl + forum?.image}
                            className="img-fluid"
                            alt="Venue"
                            height={200}
                        />
                    </Link>
                </div>
            </VisibilityBox>
            <div className="listing-content news-content">
                {/* <p>
                    {forum.description}
                </p> */}
                <div className="listing-button read-new">
                    <ul className="nav">
                        <li>
                            <span className='cursor-pointer pt-1' onClick={() => voteForum(forum)}>
                                <i className={clsx("feather-heart",forum?.votes?.includes(userId) && "text-danger")} />
                                {forum.votes?.length||""}
                            </span>
                        </li>
                        <li>
                            <span className='cursor-pointer pt-1' onClick={() => setEnableComment(!enableComment)}>
                                <i className="feather-message-square" />
                                {forum.comments?.length||""}
                            </span>
                        </li>
                    </ul>
                    <span>

                    </span>
                </div>
            </div>
            {enableComment && <ForumComment forum={forum} commentForum={commentForum} /> }
        </div>
    )
}

export default ForumCard