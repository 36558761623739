import React, { useEffect, useState } from "react";
import { LANG } from "../../../../constants/language";
import { Table } from "react-bootstrap";
import "./style.scss";
import moment from "moment";
import { getSubscriptionsUser } from "../../../../services/plan.service";
import SponsorshipDetailModal from "../../sponsorship-detail/SponsorshipDetailModal";

export default function PastSponsershipTabContent() {
  const [show, setShow]= useState<boolean>(false);
  const [deal, setDeal]= useState<any>();
  const [deals, setDeals] = useState<any[]>([]);
  useEffect(() => {
    getAllData();
  }, []);

  const getAllData = async () => {
    try {
      const now = moment();
      const [dealDetail] = await Promise.all([getSubscriptionsUser({})]);
      console.log(dealDetail, "deals >>>>");
      if (dealDetail?.status == 200 && dealDetail.data?.data?.length) {
        let pastSponsorships= dealDetail.data?.data||[];
        pastSponsorships= pastSponsorships.filter((sp:any)=> moment(sp?.endDate).isBefore(now))
        setDeals(pastSponsorships);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const openDeal=(deal:any)=>{
    setShow(true);
    setDeal(deal);
  }

  return (
    <div className="dashboardTabContent">
      <div className="tableWrapper alerttable">
        <div className="title_Counter d-flex justify-content-between mb-3">
          <h4>{LANG.PAST_SPONSORSHIPS}</h4>
        </div>
        <div className="table-responsive scrollHeight">
          <Table className="">
            <thead>
              <tr>
                <th>{LANG.NAME}</th>
                <th>{LANG.PRICE}</th>
                <th>{LANG.START_DATE}</th>
                <th>{LANG.END_DATE}</th>
                <th>{LANG.details}</th>
              </tr>
            </thead>
            <tbody>
              {deals.length > 0 ? (
                deals.map((event: any, index: number) => (
                  <tr key={index}>
                  <td className="text-capitalize">{event?.name}</td>
                  <td className="text-capitalize">{event?.price}</td>
                  <td>
                    {moment(event?.startDate).format("DD MMM YYYY")}
                  </td>
                  <td>
                    {moment(event?.endDate).format("DD MMM YYYY")}
                  </td>
                  <td>
                    <span className="text-underline cursor-pointer" onClick={()=>openDeal(event)}>
                      {LANG.VIEW_DETAILS}
                    </span>
                  </td>
                </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={7} style={{ textAlign: "center" }}>
                    {LANG.NO_ANY_SPONSORING}
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </div>
      <SponsorshipDetailModal show={show} onHide={() => setShow(false)} deal={deal} />
    </div>
  );
}
