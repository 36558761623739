import moment from "moment";
import { companyUrl } from "./generate.constant";
import { LANG } from "../constants/language";
import { digitalCardImage } from "./digital";
export const generateTemplateForDoc = (userInfo: IUser) => {
  return `
     <table style='font-family: "Helvetica"; max-width: 600px; min-width:600px; width: 100%; margin: 50px auto; border-collapse: collapse;'>
      <tr>
      <td colspan="4">
        <h1 style='font-size: 22px;font-family: "Helvetica";font-weight:bold; '>F.C. Kickers-Viktoria 1910 e.V. Mühlheim a.M.</h1>
        <table style="width: 100%;border-collapse: collapse;">
          <tr>
            <td>
              <p style="margin: 5px 0; font-size: 14px;">Jugendabteilung Fussball </p>
              <p style="margin: 5px 0; font-size: 14px;">F.C. Kickers-Viktoria 1910 Mühlheim a.M </p>
              <p style="margin: 5px 0; font-size: 14px;">Postfach 1202 </p>
              <p style="margin: 5px 0; font-size: 14px;">63165 Mühlheim am Main </p>
            </td>
            <td style="text-align: right;">
              <img src="${companyUrl}" alt="logo"/>
            </td>
          </tr>
          <tr>
            <td colspan="2" style="letter-spacing: 1.5; padding-top: 50px; text-align: center; font-size: 18px;">
              <h2>Beitrittserklärung </h2>
            </td>
          </tr>
          <tr>
            <td colspan="2">
              <strong style="font-size: 14px; font-weight: 600;">Mit meiner Unterschrift erkläre ich meinen Beitritt zum Fußballverein F.C. Kickers-Viktoria
              1910 e.V. Mühlheim a.M. </strong>
            </td>
          </tr>
        </table> 
        <table style="width: 100%;border-collapse: collapse;">
          <tr>
            <td style="padding: 10px 0; font-size: 14px; width: 15%;">Nachname: </td>
            <td style="padding: 10px 0; width: 30%;"> ${userInfo?.lastName}</td>
            <td style="padding: 10px; font-size: 14px; width: 15%">Vorname: </td>
            <td style="padding: 10px 0; width: 30%; "> ${userInfo?.firstName}</td>
          </tr>
          <tr>
            <td style="padding: 10px 0; font-size: 14px; width: 15%;">Straße: </td>
            <td style="padding: 10px 0; width: 30%;">${userInfo?.houseNumber}, ${userInfo?.street} </td>
            <td style="padding: 10px; font-size: 14px; width: 15%;">PLZ, Ort: </td>
            <td style="padding: 10px 0; width: 30%;"> ${userInfo?.zipCode}, ${userInfo?.city}</td>
          </tr>
          <tr>
            <td style="padding: 10px 0; font-size: 14px; width: 15%;">Geb.-Datum: </td>
            <td style="padding: 10px 0; width: 30%;"> ${moment(userInfo.dob).format("DD/MM/YYYY")}</td>
            <td style="padding: 10px; font-size: 14px; width: 15%;">Geburtsort: </td>
            <td style="padding: 10px 0; width: 30%;"> ${userInfo?.birthPlaceCity}</td>
          </tr>
          <tr>
            <td style="padding: 10px 0; font-size: 14px; width: 15%;">Email: </td>
            <td style="padding: 10px 0;  width: 30%;"> ${userInfo?.email}</td>
            <td style="padding: 10px; font-size: 14px; width: 15%;">Eintrittsdatum: </td>
            <td style="padding: 10px 0;  width: 30%;"> ${moment(userInfo.joinedAt).format("DD/MM/YYYY")}</td>
          </tr>
        </table>
        <table style="width: 100%;border-collapse: collapse;">
          <tr>
            <td colspan="3" style=" font-size: 16px;">
              <h3>Derzeit gültige Beiträge: </h3>
            </td>
          </tr>
          <tr>
            <td style="font-size: 14px; width:40%;">${userInfo.subscription?.plan?.title} (${userInfo.subscription?.plan?.type})</td>
            <td style="white-space: nowrap; font-size: 14px; width:60%;">${userInfo.subscription?.plan?.price} EUR</td>
          </tr>
          <tr>
            <td colspan="3" style="color: #ff0000; padding-top: 20px; font-size: 14px;">Aufnahmegebühr 35,00 EUR (einmalig):</td>
          </tr>  
          <tr>
            <td colspan="3" style="font-size: 14px;">Passantrag Fußballverband, Trainingsoutfit: Shirt, Hose und Stutzen </td>
          </tr>
          <tr>
            <td colspan="3" style="color: #ff0000; padding-top: 20px; font-size: 14px;">Die Aufnahmegebühr ist bei Antragstellung zu entrichten. </td>
          </tr>  
          <tr>
            <td colspan="2" style="padding-top: 20px;">
              <ul style="margin: 0; padding: 0 0 0 15px;">
                <li><strong style="line-height: 24px; font-size: 14px;">Der Beitragseinzug findet stets zum 01.02. oder 01.03 eines Kalenderjahres statt</strong></li>
              </ul>
            </td>
          </tr>
          <tr>
            <td colspan="2">
              <ul style="margin: 0; padding: 0 0 0 15px;">
                <li><strong style="line-height: 24px; font-size: 14px;">Bei unterjährigem Beitritt wird der Beitrag anteilig für das Kalenderjahr erhoben.</strong></li>
              </ul>
            </td>
          </tr>
          <tr>
            <td colspan="2">
              <ul style="margin: 0; padding: 0 0 0 15px;">
                <li><strong style="line-height: 24px; font-size: 14px;">Eine Kündigung muss schriftlich, mindestens 3 Monate vor Ablauf des Kalenderjahres erfolgen.</strong></li>
              </ul>
            </td>
          </tr>
          <tr>
            <td colspan="2" style="padding: 10px 0 30px 0; font-size: 14px;">Mit Beitritt zum KV Mühlheim e.V., erkenne ich die aktuell gültige Satzung an. Diese kann mir auf
              Verlangen, postalisch oder elektronisch zugestellt werden. </td>
          </tr>
        </table>
        <table style="width: 100%;border-collapse: collapse; padding-bottom: 15px;">
          <tr>
            <td style="padding: 10px 0; font-size: 14px;">Ort, Datum: </td>
            <td style="padding: 10px 0; ">${userInfo?.city}, ${moment(userInfo.joinedAt).format("DD MMM YYYY")}</td>
            <td style="padding: 10px 0; font-size: 14px;">Unterschrift: </td>
            <td style="padding: 10px 0; ">
                    <img src="${userInfo.accountHolderESignature}" style="height:40px;object-fit:cover;" alt="signature" />
              </td>
          </tr>
        </table>
        <table style="width: 100%;border-collapse: collapse; border-top: 1px solid #000;margin-bottom:160px;">
          <tr>
            <td style=" padding: 0 0 0 50px;">
              <p style="margin: 0; padding: 3px 0; font-size: 10px;">E-Mail: info@kv-muehlheim.de</p>
              <p style="margin: 0; padding: 3px 0;  font-size: 10px;">Internet: www.kv-muehlheim.de</p>
              <p style="margin: 0; padding: 3px 0;  font-size: 10px;">Steuernummer.: 35 250 12356</p>
            </td>
            <td>
              <p style="margin: 0; padding: 3px 0;  font-size: 10px;">F.C. KV Mühlheim a.M..</p>
              <p style="margin: 0; padding: 3px 0;  font-size: 10px;">Postfach: 1202</p>
              <p style="margin: 0; padding: 3px 0;  font-size: 10px;">63165 Mühlheim</p>
            </td>
            <td>
              <p style="margin: 0; padding: 3px 0;  font-size: 10px;">Sparkasse Langen-Seligenstadt</p>
              <p style="margin: 0; padding: 3px 0;  font-size: 10px;">IBAN: DE29 5065 2124 0008 0832 89</p>
              <p style="margin: 0; padding: 3px 0;  font-size: 10px;">BIC: HELADEF1SLS</p>
            </td>            
          </tr>
        </table>

        <table  style="width: 100%;border-collapse: collapse;">
        <tr>
        <td>
        <div class="">
          <h1 style='font-size: 22px;font-family: "Helvetica";'>F.C. Kickers-Viktoria 1910 e.V. Mühlheim a.M.</h1>
        </div>
        </td>
        </tr>
        </table>
      <table style="width: 100%;border-collapse: collapse;">
        <tr>
          <td>
            <p style="margin: 5px 0; font-size: 14px;">Jugendabteilung Fussball</p>
            <p style="margin: 5px 0; font-size: 14px;">F.C. Kickers-Viktoria 1910 Mühlheim a.M. </p>
            <p style="margin: 5px 0; font-size: 14px;">Postfach 1202 </p>
            <p style="margin: 5px 0; font-size: 14px;">63165 Mühlheim am Main </p>
          </td>
          <td style="text-align: right;">
            <img src="${companyUrl}" alt="logo"/>
          </td>
        </tr>
        <tr>
          <td colspan="2" style="padding-top: 50px; font-size: 18px; text-align: center;">
            <h2>SEPA-Einzugsermächtigung </h2>
          </td>
        </tr>
        <tr>
          <td style=" font-size: 14px;"><strong>Gläubigeridentifizierungsnummer: </strong></td>
          <td style=" font-size: 14px;">DE29 5065 2124 0008 0832 89</td>
        </tr>
        <tr>
          <td style=" font-size: 14px;"><strong>Mandatsreferenznummer: </strong></td>
          <td style=" font-size: 14px;">(wird ihnen separat mitgeteilt)</td>
        </tr>
      </table> 
      <table style="width: 100%;border-collapse: collapse; margin-top: 15px;">
        <tr>
          <td><strong style="border-bottom: 1px solid #000;font-size: 14px;">SEPA-Lastschriftmandat</strong></td>
        </tr>
        <tr>
          <td style="padding-bottom: 20px;font-size: 14px;">Hiermit ermächtige Ich / Wir den Zahlungsempfänger FC KV Mühlheim e.V. Zahlungen von
            meinem / unserem Konto mittels Lastschrift einzuziehen. Zugleich weise ich mein / weisen wir
            unserem Kreditinstitut an, die vom Zahlungsempfänger FC KV Mühlheim e.V. auf mein / unser Konto
            gezogenen Lastschriften einzulösen. </td>
        </tr>
        <tr>
          <td><strong style="border-bottom: 1px solid #000;"font-size: 14px;>Hinweis:</strong></td>
        </tr>
        <tr>
          <td style="padding-bottom: 20px;font-size: 14px;">
            Ich kann / Wir können innerhalb von acht Wochen, beginnend mit dem Belastungsdatum, die Erstattung des belasteten Betrages verlangen. Es gelten dabei, die mit meinem/unseren Kreditinstitut vereinbarten Bedingungen.
          </td>
        </tr>
      </table>
      <table style="width: 100%;border-collapse: collapse; margin-top: 15px;">
        <tr>
          <td style="min-width:30%;">Name des Mitgliedes:</td>
          <td colspan="2" style="min-width:70%;">${userInfo.firstName} ${userInfo.lastName}</td>
        </tr>
        <tr>
          <td style="min-width:30%;">Name des Kreditinstitutes: </td>
          <td colspan="2" style="min-width:70%;">${userInfo.bankName}</td>
        </tr>
        <tr>
          <td style="min-width:30%;">IBAN: </td>
          <td colspan="2" style="min-width:70%;">${userInfo.iban}</td>
        </tr>
        <tr>
          <td style="min-width:30%;">BIC: </td>
          <td colspan="2" style="min-width:70%;">
          ${userInfo.bic}</td>
        </tr>
        <tr>
          <td style="min-width:30%;">Name des Kontoinhabers: </td>
          <td colspan="2" style="min-width:70%;">
          ${userInfo.accountHolder}</td>
        </tr>
        <tr>
          <td style="min-width:30%;">Ort, Datum: </td>
          <td colspan="2" style="min-width:70%;">
          ${userInfo.city}, ${moment(userInfo.joinedAt).format("DD MMM YYYY")}
          </td>
        </tr>
        <tr>
          <td style="min-width:30%;">Unterschrift des Kontoinhabers:</td>
          <td colspan="2" style="min-width:70%;">
          <img src="${userInfo.accountHolderESignature}" style="height:40px;object-fit:cover;" alt="signature" /></td>
        </tr>
      </table>
      <table style="width: 100%;border-collapse: collapse; border-top: 1px solid #000; margin-top: 50px;">
        <tr>
          <td style=" padding: 0 0 0 50px;">
            <p style="margin: 0; padding: 3px 0; font-size: 10px;">E-Mail: info@kv-muehlheim.de</p>
            <p style="margin: 0; padding: 3px 0;  font-size: 10px;">Internet: www.kv-muehlheim.de</p>
            <p style="margin: 0; padding: 3px 0;  font-size: 10px;">Steuernummer.: 35 250 12356</p>
          </td>
          <td>
            <p style="margin: 0; padding: 3px 0;  font-size: 10px;">F.C. KV Mühlheim a.M..</p>
            <p style="margin: 0; padding: 3px 0;  font-size: 10px;">Postfach: 1202</p>
            <p style="margin: 0; padding: 3px 0;  font-size: 10px;">63165 Mühlheim</p>
          </td>
          <td>
            <p style="margin: 0; padding: 3px 0;  font-size: 10px;">Sparkasse Langen-Seligenstadt</p>
            <p style="margin: 0; padding: 3px 0;  font-size: 10px;">IBAN: DE29 5065 2124 0008 0832 89</p>
            <p style="margin: 0; padding: 3px 0;  font-size: 10px;">BIC: HELADEF1SLS</p>
          </td>            
        </tr>
      </table>
    </td>
  </tr>
  </table>
    `;
}

export const generateTemplateForDigitalCard = (userInfo: IUser) => {
  return `
   <div style="background-image:url('${encodeURI(digitalCardImage)}');max-width: 312px;" >
   <table border="0" style="padding:20px;font-family: Inter-Regular;border:none; max-width: 312px; min-width:312px; width: 312px;z-index:1; ">
   
   <tr>
    <td>
      <h1 style="font-size: 22px; ">Digital Card</h1>
      <table style="width: 100%;border:none;">
        <tr>
          <td>
            <p style="margin: 5px 0; font-size: 14px;">${LANG.FIRST_NAME} : ${userInfo.firstName} </p>
            <p style="margin: 5px 0; font-size: 14px;">${LANG.LAST_NAME} : ${userInfo.lastName}  </p>
            <p style="margin: 5px 0; font-size: 14px;">${LANG.EMAIL} : ${userInfo.email}</p>
            <p style="margin: 5px 0; font-size: 14px;">${LANG.PHONE} : ${userInfo.phone}</p>
          </td>
        </tr>
      </table> 
  </td>
</tr>
</table>
</div>
  `;
}