import React, { useState } from 'react';
import { Card, Form, Button, Container, ListGroup } from 'react-bootstrap';
import PlusIcon from '../../../../icons/PlusIcon';
import { LANG } from '../../../../constants/language';
import { SelectMembersModal } from '../members-modal/page';
import VisibilityBox from '../../VisibilityBox';
import { toast } from 'react-toastify';
import { createTeam } from '../../../../services/teams.service';
import { AxiosError } from 'axios';

interface Props {
    back(): void,
    create(): void,
}

const CreateNewTeam = ({ back, create }: Props) => {
    const fileUrl = process.env.REACT_APP_FILE_URL;
    const [openMember, setOpenMember] = useState<boolean>(false);
    const [teamName, setTeamName] = useState<string>('');
    const [members, setMembers] = useState<any[]>([]);

    const selectMember = (selectedMembers: any[]) => {
        setMembers(selectedMembers)
    }

    const createTeamSubmit= async ()=>{
        try {
            const memberIds= members.map(member => member?._id);
            const result= await createTeam({members:memberIds,teamName});
            if(result.status == 200){
               setTeamName("");
               setMembers([]);
               create();
               toast.success(LANG.TEAM_CREATED_SUCCESSFULLY);
               back()
            }
        } catch (error) {
            if(error instanceof AxiosError){
                toast.error(error.response?.data?.responseMessage);
            }
        }
    }
    return (
        <>
            <Container className="mt-md-0 mt-sm-0 mt-lg-5 mt-xl-5 mt-xxl-5">
                {/* Breadcrumb */}
                <div className='my-2 breadcrumbWrap'>
                    <span className='text-link cursor-pointer' onClick={back} >{LANG.TEAM}</span> /
                    <span className='text-primary'> {LANG.CREATE_NEW_TEAM}</span>
                </div>

                {/* Create New Team Card */}
                <Card className="shadow-sm col-md-6 col-sm-12 p-0">
                    <Card.Body>
                        <Card.Title>{LANG.CREATE_NEW_TEAM}</Card.Title>

                        {/* Team Name Input */}
                        <Form.Group controlId="teamName">
                            <Form.Label className="sr-only">{LANG.ENTER_TEAM_NAME}</Form.Label>
                            <Form.Control className='commonInput' type="text" value={teamName} onChange={(ev)=>setTeamName(ev.target.value)} placeholder={LANG.ENTER_TEAM_NAME} />
                        </Form.Group>

                        {/* Select Member Button */}
                        <div className="d-flex justify-content-center mt-3 w-100">
                            <Button variant="outline-primary" className='w-100 blueButton' onClick={() => setOpenMember(true)}>
                                <PlusIcon /> {LANG.SELECT_MEMBER}
                            </Button>
                        </div>

                        <VisibilityBox show={members?.length > 0}>
                            <h6 className='my-2'>{LANG.SELECTED_MEMBER}</h6>
                            <ListGroup variant="flush" className="mb-3" style={{ maxHeight: '400px', overflowY: 'auto' }}>
                                {members.map((member: any) => (
                                    <ListGroup.Item
                                        key={member._id}
                                        className={`d-flex align-items-center ${members.includes(member) ? 'bg-light' : ''
                                            }`}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <img
                                            src={member?.avatar ? fileUrl + member?.avatar : "/assets/img/default-avatar.png"}
                                            alt={member.name}
                                            className="rounded-circle mr-3"
                                            style={{ width: '40px', height: '40px' }}
                                        />
                                        <span className="flex-grow-1 ms-2">{member.firstName || 'NA'} {member?.lastName}</span>
                                        
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>
                            <Button disabled={!teamName} className='w-100 blueButton' onClick={createTeamSubmit}>
                                 {LANG.SAVE}
                            </Button>

                        </VisibilityBox>
                    </Card.Body>
                </Card>
            </Container>
            <SelectMembersModal show={openMember} members={members} handleClose={() => setOpenMember(false)} select={selectMember} />
        </>
    )
}

export default CreateNewTeam;