import React from 'react'
import { useParams } from 'react-router-dom'
import VisibilityBox from '../../core/components/VisibilityBox';
import "./style.scss";
import HomeHeader from '../home/home-header/HomeHeader';
import HomeFooter from '../home/home-footer/HomeFooter';
import Vorstand from './verein/vorstand/Vorstand';
import Geschichte from './verein/geschichte';
import Mannschaft1 from './teams/mannschaft1';
import Mannschaft2 from './teams/mannschaft2';
import AlteHerren from './teams/alteHerren';
import U17B1 from './teams/u17b1';
import U14C1 from './teams/u14c1';
import U15C2 from './teams/u15c2';
import U12C3 from './teams/u12c3';
import U13D1 from './teams/u13d1';
import U12D2 from './teams/u12d2';
import U12D3 from './teams/u12d3';
import U11E1 from './teams/u11e1';
import U9F1 from './teams/u9f1';
import U10F2 from './teams/u10f2';
import U8F3 from './teams/u8f3';
import U9F4 from './teams/u9f4';
import U7G1 from './teams/u7g1';

const Webpages = () => {
    const params = useParams();
    return (
        <>
            <div className="">
                <HomeHeader />
                <VisibilityBox show={params?.page === 'vorstand'}>
                    <Vorstand />
                </VisibilityBox>
                <VisibilityBox show={params?.page === 'geschichte'}>
                    <Geschichte />
                </VisibilityBox>
                <VisibilityBox show={params?.page === 'mannschaft1'}>
                    <Mannschaft1 />
                </VisibilityBox>
                <VisibilityBox show={params?.page === 'mannschaft2'}>
                    <Mannschaft2 />
                </VisibilityBox>
                <VisibilityBox show={params?.page === 'alte_herren'}>
                    <AlteHerren />
                </VisibilityBox>
                <VisibilityBox show={params?.page === 'u17_b1'}>
                    <U17B1 />
                </VisibilityBox>
                <VisibilityBox show={params?.page === 'u14_c1'}>
                    <U14C1 />
                </VisibilityBox>
                <VisibilityBox show={params?.page === 'u15_c2'}>
                    <U15C2 />
                </VisibilityBox>
                <VisibilityBox show={params?.page === 'u12_c3'}>
                    <U12C3 />
                </VisibilityBox>
                <VisibilityBox show={params?.page === 'u13_d1'}>
                    <U13D1 />
                </VisibilityBox>
                <VisibilityBox show={params?.page === 'u12_d2'}>
                    <U12D2 />
                </VisibilityBox>
                <VisibilityBox show={params?.page === 'u12_d3'}>
                    <U12D3 />
                </VisibilityBox>
                <VisibilityBox show={params?.page === 'u11_e1'}>
                    <U11E1 />
                </VisibilityBox>
                <VisibilityBox show={params?.page === 'u9_f1'}>
                    <U9F1 />
                </VisibilityBox>
                <VisibilityBox show={params?.page === 'u10_f2'}>
                    <U10F2 />
                </VisibilityBox>
                <VisibilityBox show={params?.page === 'u8_f3'}>
                    <U8F3 />
                </VisibilityBox>
                <VisibilityBox show={params?.page === 'u9_f4'}>
                    <U9F4 />
                </VisibilityBox>
                <VisibilityBox show={params?.page === 'u7_g1'}>
                    <U7G1 />
                </VisibilityBox>
            </div>
            <HomeFooter />
        </>
    )
}

export default Webpages