import React from 'react'
import { Link } from 'react-router-dom';
import InstagramBlueIcon from '../../../icons/InstagramBlueIcon';
import FacebookBlueIcon from '../../../icons/FacebookBlueIcon';
import YouTubeBlueIcon from '../../../icons/YouTubeBlueIcon';
import { useSelector } from 'react-redux';
import { HomePageSelector } from '../../../core/data/redux/webpage/webPageAction';
import { WebPage } from '../../../types/webpage';

const HomeFollow = () => {
  const homepage: WebPage = useSelector(HomePageSelector);
  return (
    <section className="followSection">
          <div className="container position-relative">
            <div className="row">
              <div className="col-md-2"></div>
              <div className="col-md-8">
                <div className="social">
                  <h2>{homepage?.home?.socialMedia?.subTitle}</h2>
                  <ul>
                    <li><Link to={homepage?.home?.socialMedia?.instagram||"/"} target='blank' relative={'path'}><InstagramBlueIcon /></Link></li>
                    <li><Link to={homepage?.home?.socialMedia?.facebook||"/"} target='blank' relative={'path'}><FacebookBlueIcon /></Link></li>
                    <li><Link to={homepage?.home?.socialMedia?.youtube||"/"} target='blank' relative={'path'}><YouTubeBlueIcon /></Link></li>
                  </ul>
                </div>
              </div>
              <div className="col-md-2"></div>
              <div className="col-md-12">
                <img className="Oskar_Mask" src={homepage?.home?.about?.image|| '/assets/img/Oskar_Mask.png'} alt='Oskar_Mask' />
              </div>
            </div>
          </div>
        </section>
  )
}

export default HomeFollow