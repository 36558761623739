import React from 'react'
import HomeHeader from '../home/home-header/HomeHeader';
import './style.scss';
import HomeFollow from '../home/home-follow/HomeFollow';
import HomeSubscribe from '../home/home-subscribe/HomeSubscribe';
import HomeFooter from '../home/home-footer/HomeFooter';
import { SponsorenPageSelector } from '../../core/data/redux/webpage/webPageAction';
import { useSelector } from 'react-redux';
import { WebPage } from '../../types/webpage';
import { Link } from 'react-router-dom';

const Sponsoren = () => {
    const sponsoren: WebPage = useSelector(SponsorenPageSelector);
    return (
        <div className='sponsorenPage headerBgLightBlue'>
            <div className='headerCover'>
                <HomeHeader />
            </div>
            <div className='contain1150'>
                <div className='row'>
                    <div className='col-sm-12 text-center my-lg-5 my-md-3 my-sm-3 my-3'>
                        <h2>{sponsoren?.sponsoren?.title}</h2>
                        <p>{sponsoren?.sponsoren?.subTitle}</p>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-sm-12'>
                        <ul className='sponsorenList mb-5'>
                            {sponsoren?.sponsoren?.sponsors?.map((item) => (
                                <li key={item?._id}>
                                   <Link to={item?.url||"#"} relative={'path'} target='_blank'>
                                    <img src={item?.image} className='img-thubnail' loading='lazy' onError={(e) => { 
                                    e.currentTarget.src = '/assets/img/default-avatar.png'; 
                                  }} alt={item?.name} style={{ maxHeight:135}} />
                                   </Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
            <HomeFollow />
            <HomeSubscribe />
            <HomeFooter />
        </div>
    )
}
export default Sponsoren;