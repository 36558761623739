import React, { memo, useEffect, useState } from 'react'
import { LANG } from '../../../constants/language'
import ExpandIcon from '../../../icons/ExpandIcon'
import ReactApexChart from 'react-apexcharts';
import _ from 'lodash';
import "./style.scss";
import clsx from 'clsx';
import moment from 'moment';
const OPTIONS= {
  labels: [LANG.PRESENT, LANG.NOT_PARTICIPATED,""],
  colors:["#FFE500","#1A80C7","#CCCCCC"],
  chart: {
    width: 280,
    type: 'donut',
  },
  dataLabels: {
    enabled: false
  },
  responsive: [{
    breakpoint: 480,
    options: {
      chart: {
        width: 200
      },
      legend: {
        show: false
      }
    }
  }],
  legend: {
    show: false,
    position: 'right',
    offsetY: 0,
    height: 230,
  },
}
interface Props{
  events:any[],
  expand():void
}
const EventReportCard = memo( function EventReportCard({events,expand}:Props){
  const [selectedType, setSelectedType] = useState<string>('match');
  const [filter, setFilter] = useState<any>('week');

  useEffect(()=>{
     //filter events
     if(events.length){
        const eventsByTypes = events?.filter(ev=>ev?.eventType==selectedType && moment(ev?.date).isSame(moment(), filter))||[];
        if(eventsByTypes?.length){
          const members:any[]= eventsByTypes.reduce((acc, cev:any)=>{
              acc=[...acc,...cev.participants];
              for(const t of cev.teams){
                acc.concat(t.users||[]);
              }
              return acc;
          },[]);
          // members= _.uniqBy(members,"user._id")
          const acceptedEvents = members?.filter(ev=>ev?.status=='accepted').length;
          const notAcceptedEvents = members.length - acceptedEvents;
          setState({...state, series: [acceptedEvents, notAcceptedEvents, 0]});
        }else{
          setState({...state, series: [0, 0, 1]})
        }
      } else {
        setState({...state, series: [0, 0, 1]})
     }
  },[selectedType,events.length,filter])

  const [state, setState] = React.useState<any>({
    series: [0, 0,0],
    options: OPTIONS
  });

  return (
    <div className='report rounded border mb-3'>
      <div className='d-flex justify-content-between m-3'>
        <h5 className=''>{LANG.EVENT_REPORT}</h5>
        <span className='cursor-pointer' onClick={()=>expand()}>
          <ExpandIcon />
        </span>
      </div>
      <div className='m-2'>
        <select value={selectedType} onChange={(ev) => setSelectedType(ev.target.value)} className='commonInput form-control p-3'>
          <option value='spiel'>Spiel</option>
          <option value='training'>Training</option>
          <option value='event'>Event</option>
        </select>
      </div>
      <div className='my-3'>
        <ReactApexChart
          options={state.options} series={state.series}
          type="donut" width={260} />
      </div>
      <div className='d-flex justify-content-around'>
        <span onClick={()=>setFilter('week')} 
        className={clsx('btn py-2 px-3',filter=='week'?'btn-primary':'btn-secondary')} >
          {LANG.WEEK}
        </span>
        <span onClick={()=>setFilter('month')} className={clsx('btn py-2 px-3',filter=='month'?'btn-primary':'btn-secondary')} >
        {LANG.MONTH}
        </span>
        <span onClick={()=>setFilter('year')} className={clsx('btn py-2 px-3',filter=='year'?'btn-primary':'btn-secondary')} >
        {LANG.ANNUAL}
        </span>
      </div>
      <div className='py-3 px-2'>
        <div className='d-flex justify-content-around' style={{ height: 45 }}>
          <span className='py-2 px-3 present rounded'>
            <span></span>
            {LANG.PRESENT}
          </span>
          <span className='py-2 px-3 not-present rounded'>
            <span></span>
            {LANG.NOT_PARTICIPATED}
          </span>
        </div>
      </div>
    </div>
  )
})

export default EventReportCard