import React, { useEffect, useState } from 'react'
import { Badge, Button, Card, Col, Form, Modal, Row } from 'react-bootstrap';
import Eyeopen from '../../../icons/Eyeopen';
import { LANG } from '../../../constants/language';
import moment from 'moment';
import "./style.scss";
import MemberShipIconBlock from '../../../icons/MemberShipIconBlock';
import TotalMembersIcon from '../../../icons/TotalMembersIcon';
import GroupFriendsIcon from '../../../icons/GroupFriendsIcon';
import { getMyFriends } from '../../../services/friends.service';
import { getAllTeamsByUserId } from '../../../services/teams.service';
import VisibilityBox from '../VisibilityBox';
import PlusIcon from '../../../icons/PlusIcon';
import { getAssignedTrainerById } from '../../../services/user.service';
const ProfileCard = (props: any) => {
    const fileUrl = process.env.REACT_APP_FILE_URL;
    const user = props?.user;
    const role = localStorage.getItem('role');
    const [friends, setFriends] = useState<any[]>([]);
    const [members, setMembers] = useState<any[]>([]);
    const [myTrainers, setMyTrainers] = useState<any[]>([]);
    const getFriends = async () => {
        try {
            const [friendData, memberData, assigned] = await Promise.all([
                getMyFriends({ id: user?._id }),
                getAllTeamsByUserId(user?._id),
                getAssignedTrainerById(user?._id)
            ]);
            setFriends(friendData?.data?.data);
            setMembers(memberData?.data?.data);
            if (assigned?.data) {
                setMyTrainers(assigned?.data);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if(props?.user?._id){
            getFriends();
        }
    }, [props?.user]);

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className='friendModal'
        >
            <Modal.Header closeButton className=' border-0 pb-0'>
            </Modal.Header>
            <Modal.Body>
                <Card>
                    <Card.Body className='p-0'>
                        <ul className="mb-3 d-flex gap-3 friendUsers">
                            <li>
                                <img
                                    src={user?.avatar ? fileUrl + user?.avatar : "/assets/img/default-avatar.png"}
                                    alt="Profile"
                                    width="120px"
                                    className='rounded-circle profileImg'
                                    height="120px"
                                />
                            </li>
                            <li>
                                <div className='d-flex justify-content-between'>
                                    <div>
                                        <div className='d-flex gap-3 align-items-center'>
                                            <h5 className="mb-0 text-capitalize">{user?.firstName || 'NA'} {user?.lastName}</h5>
                                            {role != 'trainer' && role != 'member' && (<Form.Check
                                                type="switch"
                                                id="custom-switch"
                                                label={LANG.PRIVATE}
                                            />)}
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <Badge pill className="me-2 text-capitalize">
                                                {user?.visibility=='public'?LANG.PUBLIC:LANG.PRIVATE}
                                            </Badge>
                                            {
                                                user?.onVocation && <Badge pill className=" me-2 text-capitalize" data-bs-toggle="tooltip"
                                                title={moment(user?.vocationDate).format("DD MMM YYYY")}>
                                                    {LANG.VOCATION}
                                                </Badge>
                                            }
                                        </div>
                                        <p className="mb-0">
                                            {user?.description || "-"}
                                        </p>
                                    </div>
                                </div>
                                <ul className='friendInfo'>
                                    <li>{LANG.MEMBER_ID}: {user?._id}</li>
                                    <li>{LANG.SEASON}: {moment().year()}/${moment().year() + 1}</li>
                                    <li>{LANG.JOINED}: {moment(user?.joinedAt).format("DD MMM YYYY")}</li>
                                    <li>{LANG.ASSIGNED_TRAINER}:
                                        {myTrainers?.length ? <>
                                            <span className="text-capitalize">{myTrainers[0]?.firstName} {myTrainers[0]?.lastName}</span>
                                            <VisibilityBox show={myTrainers?.length > 1}>
                                                <span className="fw-bold">
                                                    <PlusIcon />
                                                    {myTrainers?.slice(1).length}
                                                </span>
                                            </VisibilityBox>
                                        </> : "--"}
                                    </li>
                                    <li>{LANG.CONTACT}: {user?.phone}</li>
                                    <li> Email: {user?.email}</li>
                                </ul>
                            </li>
                            <li>
                                <span className='blueButton'>{LANG.FRIEND}</span>
                            </li>
                        </ul>
                    </Card.Body>
                </Card>
                <Card className='mt-3'>
                    <Card.Body className='p-0'>
                        <div className='row'>
                            <div className='col-lg-4 pe-2'>
                                <div className='blockFriend'>
                                    <div className=''>
                                        <h2>--</h2>
                                        <p className='mb-0'>{LANG.MEMBERSHIP_EXPIRE}</p>
                                    </div>
                                    <div className=''>
                                        <MemberShipIconBlock />
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 ps-2 pe-2'>
                                <div className='blockFriend'>
                                    <div className=''>
                                        <h2>{members?.length}</h2>
                                        <p className='mb-0'>{LANG.TOTAL_TEAM_MEMBER}</p>
                                    </div>
                                    <div className=''>
                                        <TotalMembersIcon />
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 ps-2'>
                                <div className='blockFriend'>
                                    <div className=''>
                                        <h2>{friends?.length}</h2>
                                        <p className='mb-0'>{LANG.FRIENDS}</p>
                                    </div>
                                    <div className=''>
                                        <GroupFriendsIcon />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
            </Modal.Body>
        </Modal>
    )
}

export default ProfileCard;
