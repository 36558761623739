import { BlockData, Sponsors } from "../core/data/interface/model";
import { LANG } from "./language";

export const MATCH_TYPE = ['Sonstiges','Erstmalige Spielerlaubnis', 'Vereinswechsel'];

export const ARGENTINA_NATIONALITY = ['Argentinean', 'Brazilian', 'Mexican', 'American'];

export const ISTUPNICA_OR_BRISOVNICA_NATIONALITY = ['Bosnian', 'North Korean', 'South Korean', 'Macedonian', 'Montenegro', 'Serbian'];


export const TAB_MENU = [
    { id: 1, name: LANG.ACCOUNT_SETTING, eventKey: 'accountSetting', icon: 'AccountSetting' },
    { id: 2, name: LANG.CHANGE_PASSWORD, eventKey: 'changePassword', icon: 'ChangePassword' },
    { id: 3, name: LANG.SUBSCRIPTION, eventKey: 'subscription', icon: 'Subscription' },
]
export interface INTERFACE_TAB_MENU {
    id: number;
    name: string;
    eventKey: string;
    icon: string;
}


export const BLOOD_GROUP_LIST = [
    "A+", "A-", "B+", "B-", "AB+", "AB-", "O+", "O-"
]

export const FILE_EXT = ".pdf,.doc,.docx";


export const GENDERS = ['männlich', 'weiblich', 'divers'];


export const EVENTS_STATUS = [
    { value: "scheduled", label: "Scheduled" },
    { value: "completed", label: "Completed" },
    { value: "canceled", label: "Canceled" },
];

export const EVENTS_TYPE = [
    { value: "spiel", label: "Spiel" },
    { value: "training", label: "Training" },
    { value: "event", label: "Event" },
];

export const EVENTS_DURATION = [
    { value: "one_day", label: "One Day" },
    { value: "one_week", label: "One Week" },
    { value: "two_week", label: "Two Week" },
    { value: "three_week", label: "Three Week" },
    { value: "one_month", label: "One Month" },
];


export const TIMES_DURATION = [
    { value: "1", label: "1 Hr", min: "60" },
    { value: "2", label: "2 Hrs", min: "120" },
    { value: "3", label: "3 Hrs", min: "180" },
    { value: "4", label: "4 Hrs", min: "240" },
    { value: "5", label: "5 Hrs", min: "300" },
    { value: "6", label: "6 Hrs", min: "360" },
    { value: "7", label: "7 Hrs", min: "420" },
    { value: "8", label: "8 Hrs", min: "480" },
];

export const ROOM_TYPES = [
    { value: "field", label: "Field" },
    { value: "room", label: "Room" }
];

export const PARTICIPANTS_TYPES = [
    { value: "individual", label: "Individual participants" },
    { value: "team", label: "Teams" }
];

export const EVENTS_DURATION_OBJ: any = {
    "one_day": "One Day",
    "one_week": "One Week",
    "two_week": "Two Week",
    "three_week": "Three Week",
    "one_month": "One Month",
}

export const ALLOWED_IMAGES = ['image/png', 'image/jpg', 'image/jpeg'];

export const ROLE_OBJ:any = {
    member: "user",
    sponsor: "sponsor",
    trainer: "trainer"
}


export const SPONSORS: Sponsors[] = [
    { id: 1, imgSrc: '/assets/img/sponsors/Schweikard.png', },
    { id: 2, imgSrc: '/assets/img/sponsors/Engelhardt.png', },
    { id: 3, imgSrc: '/assets/img/sponsors/Volksbank Maingau.png',},
    { id: 4, imgSrc: '/assets/img/sponsors/S.K.C.png',},
    { id: 6, imgSrc: '/assets/img/sponsors/GM.png', },
    { id: 7, imgSrc: '/assets/img/sponsors/Wiener Feinbäckerei.png', },
    { id: 8, imgSrc: '/assets/img/sponsors/Ekipp.de.png', },
    { id: 9, imgSrc: '/assets/img/sponsors/areal infra.png', },
    { id: 10, imgSrc: '/assets/img/sponsors/AR-Tim Montage.png', },
    { id: 11, imgSrc: '/assets/img/sponsors/Gordanomobile.png', },
    { id: 12, imgSrc: '/assets/img/sponsors/mecklenburgische.de.png', },
    { id: 13, imgSrc: '/assets/img/sponsors/Fraport.png', },
    { id: 14, imgSrc: '/assets/img/sponsors/Bauhaus.png', },
    { id: 15, imgSrc: '/assets/img/sponsors/Tillmann.png', },
    { id: 16, imgSrc: '/assets/img/sponsors/Kinnel.png', },
    { id: 17, imgSrc: '/assets/img/sponsors/glock-bestattungen.de.png', },
    { id: 18, imgSrc: '/assets/img/sponsors/Seecafe.png', },
    { id: 19, imgSrc: '/assets/img/sponsors/Zahnarzt.png', },
    { id: 20, imgSrc: '/assets/img/sponsors/Sparkasse.png', },
    { id: 21, imgSrc: '/assets/img/sponsors/Loccos.png', },
    { id: 22, imgSrc: '/assets/img/sponsors/GN Gutachter.png', },
    { id: 23, imgSrc: '/assets/img/sponsors/Lexmann.png', },
    { id: 24, imgSrc: '/assets/img/sponsors/Best.png', },
    { id: 25, imgSrc: '/assets/img/sponsors/Promobedarf.png', },
    { id: 26, imgSrc: '/assets/img/sponsors/Alexandrion.png', },
    { id: 27, imgSrc: '/assets/img/sponsors/Schlappeseppel.png', },
    { id: 28, imgSrc: '/assets/img/sponsors/Audi.png', },
  ];

  export const BLOCKDATA: BlockData[] = [
    { id: 1, imgSrc: '/assets/img/profiles/avatar-06.jpg', title: 'Lorem Ipsum Dolor', description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry', },
    { id: 2, imgSrc: '/assets/img/profiles/avatar-07.jpg',  title: 'Lorem Ipsum Dolor',  description: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium.', },
    { id: 3, imgSrc: '/assets/img/profiles/avatar-08.jpg',  title: 'Lorem Ipsum Dolor', description: 'Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.', },
    { id: 4, imgSrc: '/assets/img/profiles/avatar-04.jpg', title: 'Lorem Ipsum Dolor', description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry' },
    { id: 5, imgSrc: '/assets/img/profiles/avatar-05.jpg', title: 'Lorem Ipsum Dolor', description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry' },
    { id: 6, imgSrc: '/assets/img/profiles/avatar-06.jpg', title: 'Lorem Ipsum Dolor', description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry' },
  ];

  export const status_translation:any = {
    pending:"ausstehend",
    accepted: "akzeptiert",
    rejected: "abgelehnt"
}