import React, { useEffect, useRef } from 'react'
import './style.scss';
import { FormGroup, Nav, Tab, Table } from 'react-bootstrap';
import HomeFollow from '../../../home/home-follow/HomeFollow';
import HomeSubscribe from '../../../home/home-subscribe/HomeSubscribe';
import { WebPage } from '../../../../types/webpage';
import { useSelector } from 'react-redux';
import { AlteHerrenPageSelector } from '../../../../core/data/redux/webpage/webPageAction';
import { Link } from 'react-router-dom';
export default function AlteHerren() {
    const alteHerren: WebPage = useSelector(AlteHerrenPageSelector);
        const fussballRef: any = useRef<HTMLDivElement>(null);
        useEffect(() => {
            if (alteHerren?.alteHerren?.fussballScript!==undefined) {
                fussballRef.current.innerHTML = alteHerren?.alteHerren?.fussballScript;
                const script = document.createElement('script');
                script.src = 'https://www.fussball.de/widgets.js'; // URL to the widget script
                script.async = true;
                script.defer = true;
                document.body.appendChild(script);
                return () => {
                    document.body.removeChild(script);
                };
            }
        }, [alteHerren?.alteHerren?.fussballScript])
    return (
        <div className='teamsPage BgLightBlue'>
            <div className='teamsPageContent'>
                <div className='singleTeam'>
                    <div className='row'>
                        <div className='col-sm-7'>
                            <div className='teamContent'>
                                <h1 className='pb-4'>{alteHerren?.alteHerren?.title}</h1>
                                <FormGroup className='mb-5'>
                                    <h4>{alteHerren?.alteHerren?.trainerName}</h4>
                                    <p>Trainer - <Link to={"/verein/vorstand"}>{alteHerren?.alteHerren?.contactPerson}</Link></p>
                                </FormGroup>
                                <FormGroup className='mb-5'>
                                    <h4>Trainingszeiten:</h4>
                                    <p>{alteHerren?.alteHerren?.trainingTimes}</p>
                                </FormGroup>
                            </div>
                        </div>
                        <div className='col-sm-5'>
                            <div className='teamImg'>
                                <img src={alteHerren?.alteHerren?.trainerImage||'/assets/img/default-avatar.png'} onError={(e) => { 
                                    e.currentTarget.src = '/assets/img/default-avatar.png'; 
                                  }} alt='avatar-06' />
                            </div>
                        </div>
                    </div>
                </div>
                <div ref={fussballRef}>
                </div>
            </div>
            <HomeFollow />
            <HomeSubscribe />
        </div>
    )
}
