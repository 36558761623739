import React from 'react'
import { Modal } from 'react-bootstrap'
import { LANG } from '../../../constants/language'
import moment from 'moment'

const SponsorshipDetailModal = (props: any) => {
    const deal= props?.deal;
    return (
        <Modal
            {...props}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className='eventDetailModal'
        >
            <Modal.Header closeButton className=' border-0 pb-0'>
                <Modal.Title id="contained-modal-title-vcenter">
                    {LANG.PAST_SPONSORSHIPS}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className='p-3'>
                <div className='row mb-2'>
                    <div className='col-6'>
                        <span className='fw-bold'>{LANG.NAME}</span>
                    </div>
                    <div className='col-6'>
                        <span className='px-2 text-capitalize'>{deal?.name}</span>
                    </div>
                </div>
                <div className='row mb-2'>
                    <div className='col-6'>
                        <span className='fw-bold'>{LANG.PRICE}</span>
                    </div>
                    <div className='col-6'>
                        <span className='px-2'>{deal?.price} €</span>
                    </div>
                </div>
                <div className='row mb-2'>
                    <div className='col-6'>
                        <span className='fw-bold'>{LANG.START_DATE}</span>
                    </div>
                    <div className='col-6'>
                        <span className='px-2'>{moment(deal?.startDate).format("DD MMM YYYY")}</span>
                    </div>
                </div>
                <div className='row mb-2'>
                    <div className='col-6'>
                        <span className='fw-bold'>{LANG.END_DATE}</span>
                    </div>
                    <div className='col-6'>
                        <span className='px-2'>{moment(deal?.endDate).format("DD MMM YYYY")}</span>
                    </div>
                </div>

                <div className='row mb-2'>
                    <div className='col-6'>
                        <span className='fw-bold '>{LANG.PLAN}</span>
                    </div>
                    <div className='col-6'>
                        <span className='text-capitalize ms-2'>{deal?.plan?.type}</span>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default SponsorshipDetailModal;