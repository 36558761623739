import React from "react";

const LocationIcon = () => {
  return (
    <svg
      width="24"
      height="27"
      viewBox="0 0 24 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <rect width="24" height="27" fill="url(#pattern0_21_3)" />
      <defs>
        <pattern
          id="pattern0_21_3"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use href="#image0_21_3" transform="scale(0.0416667 0.037037)" />
        </pattern>
        <image
          id="image0_21_3"
          width="24"
          height="27"
          href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAbCAYAAABm409WAAACAUlEQVRIDZ1W0VHDMAzNCIzACHz2GukuI7ABbAAb0A3oBu0GdAO6AWzQbtBuUHhBz5Ecx03hLmfHenqSpaeUpqn8dV1317btk4h8iMiXql7wYI8z2ICpUJRNcBKRN1U9kbSynoCdHcjIfbZHVd2JyEpEOntWdgZbutXVIACo6sE5rWtOlsyaePjW8I2IfBv4jEzLBRyfAquqZ/iiP2NE04Ac1+6vq6qPRVDlED70B1eAWmnY0G0w2styuXyAavBgX8KgLxbkFEqlqs9mOAeDsYiIrzNv+Z4HsUT7UoEz2VV1iwC/tdynwwI5eiQiXjnrAn5vyQ6VALEFCLVbLBb3BkbWKSN340t+Y/YyJOuyCs01deBmxzxTp5qgNhf8kHycPIsBMNEJbJtKgF5N4Ew+UyXKmrahA3uGIHNLRJXsSMK1bdtX1wcqqF9hI44rpRpmAbomCRpLMFcLQvmB/Fwi96IYzQobHSIzgq1wGjk6jFPQSBSNL0XpFo6nuPXZe0kHMNUkIp/BMOPFCWVQT+5H3dvQjaY0x/Pdf0rAwfPi6ks1eVXn6QbrUmq8gw5bp/PwiRgQfztPDp/cXn1nP0y+6TtEJ08eppaAaysm1AcRkRf6+DICk08zcbPWrFwb/LvCoby5LFMRvUpIjrMp/L/O7TcXnws84av7L8KSE2p9a71/AIGMgCaS6QzcAAAAAElFTkSuQmCC"
        />
      </defs>
    </svg>
  );
};

export default LocationIcon;
