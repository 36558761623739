import React, { useEffect, useState } from "react";
import { Nav, Tab } from "react-bootstrap";
import "./style.scss";
import DashboardIcon from "../../../icons/DashboardIcon";
import ProfileBlackIcon from "../../../icons/ProfileBlackIcon";
import ProfileTabContent from "../../../core/components/profile-setting/page";
import { getUserById } from "../../../services/user.service";
import { LANG } from "../../../constants/language";
import { useNavigate, useSearchParams } from "react-router-dom";
import ActiveSponsershipTabContent from "../../../core/components/sponsor/activeSponsershipTabContent/page";
import PastSponsershipTabContent from "../../../core/components/sponsor/pastSponsershipTabContent copy/page";
import ActiveSponsorIcon from "../../../icons/ActiveSponsorIcon";
import PastSponsorIcon from "../../../icons/PastSponsorIcon";
import SlotsTabContent from "../../../core/components/sponsor/slotsTabContent/page";
import queryString from "query-string";
import SponsorDashboardTabContent from "../../../core/components/sponsor/dashboardTabContent/page";
import HandshakeIcon from "../../../icons/HandshakeIcon";
import SlotsIcon from "../../../icons/SlotsIcon";
export default function SponsorTab() {
  const [userDetail, setUserDetail]= useState<any>();
  const [params]= useSearchParams();
  const [activeKey, setActiveKey]= useState<string>(params.get('tab')||'dashboard');
  const navigate= useNavigate();
  const { tabKey = "accountSetting" }: any = queryString.parse(location.search);
  useEffect(()=>{
    getUserDetails();
  },[]);

  useEffect(()=>{
     if(params.get('tab')){
      setActiveKey(params.get('tab')||'dashboard')
     }
  },[params.get('tab')])

  const getUserDetails = async () => {
    try {
      const result = await getUserById();
      setUserDetail(result?.data?.data);
    } catch (error) {
      console.error(error);
    }
  }

  const navigateToTab=(tab:string)=>{
    navigate(`/sponsor/sponsor-dashboard?tab=${tab}${tab === "profile" ? `&tabKey=${tabKey}` : ""}`
  );
    setActiveKey(tab)
  }
  return (
    <div>
      <Tab.Container id="left-tabs-example" activeKey={activeKey} defaultActiveKey="dashboard">
        <Nav variant="pills" className="tabWrapper">
          <Nav.Item onClick={()=>navigateToTab('dashboard')}>
            <Nav.Link eventKey="dashboard">
              <DashboardIcon />
              <h5 className="mb-0">{LANG.DASHBAORD}</h5>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item onClick={()=>navigateToTab('active')}>
            <Nav.Link eventKey="active">
            <ActiveSponsorIcon/>
              <h5 className="mb-0">{LANG.ACTIVE_SPONSORSHIPS}</h5>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item onClick={()=>navigateToTab('past')}>
            <Nav.Link eventKey="past">
            <SlotsIcon />
              <h5 className="mb-0">{LANG.PAST_SPONSORSHIPS}</h5>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item onClick={()=>navigateToTab('slots')}>
            <Nav.Link eventKey="slots">
             <HandshakeIcon />
              <h5 className="mb-0">{LANG.SLOTS}</h5>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item  onClick={()=>navigateToTab('profile')}>
            <Nav.Link eventKey="profile">
              <ProfileBlackIcon />
              <h5 className="mb-0">{LANG.PROFILE}</h5>
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <Tab.Content>
          <Tab.Pane eventKey="dashboard">
            <SponsorDashboardTabContent />
          </Tab.Pane>
          <Tab.Pane eventKey="active">
            <ActiveSponsershipTabContent />
          </Tab.Pane>
          <Tab.Pane eventKey="past">
            <PastSponsershipTabContent />
          </Tab.Pane>
          <Tab.Pane eventKey="slots">
            <SlotsTabContent />
          </Tab.Pane>
          <Tab.Pane eventKey="profile">
            <ProfileTabContent userDetail={userDetail}/>
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </div>
  );
}
