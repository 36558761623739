import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { LANG } from "../../../../constants/language";
import PlusIcon from "../../../../icons/PlusIcon";
import VisibilityBox from "../../VisibilityBox";
import ProfileCard from "../../profile-card/page";
import CalendarIcon from "../../../../icons/CalendarIcon";
import CreateNewEvent from "../create-new-event/page";
import { Dropdown, Table } from "react-bootstrap";
import EventCalendar from "../event-calendar/EventCalendar";
import { deleteEventById, getAllMyEvents, getAllMyEventsWithSlots } from "../../../../services/event.service";
import VThreeDots from "../../../../icons/VThreeDots";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  setEditEventModalStatus,
  setFormDetail,
} from "../../../data/redux/shared/sharedSlice";
import EditEventModalDetails from "../edit-event-modal/page";
import { ConfirmDeleteModal } from "../delete-modal/page";
import { AxiosError } from "axios";
import { toast } from "react-toastify";
import EventDetailModal from "../../event-detail-modal/EventDetailModal";
import { checkEventAccpeted } from "../../../../utils";

export default function TrainerEventTabContent() {
  const [params, setParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const sharedData = useSelector((state: any) => state.shared);
  const [enableAdd, setEnableAdd] = useState<boolean>(false);
  const [events, setEvents] = useState<any[]>([]);
  const [eventLists, setEventLists] = useState<any[]>([]);
  const [show, setShow] = useState<boolean>(false);
  const [user, setUser] = useState<any>();
  const [visible, setVisible] = useState(false);

  const [showDelete, setShowDelete] = useState<boolean>(false);
  const [event, setEvent] = useState<any>();

  const [showEvent, setShowEvent] = useState<boolean>(false);
  const [selectedEvent, setSelectedEvent] = useState<any>();
  const userId = localStorage.getItem("id");

  useEffect(() => {
    if (params.get("teamId")) {
      setEnableAdd(false);
    }
  }, [params.get("teamId")]);

  useEffect(() => {
    setVisible(false);
    if (params.get("tab")) {
      setTimeout(() => {
        setVisible(true);
      }, 200);
    }
  }, [params.get("tab")]);

  useEffect(() => {
    if (params.get("create") && params.get('tab') == 'events') {
      setTimeout(() => {
        setEnableAdd(true)
      }, 200);
    }
  }, [params.get("create")]);

  useEffect(() => {
    getAllData();
    setVisible(true);
  }, []);

  const getAllData = async () => {
    try {
      const [eventList] = await Promise.all([getAllMyEvents({})]);
      if (eventList?.status == 200) {
        setEventLists(eventList.data?.data || []);
      }
      if (eventList?.status == 200) {
        // setEventLists(eventDetail.data?.data);
        if (eventList.data?.data?.length) {
          const evs: Event[] = eventList.data?.data?.map((booking: any) => ({
            title: booking?.eventType|| booking?.status,
            date: booking?.date||booking?.calenderStartDate,
            extendedProps: booking,
          }));
          setEvents(evs);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const viewMemberDetail = (userDetail: any) => {
    setUser(userDetail);
    setShow(true);
  };

  const createdTeam = async () => {
    await getAllData();
  };

  const viewTeam = async (teamId: string) => {
    navigate(`/trainer/trainer-dashboard?tab=team&teamId=${teamId}`);
  };

  const handleToggleEdit = (event: any) => {
    dispatch(setFormDetail(event));
    dispatch(setEditEventModalStatus(true));
  };

  const handleToggleDelete = (event: any) => {
    setEvent(event);
    setShowDelete(true);
  };

  const deleteEvent = async () => {
    try {
      const result = await deleteEventById(event?._id);
      if (result.status == 200) {
        setShowDelete(false);
        await getAllData();
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        toast.error(error.response?.data?.responseMessage);
      }
    }
  }
  const viewEvent = (event: any) => {
    setSelectedEvent(event);
    setShowEvent(true);
  }
  return (
    <div className="commonGrid">
      {enableAdd ? (
        <>
          <CreateNewEvent
            back={() => {
              setEnableAdd(false);
              setParams((pre) => {
                pre.delete("create");
                return pre;
              })
            }}
            create={createdTeam}
          />
        </>
      ) : (
        <>
          <div className="my-3">
            <div className="card col-4">
              <div className="mb-3">
                <span className="bg-blue-200 p-2">
                  <CalendarIcon />
                </span>
                {events?.length ? events?.length : ""} {LANG.EVENTS}
              </div>
              <button
                className="btn blueButton  w-100"
                onClick={() => {
                  setEnableAdd(true);
                  setParams((pre) => {
                    pre.set("create", "true");
                    return pre;
                  })
                }}
              >
                <PlusIcon />
                {LANG.CREATE_NEW_EVENT}
              </button>
            </div>
            <div className="mt-3">
              <VisibilityBox show={visible}>
                <EventCalendar events={events} />
              </VisibilityBox>
            </div>
            <div className="tableWrapper alerttable mt-3">
              <div className="title_Counter d-flex justify-content-between mb-3">
                <h4>Alarm</h4>
                {/* <div className="pendingCounter">
                  Pending<span>8+</span>
                </div> */}
              </div>
              <div className="table-responsive scrollHeight">
                <Table className="">
                  <thead>
                    <tr>
                      <th>{LANG.EVENT_TYPE}</th>
                      <th>{LANG.GROUND_NAME}</th>
                      <th>{LANG.DURATION}</th>
                      <th>{LANG.DATE}</th>
                      <th>{LANG.PARTICIPANTS}</th>
                      <th>{LANG.TRAINER}</th>
                      <th>{LANG.ACTION}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {eventLists.filter((ev: any) => !ev.approved).length ? (
                      <>
                        {eventLists
                          .filter((ev: any) => !ev.approved)
                          .map((event: any, index: number) => (
                            <tr key={index}>
                              <td className="text-capitalize">
                                {event?.eventType}
                              </td>
                              <td>{event?.room?.roomName}</td>
                              <td>{event?.timeDuration} hours</td>
                              <td>
                                {moment(event?.date).format("DD MMM YYYY")}{" "}
                                {event?.time}
                              </td>
                              <td className="text-capitalize">
                                {event?.participantType}
                              </td>
                              <td>
                                {event?.createdBy?.firstName}{" "}
                                {event?.createdBy?.lastName}
                              </td>
                              <td>
                                <Dropdown className="p-0">
                                  <Dropdown.Toggle
                                    className="p-0 drop-toggle text-dark bg-transparent"
                                    id="dropdown-basic"
                                  >
                                    <VThreeDots />
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    {/* <Dropdown.Item
                                    onClick={() => handleToggleEdit(event)}
                                  >
                                    {LANG.EDIT}
                                  </Dropdown.Item> */}
                                    <Dropdown.Item
                                      onClick={() => handleToggleDelete(event)}
                                    >
                                      {LANG.DELETE}
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </td>
                            </tr>
                          ))}
                      </>
                    ) : (
                      <tr>
                        <td colSpan={8}>
                          <p className="text-center w-100 p-3 mb-0">
                            {LANG.EVENT_NOT_FOUNDS}
                          </p>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </div>
            <div className="tableWrapper eventstable">
              <div className="title_Counter d-flex justify-content-between mb-3">
                <h4>{LANG.EVENTS}</h4>
                {/* <div className="pendingCounter">
                  Pending<span>8+</span>
                </div> */}
              </div>
              <div className="table-responsive scrollHeight">
                <Table className="">
                  <thead>
                    <tr>
                      <th>{LANG.EVENT_TYPE}</th>
                      <th>{LANG.GROUND_NAME}</th>
                      <th>{LANG.DURATION}</th>
                      <th>{LANG.DATE}</th>
                      <th>{LANG.PARTICIPANTS}</th>
                      <th>{LANG.TRAINER}</th>
                      <th>{LANG.ACTION}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {eventLists.filter((ev: any) => ev?.approved).length ? (
                      <>
                        {eventLists
                          .filter((ev: any) => ev?.approved)
                          .map((event: any, index: number) => (
                            <tr key={index}>
                              <td className="text-capitalize">
                                {event?.eventType}
                              </td>
                              <td>{event?.room?.roomName}</td>
                              <td>{event?.eventDuration}</td>
                              <td>
                                {moment(event?.date).format("DD MMM YYYY")}{" "}
                                {event?.time}
                              </td>
                              <td className="text-capitalize">
                                {event?.participantType}
                              </td>
                              <td>
                                {event?.createdBy?.firstName}{" "}
                                {event?.createdBy?.lastName}
                              </td>

                              <td>
                                <div className="d-flex gap-2">
                                  {
                                    (!checkEventAccpeted(event, userId) && event?.createdBy?._id !== userId) ? <>
                                      {LANG.ELAPSED}
                                    </> : <>
                                      <span
                                        className={
                                          event?.approved
                                            ? "event_approved"
                                            : "event_under_review"
                                        }
                                      >
                                        {event?.approved
                                          ? LANG.APPROVED
                                          : LANG.UNDER_REVIEW}
                                      </span>
                                    </>
                                  }
                                  <Dropdown className="editDropdown">
                                    <Dropdown.Toggle
                                      variant="success"
                                      id="dropdown-basic"
                                    >
                                      <VThreeDots />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                      <Dropdown.Item
                                        onClick={() => viewEvent(event)}
                                      >
                                        {LANG.VIEW_DETAILS}
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        onClick={() => handleToggleDelete(event)}
                                      >
                                        {LANG.DELETE}
                                      </Dropdown.Item>

                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                              </td>
                            </tr>
                          ))}
                      </>
                    ) : (
                      <>
                        <p className="text-center w-100 p-3">
                          {LANG.EVENT_NOT_FOUNDS}
                        </p>
                      </>
                    )}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </>
      )}
      {sharedData.editEventModal && (
        <EditEventModalDetails update={getAllData()} />
      )}
      <ProfileCard show={show} user={user} onHide={() => setShow(false)} />
      <ConfirmDeleteModal show={showDelete} handleSubmit={deleteEvent} handleClose={() => setShowDelete(false)} />
      <EventDetailModal show={showEvent} onHide={() => setShowEvent(false)} event={selectedEvent} />
    </div>
  );
}
