import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { setEventModalStatus } from '../../../../data/redux/shared/sharedSlice';
import { Card, Badge, Row, Col, Image } from 'react-bootstrap';
import ImageWithBasePath from '../../../../data/img/ImageWithBasePath';
import { LANG } from '../../../../../constants/language';
import VisibilityBox from '../../../VisibilityBox';
import { EVENTS_DURATION_OBJ, status_translation } from '../../../../../constants';
import "./style.scss";
import RejectReasonTooltip from '../../../common/reject-reason-tooltip/RejectReasonTooltip';
const EventDetail = () => {
    const fileUrl = process.env.REACT_APP_FILE_URL;
    const dispatch: any = useDispatch();
    const sharedActions: any = useSelector((state: any) => state.shared);
    const [eventDetail, setEventDetail] = useState<any>();
    const closeModal = () => {
        dispatch(setEventModalStatus(false))
    }

    useEffect(() => {
        console.log(sharedActions?.formDetails)
        setEventDetail(sharedActions?.formDetails);
    }, [sharedActions.eventModal])
    return (
        <Modal className='eventModal' backdrop="static" show={sharedActions.eventModal} onHide={closeModal} animation={true}>
            <Modal.Header closeButton className='p-0 border-0'>
                <Modal.Title>{LANG.EVENT}</Modal.Title>
            </Modal.Header>
            <Modal.Body className='p-0'>
                <Card className="p-0 border-0">
                    {/* <ImageWithBasePath className='rounded' src="assets/img/soccer-football.png" alt="Event Image" /> */}

                    <Badge bg="warning" className="mt-3">
                        <i className="bi bi-calendar-date"></i> {moment(eventDetail?.date).locale("de").format("DD MMM YYYY")} {eventDetail?.time}
                    </Badge>

                    <Card.Body className='p-0'>
                        <Card.Title className='text-capitalize'>{eventDetail?.eventType}</Card.Title>
                        <div>
                            <span className='titleDescription h6'>{LANG.EVENT_DESCRIPTION}</span>
                            {eventDetail?.description && <p className='Description'>{eventDetail?.description}</p>}
                        </div>
                        <div>
                            <span className='h6 me-2'>{LANG.EVENT_DURATION}:</span>
                            <span className='h6'>{EVENTS_DURATION_OBJ[eventDetail?.eventDuration]}</span>
                        </div>
                        <div>
                            <span className='h6 me-2'>{LANG.TIME_DURATION}:</span>
                            <span className='h6'>{eventDetail?.timeDuration} Std.</span>
                        </div>
                        <ul className="pt-3 sportWrap">
                            <li className="text-center">
                                <small className="text-muted">Trainer</small>
                                <Image src={eventDetail?.createdBy?.avatar ? fileUrl + eventDetail?.createdBy?.avatar : "/assets/img/default-avatar.png"} roundedCircle fluid style={{ width: '50px', height: '50px' }} />
                                <p>{eventDetail?.createdBy?.firstName} {eventDetail?.createdBy?.lastName}</p>
                            </li>
                            <li className="text-center">
                                <small className="text-muted">Ground</small>
                                <Image src={"/assets/img/field-ground.png"} roundedCircle fluid style={{ width: '50px', height: '50px' }} />
                                <p>{eventDetail?.room?.roomName}</p>
                            </li>
                        </ul>
                        {
                            eventDetail?.participantType === 'team' ?
                                <div className='row mb-2'>
                                    <div className='col-6'>
                                        <span className='fw-bold'>Teams</span>
                                    </div>
                                    <div className='col-12'>
                                        {
                                            eventDetail?.teams?.map((item: any, index: number) =>
                                                <div className='border rounded p-2 mb-2' key={index}>
                                                    <span className='me-2 fw-bold'>{item?.team?.teamName}</span>
                                                    {
                                                        item?.users?.map((member: any, index: number) =>
                                                            <div key={index} className='me-2  d-flex justify-content-between p-1'>
                                                                <span className='text-capitalize text-gray-800'>{member?.user?.firstName} {member?.user?.lastName}</span>
                                                                <span className={member?.status == 'accepted' ? "event_approved text-capitalize fs-6" : "event_under_review text-capitalize fs-6"} >{status_translation[member?.status]}
                                                                  <RejectReasonTooltip show={member?.status == 'rejected'} reason={member?.reason} />
                                                                </span>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            )
                                        }
                                    </div>
                                </div> :
                                <div className='row mb-2'>
                                    <div className='col-6 mb-2'>
                                        <span className='fw-bold'>{LANG.MEMBER}</span>
                                    </div>
                                    <div className='col-12'>
                                        {
                                            eventDetail?.participants?.map((member: any, index: number) =>
                                                <div key={index} className='me-2  d-flex justify-content-between border rounded p-2 mb-2'>
                                                    <span className='text-capitalize text-gray-800'>{member?.user?.firstName} {member?.user?.lastName}</span>
                                                    <span className={member?.status == 'accepted' ? "event_approved text-capitalize fs-6" : "event_under_review text-capitalize fs-6"} >{status_translation?.[member?.status]}
                                                    <RejectReasonTooltip show={member?.status == 'rejected'} reason={member?.reason} />
                                                    </span>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                        }
                    </Card.Body>
                </Card>
            </Modal.Body>
        </Modal>
    )
}

export default EventDetail