import React, { useEffect, useState } from 'react';
import { Modal, Button, Form, ListGroup, InputGroup } from 'react-bootstrap';
import SearchIcon from '../../../../icons/SearchIcon';
import { LANG } from '../../../../constants/language';
import { getAllMembers } from '../../../../services/friends.service';

import _ from 'lodash';
import { getAssignedUserToTrainer } from '../../../../services/user.service';

interface Props {
  show: boolean;
  team: string,
  members: any[],
  handleClose: () => void;
  select(m: any): void
}

export function EditMembersModal({ show, members: saveMembers, team, handleClose, select }: Props) {
  const fileUrl = process.env.REACT_APP_FILE_URL;
  const [selectedMembers, setSelectedMembers] = useState<any>([]);
  const [members, setMembers] = useState<any[]>([]);
  const [tempMembers, setTempMembers] = useState<any[]>([]);
  const [teamName, setTeamName] = useState<string>('');
  useEffect(() => {
    getData();
    if (saveMembers?.length) {
      setTimeout(() => {
        setSelectedMembers(saveMembers);
      }, 400);
    }
    setTeamName(team);
  }, [saveMembers, show]);

  const getData = async () => {
    try {
      const result = await getAssignedUserToTrainer();

      let mergeMembers: any[] = result.data||[];
      mergeMembers = [...saveMembers, ...mergeMembers];
      const uniqueMembers: any[] = _.uniqBy(mergeMembers, '_id');
      setMembers(uniqueMembers);
      setSelectedMembers(saveMembers);
      setTempMembers(uniqueMembers);
    } catch (error) {
      console.log(error);
    }
  }

  const searchFriends = async (text: string) => {
    try {
      if (!text) {
        setMembers(tempMembers);
        return;
      }
      console.log(tempMembers)
      const filteredData = tempMembers?.filter((datas) => {
        const values = Object.values(datas)?.map((value:any) =>
          value.toString().toLowerCase()
        );
        return values?.some((value) => value?.includes(text?.toLowerCase()));
      })||[];
      setMembers(filteredData);
    } catch (error) {
      console.log(error)
    }
  }
  // Sample member data
  const toggleSelect = (member: any) => {
    setSelectedMembers((prevSelected: any) =>
      prevSelected.includes(member) ? prevSelected.filter((m: any) => m?._id !== member?._id) : [...prevSelected, member]
    );
  };

  const submit = () => {
    select({teamName, members:selectedMembers});
    handleClose();
  }

  return (
    <Modal show={show} onHide={handleClose} centered >
      <Modal.Header closeButton>
        <Modal.Title>{LANG.UPDATE_TEAM_DETAIL}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group controlId="teamName">
          <Form.Label className="sr-only">{LANG.TEAM_NAME}</Form.Label>
          <Form.Control type="text" value={teamName} onChange={(ev) => setTeamName(ev.target.value)} placeholder={LANG.ENTER_TEAM_NAME} />
        </Form.Group>
        {/* Search Bar */}
        <InputGroup className="my-3">
          <InputGroup.Text className='border-0'>
            <SearchIcon />
          </InputGroup.Text>
          <Form.Control placeholder={LANG.SEARCH_MEMBER} onChange={(e) => searchFriends(e.target.value)} />
        </InputGroup>

        {/* Members List */}
        <ListGroup variant="flush" style={{ maxHeight: '400px', overflowY: 'auto' }}>
          {members.map((member: any, index: number) =>
            <label key={member._id} className='mb-1 rounded' htmlFor={'member-check-' + member?._id}>
              <ListGroup.Item
                key={index}
                className={`d-flex align-items-center ${selectedMembers.includes(member) ? 'bg-light' : ''
                  }`}
                style={{ cursor: 'pointer' }}
              >
                <img
                  src={member?.avatar ? fileUrl + member?.avatar : "/assets/img/default-avatar.png"}
                  alt={member?.firstName}
                  className="rounded-circle mr-3"
                  style={{ width: '40px', height: '40px' }}
                />
                <span className="flex-grow-1 ms-2">{member.firstName || 'NA'} {member?.lastName}</span>
                <Form.Check
                  type="checkbox"
                  id={'member-check-' + member?._id}
                  checked={selectedMembers.find((m: any) => m?._id == member?._id)}
                  onChange={() => toggleSelect(member)}
                  className="ml-auto"
                />
              </ListGroup.Item>
            </label>
          )}
        </ListGroup>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" disabled={!teamName} className='w-100' onClick={submit}>
          {LANG.UPDATE}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
