import React, { useEffect, useState } from 'react';
import { Form, Button, Modal } from 'react-bootstrap';
import { LANG } from '../../../../constants/language';
import VisibilityBox from '../../VisibilityBox';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import * as Yup from "yup";
import { useFormik } from "formik";
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { setCommentModalStatus, setFormDetail } from '../../../data/redux/shared/sharedSlice';
import { addNewComment, updateComment} from '../../../../services/forum.service';
import { checkFileSizeMoreThanTen, getFormData } from '../../../../utils';
import { updateForumState } from '../../../data/redux/forum/forumSlice';
import { ALLOWED_IMAGES } from '../../../../constants';

const commentSchema: any = Yup.object().shape({
    image: Yup.mixed(),
    message: Yup.string().required(LANG.FIELD_IS_REQUIRED)
});

const ForumCommentModal = (props: any) => {
    const dispatch: any = useDispatch();
    const fileUrl = process.env.REACT_APP_FILE_URL;
    const { formDetails: commentDetail, commentModal } = useSelector((state: any) => state.shared);
    
    const [imageUrl, setImageUrl] = useState<any>("");

    useEffect(() => {
        // get data
    }, [])

    const formIntialValues: any = {
        ...commentDetail
    };

    const commentFormik = useFormik({
        initialValues: formIntialValues,
        validationSchema: commentSchema,
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            try {
                const formData= getFormData(values);
                if (commentDetail?._id) {
                    const result = await updateComment(commentDetail?._id, formData);
                    if (result.status == 200) {
                        setSubmitting(true);
                        resetForm();
                        dispatch(setCommentModalStatus(false));
                        dispatch(updateForumState({id:commentDetail?.forumId, data: result.data?.data }));
                        props?.update();
                    }
                } else {
                    const result = await addNewComment(formData);
                    if (result.status == 200) {
                        setSubmitting(true);
                        resetForm();
                        dispatch(updateForumState({id:commentDetail?.forumId, data: result.data?.data}));
                        dispatch(setCommentModalStatus(false));
                        props?.update();
                    }
                }
            } catch (error) {
                if (error instanceof AxiosError) {
                    toast.error(error.response?.data?.responseMessage);
                }
                setSubmitting(false);
            }
        },
    });

    const closeModal = () => {
        dispatch(setCommentModalStatus(false));
        dispatch(setFormDetail({}));
    }

    const handleChangeImage = async (e: any) => {
        const selectedFile = e.target.files[0];
        if(!ALLOWED_IMAGES.includes(selectedFile?.type)){
            toast.error(LANG.ONLY_JPG_PNG_IMAGES_ALLOWED);
            return;
        }
        if(checkFileSizeMoreThanTen(selectedFile)){
           toast.error(LANG.MAXIMUM_FILE_SIZE_IS_TEN_MB)
        }else{
            const url = URL.createObjectURL(selectedFile);
            setImageUrl(url);
            commentFormik.setFieldValue('image', selectedFile)
        }
    };
    return (
        <Modal
            {...props}
            onHide={closeModal}
            show={commentModal}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <h2 className='fs-6'>{LANG.ADD_COMMENT}</h2>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={commentFormik.handleSubmit}>
                    <div className="row">
                        <div className='uploadImageWrapper mb-3'>
                            <div className='uploadImg'>
                                <VisibilityBox show={commentDetail?.image && !imageUrl}>
                                   <img src={fileUrl + commentDetail?.image} height={120} style={{ borderRadius: 20 }} alt="forum logo" />
                                </VisibilityBox>
                                <VisibilityBox show={imageUrl}>
                                   <img src={imageUrl} height={120} style={{ borderRadius: 20 }} alt="forum logo" />
                                </VisibilityBox>
                            </div>
                            <div className='mt-2'>
                                <label  className='uploaddBtn btn btn-sm btn-outline-primary' ><Form.Control accept='.png,.jpg,.jpeg' className='d-none' onChange={handleChangeImage} type="file" />{LANG.ADD_IMAGE}</label>
                            </div>
                        </div>
                        <div className="col-sm-12  col-md-12 mb-2">
                            <Form.Label>
                                {LANG.COMMENT}
                            </Form.Label>
                            <Form.Group>
                                <input
                                    type="text"
                                    placeholder={LANG.COMMENT}
                                    maxLength={1000}
                                    className={clsx(
                                        "form-control commonInput",
                                        { "border border-danger": commentFormik.touched.message && commentFormik.errors.message },
                                    )}
                                    {...commentFormik.getFieldProps("message")}
                                />
                            </Form.Group>
                            {(commentFormik.errors.message && commentFormik.touched.message) && (
                                <div className="formik-errors text-danger">{`${commentFormik.errors.message}`}</div>
                            )}
                        </div>
                    </div>
                    <div className='d-flex justify-content-end'>
                        <Button type='button' onClick={closeModal} variant='outline-secondary' className='me-2' >
                            {LANG.CANCEL}
                        </Button>
                        <Button type='submit' variant="primary" className='' >
                            {commentDetail?._id ? LANG.UPDATE : LANG.CONFIRM}
                        </Button>
                    </div>
                </form>
            </Modal.Body >
        </Modal >

    )
}

export default ForumCommentModal;