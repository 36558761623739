import React from 'react'
import HomeHeader from '../home/home-header/HomeHeader';
import './style.scss';
import HomeFooter from '../home/home-footer/HomeFooter';
import { ImpressumPageSelector } from '../../core/data/redux/webpage/webPageAction';
import { useSelector } from 'react-redux';
import { WebPage } from '../../types/webpage';
import ReactQuill from 'react-quill';

const Impressum = () => {
    const impressum: WebPage = useSelector(ImpressumPageSelector);
    return (
        <div className='IMPressumPage BgLightBlue'>
            <div className='headerCover'>
                <HomeHeader />
            </div>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='textFull'>
                            <h1>IMPressum</h1>
                            <ReactQuill value={impressum?.impressum}
                                readOnly={true}
                                theme={"bubble"} />
                        </div>
                    </div>
                </div>
            </div>
            <HomeFooter />
        </div>
    )
}
export default Impressum;